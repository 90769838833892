import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  '@keyframes typing': {
    '0%': {
      transform: 'translateY(0)',
      transition: 'all 0.5s ease-in-out',
    },
    '50%': {
      transform: 'translateY(-5px)',
      transition: 'all 0.5s ease-in-out',
    },
    '100%': {
      transform: 'translateY(0)',
      transition: 'all 0.5s ease-in-out',
    },
  },
  root: {
    background: '#f2f2f2',
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 70,
    padding: theme.spacing(1) / 2,
  },
  dot: {
    width: 8,
    height: 8,
    background: '#cacaca',
    borderRadius: '50%',
    margin: 3,
    transition: 'all 0.5s ease-in-out',
    animation: '$typing 1s infinite',
  },
  dot1: {
    animationDelay: '1s',
  },
  dot2: {
    animationDelay: '0.5s',
  },
  dot3: {
    animationDelay: '0.8s',
  },
}));

export const TypingIndicator: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={clsx(classes.dot, classes.dot1)}/>
      <div className={clsx(classes.dot, classes.dot2)}/>
      <div className={clsx(classes.dot, classes.dot3)}/>
    </div>
  )
}
