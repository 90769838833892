import { RoutesKeys, RouteType } from './utils';
import { asyncLoad } from '@hu-care/react-utils';

const withExternalNavBarRoutes: RouteType[] = [
  {
    key: RoutesKeys.singlePendingInvites,
    component: asyncLoad(() => import('../containers/invites/single-pending-invites.container')),
    path: '/invites/:id',
    exact: true,
    config: {
      title: 'pending invites',
      navBarType: 'conditional',
    },
  },
  {
    key: RoutesKeys.pendingInvites,
    component: asyncLoad(() => import('../containers/invites/pending-invites.container')),
    path: '/invites',
    exact: true,
    config: {
      title: 'pending invites',
      navBarType: 'conditional',
    },
  },
  {
    key: RoutesKeys.sentInvites,
    component: asyncLoad(() => import('../containers/invites/sent-invites.container')),
    path: '/sent-invites',
    exact: true,
    config: {
      title: 'sent invites',
      navBarType: 'conditional',
    },
  },
  {
    key: RoutesKeys.createProfile,
    component: asyncLoad(() => import('../containers/profile/create-profile.container')),
    path: '/profiles-new',
    exact: true,
    config: {
      title: 'create profile',
      navBarType: 'external',
    },
  },
  {
    key: RoutesKeys.profiles,
    component: asyncLoad(() => import('../containers/profile/profiles.container')),
    path: '/profiles',
    exact: true,
    config: {
      title: 'profiles',
      navBarType: 'conditional',
    },
  },
  {
    key: RoutesKeys.payment,
    component: asyncLoad(() => import('../containers/payment/payment.container')),
    path: '/payment/:professionalId',
    exact: true,
    config: {
      title: 'payment',
      navBarType: 'external',
    },
  },
  {
    key: RoutesKeys.paymentSuccess,
    component: asyncLoad(() => import('../containers/payment/payment-success.container')),
    path: '/payment/:professionalId/success',
    exact: true,
    config: {
      title: 'payment success',
      navBarType: 'external',
    },
  },
  {
    key: RoutesKeys.professionalRegister,
    component: asyncLoad(() => import('../containers/profile/professional-register.container')),
    path: '/professional-register/:medicId',
    exact: true,
    config: {
      title: 'professional register',
      navBarType: 'external',
    },
  },
];

export const commonRoutes: RouteType[] = [
  ...withExternalNavBarRoutes,
  {
    key: RoutesKeys.dashboard,
    component: asyncLoad(() => import('../containers/dashboard/dashboard.container')),
    path: '/',
    exact: true,
    config: {
      title: 'dashboard',
    },
  },
  {
    key: RoutesKeys.profile,
    component: asyncLoad(() => import('../containers/profile/profile.container')),
    path: '/profile',
    exact: true,
    config: {
      title: 'profile',
    },
  },
  {
    key: RoutesKeys.appointment,
    component: asyncLoad(() => import('../containers/appointment/appointment-detail.container')),
    path: '/appointments/:appointmentId',
    exact: true,
    config: {
      title: 'appointment',
    },
  },
  {
    key: RoutesKeys.agenda,
    component: asyncLoad(() => import('../containers/agenda/agenda.container')),
    path: '/agenda',
    exact: true,
    config: {
      title: 'agenda',
    },
  },
  {
    key: RoutesKeys.agendaConfigure,
    component: asyncLoad(() => import('../containers/agenda/agenda-configure.container')),
    path: '/configure-agenda',
    exact: true,
    config: {
      title: 'agenda configure',
    },
  },
  {
    key: RoutesKeys.agendaList,
    component: asyncLoad(() => import('../containers/agenda/agenda-list.container')),
    path: '/configure-agenda/:agendaId',
    exact: true,
    config: {
      title: 'agenda list',
    },
  },
  {
    key: RoutesKeys.agendaOverride,
    component: asyncLoad(() => import('../containers/agenda/agenda-override.container')),
    path: '/override-agenda/:date',
    exact: true,
    config: {
      title: 'agenda override',
    },
  },
  {
    key: RoutesKeys.network,
    component: asyncLoad(() => import('../containers/network/network.container')),
    path: '/network',
    exact: false,
    config: {
      title: 'network',
    },
  },
  {
    key: RoutesKeys.professionalDetail,
    component: asyncLoad(() => import('../containers/professional/professional-profile.container')),
    path: '/professional/:id',
    exact: true,
    config: {
      title: 'professional',
    },
  },
  {
    key: RoutesKeys.invoices,
    component: asyncLoad(() => import('../containers/invoices/invoices.container')),
    path: '/invoices',
    exact: true,
    config: {
      title: 'invoices',
    },
  },
  {
    key: RoutesKeys.patientImport,
    component: asyncLoad(() => import('../containers/patients/patient-upload-import.container')),
    path: '/patients/import',
    exact: true,
    config: {
      title: 'patient import',
    },
  },
  {
    key: RoutesKeys.patientDetail,
    component: asyncLoad(() => import('../containers/patients/patient-detail.container')),
    path: '/patients/:patientId',
    exact: true,
    config: {
      title: 'patient',
    },
  },
  {
    key: RoutesKeys.patients,
    component: asyncLoad(() => import('../containers/patients/patients.container')),
    path: '/patients',
    exact: false,
    config: {
      title: 'patients',
    },
  },
  {
    key: RoutesKeys.reports,
    component: asyncLoad(() => import('../containers/reports/reports.container')),
    path: '/reports',
    // exact: true,
    config: {
      title: 'reports',
    },
  },
  {
    key: RoutesKeys.requests,
    component: asyncLoad(() => import('../containers/requests/requests.container')),
    path: '/requests',
    // exact: true,
    config: {
      title: 'requests',
    },
  },
  {
    key: RoutesKeys.notifications,
    component: asyncLoad(() => import('../containers/notifications/notifications.container')),
    path: '/notifications',
    exact: true,
    config: {
      title: 'notifications',
    },
  },
  {
    key: RoutesKeys.assistants,
    component: asyncLoad(() => import('../containers/assistants/assistants.container')),
    path: '/assistants',
    exact: true,
    config: {
      title: 'assistants',
    },
  },
  {
    key: RoutesKeys.invoices,
    component: asyncLoad(() => import('../containers/invoices/invoices.container')),
    path: '/invoices',
    exact: false,
    config: {
      title: 'invoices',
    },
  },
];
