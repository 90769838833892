import { useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import {
  MedicProfileQuery,
  MedicProfileQueryVariables,
  MedicProfileDocument,
  AssistantProfileQuery,
  AssistantProfileQueryVariables,
  AssistantProfileDocument,
  OperatorProfileQuery,
  OperatorProfileQueryVariables,
  OperatorProfileDocument,
} from '../generated/graphql';

export const useProfessionalProfiles = () => {
  const {
    data: medicData,
    loading: loadingMedic,
    refetch: refetchMedic,
  } = useQuery<MedicProfileQuery, MedicProfileQueryVariables>(MedicProfileDocument);

  const {
    data: operatorData,
    loading: loadingOperator,
    refetch: refetchOperator,
  } = useQuery<OperatorProfileQuery, OperatorProfileQueryVariables>(OperatorProfileDocument);

  const {
    data: assistantData,
    loading: loadingAssistant,
    refetch: refetchAssistant,
  } = useQuery<AssistantProfileQuery, AssistantProfileQueryVariables>(AssistantProfileDocument);

  const refetch = useCallback(() => {
    return Promise.all([
      refetchMedic(),
      refetchOperator(),
      refetchAssistant(),
    ]);
  }, [
    refetchMedic,
    refetchOperator,
    refetchAssistant,
  ]);

  return useMemo(() => ({
    data: {
      medicProfile: medicData?.medicProfile,
      assistantProfile: assistantData?.assistantProfile,
      operatorProfile: operatorData?.operatorProfile,
    },
    loading: loadingMedic || loadingAssistant || loadingOperator,
    refetch,
  }), [
    medicData,
    loadingMedic,
    assistantData,
    loadingAssistant,
    operatorData,
    loadingOperator,
    refetch,
  ]);
}
