import { createContext, memo, useContext, useEffect, useMemo, useState } from 'react';
import { Theme, ThemeProvider } from '@material-ui/core';
import { enrichTheme } from './common-theme';

import huCareTheme from '@hu-care/theme';
import { ProfessionalRole } from '../generated/graphql';

export function getRoleColor(role?: string) {
  switch (role) {
    case ProfessionalRole.Medic:
      return { main: '#448CCB', light: '#B3D1E9', dark: '#336897' };
    case ProfessionalRole.Assistant:
      return { main: '#F7941D', light: '#FAD5A1', dark: '#985F1A' };
    case ProfessionalRole.OrganizationSa:
      return { main: '#7ACCC8', light: '#cafbf9', dark: '#63cec9' };
    case ProfessionalRole.Operator:
      return { main: '#F26C4F' };
    default:
      return { main: '#FFF' };
  }
}

const roleTheme = (baseTheme: Theme, role?: ProfessionalRole) => enrichTheme(baseTheme, {
  palette: {
    role: baseTheme.palette.augmentColor(getRoleColor(role)),
    secondary: {
      // light: '#8a8a8a',
      light: '#F5F5F5',
    },
    background: {
      default: '#F5F7FC',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#fafafa',
        },
      },
    },
  },
});

interface RoleThemeCtx {
  role?: ProfessionalRole;
  theme: Theme;
  setRole: (role?: ProfessionalRole) => void;
}

const RoleThemeContext = createContext({} as unknown as RoleThemeCtx);

export const useRoleTheme = () => useContext(RoleThemeContext);

export const RoleThemeProvider = memo(({ children }) => {
  const [currentTheme, setCurrentTheme] = useState<Theme>(() => roleTheme(huCareTheme));
  const [role, setRole] = useState<ProfessionalRole | undefined>(undefined);

  useEffect(() => {
    setCurrentTheme(roleTheme(huCareTheme, role));
  }, [setCurrentTheme, role]);

  const value = useMemo(() => ({
    role,
    setRole: (role?: ProfessionalRole) => setRole(role),
    theme: currentTheme,
  }), [role, currentTheme, setRole]);

  return (
    <ThemeProvider theme={currentTheme}>
      <RoleThemeContext.Provider value={value}>
        {children}
      </RoleThemeContext.Provider>
    </ThemeProvider>
  )
});
