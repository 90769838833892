import { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { ChatPopup } from '../components/chat/chat-popup';
import {
  ChatAccessTokenDocument, ChatAccessTokenMutation, ChatAccessTokenMutationVariables,
  StartChatDocument,
  StartChatMutation,
  StartChatMutationVariables,
  UserDataFragment,
} from '../generated/graphql';
import { useMutation } from '@apollo/client';
import { ChatProvider } from '@hu-care/chat-client/dist/react';

interface ChatPopupCtx {
  isOpen: boolean;
  open: (destUser: UserDataFragment) => void;
  close: () => void;
  destUser: UserDataFragment | null;
  getToken: () => Promise<string | undefined>;
  startChat: (userId: string) => Promise<string | undefined>;
  error: boolean;
}

export const ChatPopupContext = createContext<ChatPopupCtx>({
  isOpen: false,
  open: (destUser: UserDataFragment) => {/**/},
  close: () => {/**/},
  destUser: null,
  getToken: () => Promise.resolve(undefined),
  startChat: () => Promise.resolve(undefined),
  error: false,
});

export const ChatPopupProvider: FC = ({ children }) => {
  const [destUser, setDestUser] = useState<UserDataFragment | null>(null);

  const [startMutation, { error: sError }] = useMutation<StartChatMutation, StartChatMutationVariables>(StartChatDocument);

  const [tokenMutation, { error: tError }] = useMutation<ChatAccessTokenMutation, ChatAccessTokenMutationVariables>(ChatAccessTokenDocument, {
    context: {
      batch: false,
    },
  });

  const getToken = useCallback(() => {
    return tokenMutation().then(({ data }) => data?.getChatAccessToken);
  }, [tokenMutation]);

  const startChat = useCallback((userId: string) => {
    return startMutation({ variables: { userId } }).then(({ data }) => data?.createChat);
  }, [startMutation]);

  const value = useMemo<ChatPopupCtx>(() => ({
    isOpen: !!destUser,
    open: (destUser: UserDataFragment) => setDestUser(destUser),
    close: () => setDestUser(null),
    destUser,
    setDestUser,
    getToken,
    startChat,
    error: !!tError || !!sError,
  }), [
    destUser,
    setDestUser,
    getToken,
    startChat,
    tError,
    sError,
  ]);

  return (
    <ChatPopupContext.Provider value={value}>
      <ChatProvider
        getToken={getToken}
        startChat={startChat}
        debug={process.env.NODE_ENV === 'development'}
        waitForReady={false}
      >
        {children}
        <ChatPopup/>
      </ChatProvider>
    </ChatPopupContext.Provider>
  )
}

export const useChatPopup = () => useContext(ChatPopupContext);
