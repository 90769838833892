import { RoutesKeys, RouteType } from './utils';
import intersection from 'lodash/intersection';
import { Route } from 'react-router-dom';
import { getRouteFactory } from '@hu-care/react-utils';

import { commonRoutes } from './common.routes';
import { errorRoutes } from './error.routes';

export * from './utils';

export const allRoutes: RouteType[] = [
  ...commonRoutes,
  ...errorRoutes,
];

export const renderCommonRoutes = (scopes: string[] = []) => (
  commonRoutes
    .filter(({ config }) => (config.scopes || []).length ? intersection(config.scopes, scopes).length : true)
    .map(({ component, exact, path }) => (
      <Route key={path} exact={exact} path={path} component={component}/>
    ))
);

export const renderErrorRoutes = () => (
  errorRoutes.map(({ component, exact, path }) => (
    <Route key={path} exact={exact} path={path} component={component}/>
  ))
);

const routeByKey = allRoutes.reduce((acc, r) => {
  acc.set(r.key, r.path);
  return acc;
}, new Map<RoutesKeys, string>());

export const getRoute = getRouteFactory<RoutesKeys>(routeByKey);
