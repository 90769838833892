import { ContainerProps } from '@material-ui/core';

export enum RoutesKeys {
  dashboard = 'dashboard',
  patients = 'patients',
  patientDetail = 'patientDetail',
  patientImport = 'patientImport',
  agenda = 'agenda',
  appointment = 'appointment',
  network = 'network',
  professionalDetail = 'professionalDetail',
  reports = 'reports',
  requests = 'requests',
  requestDetail = 'requestDetail',
  invoices = 'invoices',
  pageNotFound = 'pageNotFound',
  notifications = 'notifications',
  profile = 'profile',
  profiles = 'profiles',
  createProfile = 'createProfile',
  professionalRegister = 'professionalRegister',
  payment = 'payment',
  paymentSuccess = 'paymentSuccess',
  assistants = 'assistants',
  pendingInvites = 'pendingInvites',
  singlePendingInvites = 'singlePendingInvites',
  sentInvites = 'sentInvites',
  agendaConfigure = 'agendaConfigure',
  agendaList = 'agendaList',
  agendaOverride = 'agendaOverride',
}

export interface RouteType {
  key: RoutesKeys;
  path: string;
  component: any;
  exact?: boolean;
  children?: any[];
  config: {
    breadcrumbs?: boolean,
    back?: boolean,
    title: string,
    scopes?: string[];
    args?: Record<string, any>;
    containerProps?: Partial<Omit<ContainerProps, 'children'>>;
    navBarType?: 'default' | 'conditional' | 'external';
    [key: string]: any;
  };
}
