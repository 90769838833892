import { useCallback } from 'react';
import { matchPath, useHistory } from 'react-router';
import { allRoutes, RoutesKeys } from '../routes';

export const useRedirect = () => {
  const history = useHistory();

  return useCallback((to: string, exclude: RoutesKeys[] = []) => {
    if (history.location.pathname === to) {
      return;
    }

    const excludeRoutes = allRoutes.filter(r => exclude.includes(r.key))

    for (const excludeRoute of excludeRoutes) {
      const { path, exact } = excludeRoute;
      const match = matchPath(history.location.pathname, {
        path,
        exact,
        strict: false,
      });

      if (match) {
        return;
      }
    }

    history.replace(to);
  }, [history])
}
