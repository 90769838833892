import { FC, ReactElement } from 'react';
import { Grid, Card, Typography, Box, IconProps } from '@material-ui/core';
import { Image, PictureAsPdf, Description, SvgIconComponent } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

export interface AttachmentsPreviewProps {
  attachments: Partial<{
    id: string;
    file: File;
  }>[];
  onRemove: (idx: number | number[]) => void;
}

const mimeMap = new Map<RegExp, SvgIconComponent>([
  [new RegExp('^image/*'), Image],
  [new RegExp('^application/pdf$'), PictureAsPdf],
  [new RegExp('.*'), Description],
]);

export const getIcon = (type: string | undefined | null, iconProps?: IconProps): ReactElement | undefined => {
  let Icon: any = null;
  for (const reg of Array.from(mimeMap.keys())) {
    if (reg.test(type || '')) {
      Icon = mimeMap.get(reg);
      break;
    }
  }

  if (Icon) {
    return <Icon color="action" fontSize="inherit" {...iconProps}/>
  }
}

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flex: '1',
  },
  content: {
    flex: '1 0 auto',
    position: 'relative',
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(6),
    textAlign: 'right',
  },
  preview: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    fontSize: '1.8rem',
    backgroundColor: theme.palette.secondary.light,
  },
  deleteBtn: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    fontSize: '1rem',
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export const AttachmentsPreview: FC<AttachmentsPreviewProps> = ({ attachments, onRemove }) => {
  const classes = useStyles();

  if (!attachments.length) {
    return null;
  }

  return (
    <Box pb={2}>
      <Grid container spacing={1}>
        {attachments.map(({ id, file }, idx) => (
          <Grid key={id || file?.name} item sm={4} xs={4} md={3}>
            <Card className={classes.card}>
              <div className={classes.preview}>
                {file && getIcon(file.type)}
              </div>
              <div className={classes.details}>
                <div className={classes.content}>
                  <Typography noWrap>{file?.name}</Typography>
                  <Typography className={classes.deleteBtn} onClick={() => onRemove(idx)}>
                    Elimina
                  </Typography>
                </div>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
