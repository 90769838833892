import ReactDOM from 'react-dom'
import { Router } from 'react-router';
import './index.css'
import { App } from './App'
import { SnackbarProps, StoreProvider } from '@hu-care/react-ui-store';
import { RoleThemeProvider } from './themes';
import { AuthProvider } from './contexts/auth.context';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { it } from 'date-fns/esm/locale';
import history from './utils/history';
import { setupLogger } from './utils/logger';
import './i18n';
import { CssBaseline } from '@material-ui/core';
import { ErrorBoundary } from './containers/error-pages/error.boundary';

// Set this to override the auth and force this user to be used in all API calls
window.userId = '4e4630aa-0431-458a-ba35-04ded360162f'; // Sejedjamaladdin Nekoofar

const snackbarProps: SnackbarProps = {
  preventDuplicate: true,
  disableWindowBlurListener: true,
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
};

setupLogger()
.then(() => {
  ReactDOM.render(
    <ErrorBoundary>
      <StoreProvider snackbarProps={snackbarProps}>
        <RoleThemeProvider>
          <CssBaseline/>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
            <Router history={history}>
              <AuthProvider skipAll={!!window.userId && process.env.NODE_ENV === 'development'}>
                <App/>
              </AuthProvider>
            </Router>
          </MuiPickersUtilsProvider>
        </RoleThemeProvider>
      </StoreProvider>
    </ErrorBoundary>,
    document.getElementById('root'),
  );
});

