import { useCallback, FC, ElementType } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {
  Box,
  BoxProps,
  Button,
  DialogActions,
  SvgIconProps,
  Typography,
  TypographyProps,
  CircularProgress,
  ButtonProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Actions, useStore } from '@hu-care/react-ui-store';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  warningIcon: {
    color: theme.palette.error.main,
    fontSize: 56,
    paddingBottom: theme.spacing(1),
  },
  cancelButton: {
    marginRight: 'auto',
  },
  dialogTitle: {
    justifyContent: 'center',
  },
}));

const ConfirmDialogTitle: FC<{
  title: string;
  icon?: ElementType;
  iconProps?: SvgIconProps;
  typographyProps?: TypographyProps;
  boxProps?: BoxProps;
}> = (
  {
    title,
    icon: Icon = ErrorOutlineIcon,
    iconProps,
    typographyProps,
    boxProps,
  },
) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" alignItems="center" margin="0 auto" textAlign="center" {...boxProps}>
      <Icon {...iconProps} className={clsx(classes.warningIcon, iconProps?.className)} />
      <Typography variant="h4" {...typographyProps}>{title}</Typography>
    </Box>
  )
}

const ConfirmDialogBody: FC<{
  boxProps?: BoxProps;
  onConfirm: () => void;
  onCancel: () => void;
  confirmLoading?: boolean;
  cancelButtonProps?: ButtonProps;
  confirmButtonProps?: ButtonProps;
}> = ({ boxProps, onConfirm, onCancel, confirmLoading, cancelButtonProps, confirmButtonProps }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box {...boxProps}>
      <DialogActions>
        <Button
          className={classes.cancelButton}
          onClick={onCancel}
          {...cancelButtonProps}
        >
          {t('cancel', 'Annulla')}
        </Button>
        <Button
          onClick={onConfirm}
          disabled={confirmLoading}
          {...confirmButtonProps}
        >
          {confirmLoading ? <CircularProgress size="1rem" color="secondary"/> : t('confirm', 'Conferma')}
        </Button>
      </DialogActions>
    </Box>
  )
}

export interface UseConfirmDialogProps {
  onConfirm?: () => any;
  confirmLoading?: boolean;
  titleBoxProps?: BoxProps;
  iconProps?: SvgIconProps;
  icon?: ElementType;
  titleTypographyProps?: TypographyProps;
  bodyBoxProps?: BoxProps;
  title?: string;
  closeOnConfirm?: boolean;
}

export const useConfirmDialog = ({ closeOnConfirm = true, ...defaultProps }: UseConfirmDialogProps = {}) => {
  const { dispatch } = useStore();
  const classes = useStyles();
  const onClose = useCallback(() => {
    dispatch({
      type: Actions.DIALOG_CLOSE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const open = useCallback((openProps?: UseConfirmDialogProps) => {
    const props = { ...defaultProps, ...openProps };
    const onConfirm = async () => {
      try {
        if (props.onConfirm) {
          await props.onConfirm();
        }
        if (closeOnConfirm) {
          onClose();
        }
      } catch (e) {
        throw e;
      }
    }

    dispatch({
      type: Actions.DIALOG_OPEN,
      title: (
        <ConfirmDialogTitle
          title={props.title || ''}
          icon={props.icon}
          iconProps={props.iconProps}
          typographyProps={props.titleTypographyProps}
          boxProps={props.titleBoxProps}
        />),
      body: (
        <ConfirmDialogBody
          onConfirm={onConfirm}
          onCancel={onClose}
          boxProps={props.bodyBoxProps}
          confirmLoading={props.confirmLoading}
        />
      ),
      onClose,
      maxWidth: 'sm',
      fullWidth: true,
      fullScreen: false,
      dialogTitleProps: {
        className: classes.dialogTitle,
        disableCloseButton: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultProps, onClose, closeOnConfirm, classes]);

  return { confirm: open, close: onClose };
}
