import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@hu-care/react-ui-store';

export const useHandleError = (shouldThrow = false) => {
  const { t } = useTranslation();
  const toast = useToast();
  return useCallback((e: Error, message: string) => {
    console.error(e);
    toast.error(t(message));
    if (shouldThrow) {
      throw e;
    }
  }, [t, toast, shouldThrow]);
}
