import { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { useMountedState } from 'react-use';

import { NetworkStatus, OrganizationProfessionalDataFragment, ProfessionalDataFragment } from '../generated/graphql';

import { useRoleTheme } from '../themes';
import { useProfessionalProfiles } from '../hooks/useProfessionalProfiles';
import { useProfessional } from '../hooks/useProfessional';
import { isAssistant, isMedic, isOperator, professionalHasCompletedProfile } from '../utils/profiles';
import { useHistory, useLocation } from 'react-router';
import { getRoute, RoutesKeys } from '../routes';
import { useRedirect } from '../hooks/useRedirect';
import { usePendingInvites } from '../hooks/invite/useInvites';
import { useManagedOrganizations } from '../hooks/useManagedOrganizations';
import { currentProfessionalIdVar } from '../apollo/links';

export type SupportedProfileType = ProfessionalDataFragment;

type ProfileContextType = {
  // Current selected profile
  selectedProfile: SupportedProfileType | null;
  setSelectedProfile: Dispatch<SetStateAction<SupportedProfileType | null>>;
  // Current impersonated profile
  impersonatedProfile: SupportedProfileType | null;
  setImpersonatedProfile: Dispatch<SetStateAction<SupportedProfileType | null>>;
  // All Selectable profiles
  availableProfiles: SupportedProfileType[];
  // All impersonatable profiles
  availableImpersonate: SupportedProfileType[];
  // impersonated profile if any, otherwise selectedProfile if any
  actingAs: ProfessionalDataFragment | null;
  // current user available medic profile (if present)
  medicProfile: ProfessionalDataFragment | null;
  isImpersonating: boolean;
  loading: boolean;
}

const ProfileContext = createContext<ProfileContextType>({
  selectedProfile: null,
  setSelectedProfile: () => undefined,
  impersonatedProfile: null,
  setImpersonatedProfile: () => undefined,
  availableProfiles: [],
  availableImpersonate: [],
  actingAs: null,
  medicProfile: null,
  isImpersonating: false,
  loading: false,
});

const LAST_PROFILE_USED_KEY = '@medic:lastProfileUsed';
const LAST_PROFILE_IMPERSONATED_KEY = '@medic:lastProfileImpersonated';

export const ProfileProvider: FC = ({ children }) => {
  const [selectedProfile, setSelectedProfile] = useState<SupportedProfileType | null>(null);
  const [impersonatedProfile, setImpersonatedProfile] = useState<SupportedProfileType | null>(null);
  const history = useHistory();
  const redirect = useRedirect();

  useEffect(() => {
    if (selectedProfile) {
      currentProfessionalIdVar(selectedProfile.id);
    } else {
      currentProfessionalIdVar(null);
    }
  }, [selectedProfile]);

  const { data: professionalData, loading: professionalLoading } = useProfessionalProfiles();
  const { organizations, loading: organizationsLoading } = useManagedOrganizations();
  const { pendingInvites, loading: iLoading } = usePendingInvites();
  const loading = professionalLoading || organizationsLoading || iLoading;

  const organizationsSaProfiles = useMemo(() => {
    return (organizations || ([] as OrganizationProfessionalDataFragment[]))
      .map((op) => op.organization.serviceAccount);
  }, [organizations]);

  const availableProfiles = useMemo(() => {
    if (loading) return [];
    return [
      professionalData?.medicProfile,
      professionalData?.assistantProfile,
      professionalData?.operatorProfile,
      ...organizationsSaProfiles,
    ].filter(Boolean) as SupportedProfileType[]
  }, [professionalData, organizationsSaProfiles, loading]);

  const availableImpersonate = useMemo(() => {
    const networks = professionalData?.assistantProfile?.networks || [];
    if (isAssistant(selectedProfile)) {
      return networks
        .filter(n => n.status === NetworkStatus.Approved && n.canImpersonate)
        .map(n => n.inverse) as SupportedProfileType[];
    } else {
      return [];
    }
  }, [professionalData, selectedProfile]);

  const { setRole } = useRoleTheme();
  const { pathname } = useLocation();

  // FIXME this trigger a new query even if all data are already in cache
  const { professional, loading: pLoading } = useProfessional(impersonatedProfile?.id || selectedProfile?.id);

  const value = useMemo(() => ({
    selectedProfile,
    setSelectedProfile,
    availableProfiles,
    impersonatedProfile,
    setImpersonatedProfile,
    availableImpersonate,
    actingAs: professional || null,
    isImpersonating: !!impersonatedProfile && impersonatedProfile.id !== selectedProfile?.id,
    loading: loading || pLoading,
    medicProfile: availableProfiles.find(f => isMedic(f)) || null,
  }), [
    selectedProfile,
    setSelectedProfile,
    availableProfiles,
    setImpersonatedProfile,
    availableImpersonate,
    loading,
    professional,
    impersonatedProfile,
    pLoading,
  ]);

  const isMounted = useMountedState();

  useEffect(() => {
    if (loading || !isMounted()) return;

    if (!selectedProfile) {
      // if last profile id was saved in localStorage, try to retrieve it
      const lastProfileId = localStorage.getItem(LAST_PROFILE_USED_KEY);
      const profileIndex = (availableProfiles || []).findIndex(p => p.id === lastProfileId);

      if (profileIndex !== -1) {
        setSelectedProfile(availableProfiles[profileIndex] as any);
      } else {
        setTimeout(() => {
          if (pendingInvites.length) {
            return redirect(getRoute(RoutesKeys.pendingInvites));
          }

          const mustRedirect = (
            !loading
            && isMounted()
            && (availableProfiles.length === 0 || !selectedProfile)
          );
          if (mustRedirect) {
            redirect(getRoute(RoutesKeys.profiles), [
              RoutesKeys.createProfile,
              RoutesKeys.payment,
              RoutesKeys.paymentSuccess,
              RoutesKeys.professionalRegister,
            ]);
          }
        });
      }
    }
  }, [
    availableProfiles,
    redirect,
    isMounted,
    selectedProfile,
    loading,
    history,
    setSelectedProfile,
    pendingInvites,
  ]);

  useEffect(() => {
    if (selectedProfile) {
      localStorage.setItem(LAST_PROFILE_USED_KEY, selectedProfile.id);
    }
  }, [selectedProfile]);

  // this hook resets impersonated profile on selectedProfile change
  useEffect(() => {
    if (selectedProfile) {
      setImpersonatedProfile(null);
    }
  }, [selectedProfile, setImpersonatedProfile]);

  // beware: the above hook must be kept before this one
  useEffect(() => {
    if (selectedProfile && availableImpersonate.length) {

      // if last profile id was saved in localStorage, try to retrieve it
      const lastProfileId = localStorage.getItem(LAST_PROFILE_IMPERSONATED_KEY);

      if (lastProfileId !== null) {
        const profileIndexFound = availableImpersonate.findIndex(p => p.id === lastProfileId);

        if (profileIndexFound > -1) {
          setImpersonatedProfile(availableImpersonate[profileIndexFound] as any);
        }
      }
    }
  }, [selectedProfile, availableImpersonate, setImpersonatedProfile]);

  useEffect(() => {
    if (selectedProfile) {
      if (impersonatedProfile) {
        localStorage.setItem(LAST_PROFILE_IMPERSONATED_KEY, impersonatedProfile.id);
      } else {
        localStorage.removeItem(LAST_PROFILE_IMPERSONATED_KEY);
      }
    }
  }, [selectedProfile, impersonatedProfile]);

  useEffect(() => {
    setRole(professional?.role);
  }, [professional, setRole]);

  useEffect(() => {
    if (!professional) return;

    const mustPay = (isMedic(professional) || isOperator(professional))
      && !professional.hasActiveLicense
      && !process.env.REACT_APP_SKIP_LICENSE;

    if (mustPay) {
      const newPath = getRoute(RoutesKeys.payment, { professionalId: professional.id });
      return redirect(newPath, [RoutesKeys.profiles, RoutesKeys.createProfile, RoutesKeys.paymentSuccess]);
    }

    const mustCompleteDetails = !professionalHasCompletedProfile(professional);
    if (mustCompleteDetails) {
      const newPath = getRoute(RoutesKeys.professionalRegister, { medicId: professional.id });
      redirect(newPath);
    }
  }, [professional, redirect, history, pathname]);

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  )
};

export const useProfile = () => useContext(ProfileContext);
