import { responsiveFontSizes, Theme } from '@material-ui/core';
// import createTypography, { Typography } from '@material-ui/core/styles/createTypography';
import { merge } from 'lodash-es';
// import { Overrides } from '@material-ui/core/styles/overrides';

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer I>
    ? Array<DeepPartial<I>>
    : DeepPartial<T[P]>;
};

/**
 * From a passed base theme, deep merge the overrides and apply common styles
 * @param theme
 * @param overrides
 */
export const enrichTheme = (theme: Theme, overrides: DeepPartial<Theme>) => {
  const merged: Theme = merge(theme, overrides);
  // merged.overrides = commonOverrides(merged);
  // merged.typography = commonTypography(merged);

  return responsiveFontSizes(merged);
};
