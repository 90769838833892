import { useMutation } from '@apollo/client';
import {
  MassiveEmailTarget,
  ProfessionalBasicDataFragment,
  SendMailDocument,
  SendMailMutation,
  SendMailMutationVariables, SendMassiveEmailDocument, SendMassiveEmailMutation, SendMassiveEmailMutationVariables,
} from '../generated/graphql';
import { useCallback, useMemo, useRef } from 'react';
import { useToast } from '@hu-care/react-ui-store';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { FileRejection } from 'react-dropzone';
import { canHandleFiles } from '../utils/upload';

export interface SendMailForm {
  title: string;
  body: string;
  attachments?: File[];
  userIds: string[];
}

export const useSendMail = (professional: ProfessionalBasicDataFragment) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [mailMutation, rest] = useMutation<SendMailMutation, SendMailMutationVariables>(SendMailDocument);

  const sendMail = useCallback(async (data: SendMailForm) => {
    try {
      const result = await mailMutation({
        variables: {
          medicId: professional.id,
          to: data.userIds,
          body: data.body,
          title: data.title,
          attachments: data.attachments || undefined,
        },
      });
      if (result.data?.sendMail) {
        toast.success(t('mail_sent', 'Mail inviata'));
      } else {
        throw new Error('Cannot send email');
      }
    } catch (e) {
      console.error(e);
      toast.error(t('cannot_send_email', 'Impossibile inviare email'));
      throw e;
    }
  }, [mailMutation, professional, toast, t]);

  return {
    sendMail,
    ...rest,
  }
};

export const useSendMassiveMail = (professional: ProfessionalBasicDataFragment, target: MassiveEmailTarget) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [mailMutation, rest] = useMutation<SendMassiveEmailMutation, SendMassiveEmailMutationVariables>(SendMassiveEmailDocument);

  const sendMail = useCallback(async (data: SendMailForm) => {
    try {
      const result = await mailMutation({
        variables: {
          medicId: professional.id,
          target,
          body: data.body,
          title: data.title,
          attachments: data.attachments || undefined,
        },
      });
      if (result.data?.sendMassiveEmail) {
        toast.success(t('mail_sent', 'Mail inviata'));
      } else {
        throw new Error('Cannot send email');
      }
    } catch (e) {
      console.error(e);
      toast.error(t('cannot_send_email', 'Impossibile inviare email'));
      throw e;
    }
  }, [mailMutation, professional, toast, t, target]);

  return {
    sendMail,
    ...rest,
  }
};

export const useSendMailForm = (maxFiles: number, maxFileSize: number) => {
  const toast = useToast();
  const { t } = useTranslation();
  const form = useForm<SendMailForm>({
    defaultValues: {
      title: '',
      body: '',
    },
  });

  const { control } = form;

  const { append, remove, fields } = useFieldArray<{ file: File }>({
    control,
    name: 'attachments',
  });

  const fileInputRef = useRef<HTMLInputElement| null>(null);

  const onFileRemove = useCallback((idx: number | number[]) => {
    remove(idx);
    if (fileInputRef.current) {
      const idxs: number[] = Array.isArray(idx) ? idx : [idx];
      idxs.forEach(id => removeFileFromFileList(id, fileInputRef.current!));
    }
  }, [remove]);

  const onDrop = useCallback((files: File[], reject: FileRejection[]) => {
    const canHandle = canHandleFiles({
      newFiles: files,
      maxFiles: maxFiles,
      maxFileSize: maxFileSize,
      reject,
      showMessage: toast.error,
      t,
    });

    if (canHandle) {
      append(files.map(file => ({ file })))
    }
  }, [append, toast, t, maxFiles, maxFileSize]);

  return useMemo(() => ({
    onFileRemove,
    append,
    remove,
    fields,
    fileInputRef,
    onDrop,
    ...form,
  }), [
    onFileRemove,
    append,
    remove,
    fields,
    fileInputRef,
    onDrop,
    form,
  ])
}


function removeFileFromFileList(index: number, input: HTMLInputElement) {
  const dt = new DataTransfer();
  const { files } = input;
  if (!files) {
    return;
  }
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (index !== i) dt.items.add(file);
    input.files = dt.files;
  }
}
