import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  Grid,
  Icon,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useProfile } from '../../contexts/profile.context';
import { getProfessionalLabel, getProfileColor, getProfileIcon, isOrganizationSa } from '../../utils/profiles';
import { useIsMobile } from '../../hooks/useIsMobile';
import { getRoute, RoutesKeys } from '../../routes';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme =>
  createStyles({
    select: {
      height: 32,
      '&:before': {
        borderBottom: '0px solid white !important',
      },
      color: 'white',
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'capitalize',
    },
    icon: {
      fill: 'white',
    },
    listItemIcon: {
      minWidth: 0,
      paddingRight: 4,
    },
    profileIcon: {
      height: 20,
      width: 20,
      paddingRight: 2,
      fill: theme.palette.text.secondary,
    },
    profileName: {
      maxWidth: '16ch',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  }),
);

export const ProfileSelector: FC = () => {
  const { setSelectedProfile, selectedProfile, availableProfiles } = useProfile();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const history = useHistory();

  const [profileIndex, setProfileIndex] = useState<number|null>(null);
  const { t } = useTranslation();

  // update profileIndex if selectedProfile changes from outside
  useEffect(() => {
    if (!selectedProfile) return;
    const index = availableProfiles.findIndex(p => p.id === selectedProfile.id);
    if (index !== profileIndex) {
      setProfileIndex(index);
    }
  }, [selectedProfile, availableProfiles, profileIndex, setProfileIndex]);

  const handleChange = useCallback((event: ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>) => {
    const idx = event.target.value as number;
    setProfileIndex(idx);

    const newProfile = availableProfiles[idx];
    setSelectedProfile(newProfile || null);
    history.push(getRoute(RoutesKeys.dashboard));
  }, [availableProfiles, history, setSelectedProfile]);

  if (!availableProfiles.length || profileIndex === null) {
    return null;
  }

  return (
    <FormControl>
      <Select
        className={classes.select}
        disableUnderline
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
        value={profileIndex}
        onChange={handleChange}
        renderValue={value => {
          const profile = availableProfiles[value as number];

          if (!profile) return null;

          const ProfileIcon = getProfileIcon(profile);
          const profileNameLabel = getProfessionalLabel(profile);
          const profileName = isOrganizationSa(profile) ? profileNameLabel : t(profileNameLabel);

          return <Grid container alignItems="center">
            <Grid item>
              <Icon className={classes.listItemIcon}>
                <ProfileIcon className={classes.profileIcon} style={{ fill: 'white' }} />
              </Icon>
            </Grid>
            { !isMobile && <Grid item>
              <Tooltip title={profileName}>
                <Typography className={classes.profileName}>
                  {profileName}
                </Typography>
              </Tooltip>
            </Grid>}
          </Grid>
        }}
      >
        {availableProfiles.map((profile, i) => {
          const ProfileIcon = getProfileIcon(profile);
          const profileNameLabel = getProfessionalLabel(profile);
          const profileName = isOrganizationSa(profile) ? profileNameLabel : t(profileNameLabel);

          return <MenuItem key={profile.__typename} value={i}>
            <Grid container alignItems="center">
              <Grid item>
                <Icon className={classes.listItemIcon}>
                  <ProfileIcon className={classes.profileIcon} style={{ fill: getProfileColor(profile) }} />
                </Icon>
              </Grid>
              <Grid item>
                <Tooltip title={profileName}>
                  <Typography className={classes.profileName}>
                    {profileName}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </MenuItem>
        })}
      </Select>
    </FormControl>
  )
}
