import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ClientOAuth2, { Token } from 'client-oauth2';
import { encodeObject, decodeObject, getPkce } from '../utils/crypto';
import { useHistory } from 'react-router';
import { Loading } from '@hu-care/react-layout';
import { decodeJwt } from '@hu-care/react-utils';

const STORAGE_TOKEN_KEY = '@medic:refreshToken';
const STORAGE_VERIFIER_KEY = '@medic:pkceVerifier';

declare const window: any;

export interface AuthUser {
  name: string;
  surname: string;
  picture: string;
  email: string;
  taxId: string;
  phone: string;
  sub: string;
}

export interface AuthContext {
  ready: boolean;
  token: Token | null;
  user: AuthUser | null;
  refreshToken: (token?: string) => Promise<Token | null>;
  getToken: () => string;
  logout: () => Promise<void>;
  settingsUrl: string;
}

const redirectUri = `${window.location.origin}/callback`;
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID!;
const accessTokenUri = new URL('/h/oauth2/token', process.env.REACT_APP_AUTH_URL).toString();
const revokeUri = new URL('/h/oauth2/revoke', process.env.REACT_APP_AUTH_URL).toString();
const logoutUrl = new URL('/k/self-service/browser/flows/logout', process.env.REACT_APP_AUTH_URL).toString();
const settingsUrl = new URL('/settings', process.env.REACT_APP_AUTH_URL).toString();

const AuthCtx = createContext<AuthContext>({
  ready: false,
  user: null,
  token: null,
  getToken: () => '',
  refreshToken: token => Promise.resolve(null),
  logout: () => Promise.resolve(),
  settingsUrl,
});

const client = new ClientOAuth2({
  clientId,
  accessTokenUri,
  redirectUri,
  authorizationUri: new URL('/h/oauth2/auth', process.env.REACT_APP_AUTH_URL).toString(),
  scopes: ['openid', 'email', 'profile', 'offline_access'],
});

/**
 * Create the authorize url
 * This app use the PKCE authorize flow because it is a client only app
 * @param returnTo
 * @param extraState
 */
async function getAuthUri(returnTo: string, extraState: Record<string, any> = {}) {
  const state = encodeObject({ returnTo, ...extraState });
  const { verifier, challenge } = await getPkce();
  localStorage.setItem(STORAGE_VERIFIER_KEY, verifier);
  return client.code.getUri({
    query: {
      code_challenge: challenge,
      code_challenge_method: 'S256',
      state,
    },
  });
}

/**
 * Get a fresh access token from a refresh token
 * @param token
 */
async function getNewToken(token: string) {
  return fetch(accessTokenUri, {
    method: 'POST',
    body: new URLSearchParams({
      refresh_token: token,
      client_id: clientId,
      grant_type: 'refresh_token',
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
    },
  }).then(res => {
    return res.json()
      .then(data => {
        if (!res.ok) {
          throw data;
        }
        return data;
      });
  });
}

/**
 * Redirect the user to the auth server to perform an auth code flow
 * The user will be prompted with the login form and, after successful authenticate,
 * it will be redirected to the app callback with a code query parameter
 * the app will then exchange the code for an access token to use against the api
 */
async function redirectToAuthorize() {
  localStorage.removeItem(STORAGE_TOKEN_KEY);
  getAuthUri(window.location.pathname).then(url => {
    window.location.href = url;
  });
}

/**
 * Revoke a token
 * If a refresh token is revoked, the related access token is revoked too
 * @param token
 */
async function revokeToken(token: string) {
  return fetch(revokeUri, {
    method: 'POST',
    body: new URLSearchParams({
      token,
      client_id: clientId,
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
    },
  }).then(res => {
    if (!res.ok) {
      return res.json()
        .then(err => {
          throw err;
        });
    }
    return null;
  });
}

export const useAuth = () => useContext(AuthCtx);

export const AuthProvider: FC<{ skipAll?: boolean }> = ({ children, skipAll }) => {
  const [token, setToken] = useState<Token | null>(null);
  const { location, push } = useHistory();

  const refreshAccessToken = useCallback(async (refreshToken?: string) => {
    if (skipAll) {
      throw new SkipAllError('Cannot refresh token without OAuth2');
    }
    if (!refreshToken) {
      // await redirectToAuthorize();
      throw new Error('Missing refresh token')
    }
    return getNewToken(refreshToken)
      .then(data => {
        // Refresh token was valid, save the new access token in memory
        // Update the storage with the new refresh token
        const newToken = client.createToken(data);
        setToken(newToken);
        localStorage.setItem(STORAGE_TOKEN_KEY, newToken.refreshToken);
        return newToken;
      })
      .catch(err => {
        // Refresh token was invalid, redirect to the auth server
        console.error(err);
        setToken(null);
        redirectToAuthorize();
        return null;
      });
  }, [setToken, skipAll]);

  /**
   * Logout a user
   * Revoke its refresh token (and the related access token),
   * remove it from storage
   * redirect the user to the auth server logout endpoint
   */
  const logout = useCallback(async () => {
    const onRevoke = () => {
      localStorage.removeItem(STORAGE_TOKEN_KEY);
      const uri = new URL(logoutUrl);
      uri.searchParams.set('return_to', window.location.origin);
      window.location.href = uri.toString();
    };
    if (token) {
      revokeToken(token.refreshToken)
        .catch(err => {
          console.error(err);
        })
        .then(() => {
          onRevoke();
        });
    } else {
      onRevoke();
    }
  }, [token]);

  useEffect(() => {
    if (skipAll) {
      return;
    }
    const { code, state } = location.query;
    // We are in the authorization callback url, the code is needed to get an access token
    if (code) {
      client.code.getToken(location, {
        body: {
          code_verifier: localStorage.getItem(STORAGE_VERIFIER_KEY) || '',
        },
      })
        .then(data => {
          // We can add the desired return to uri in the auth flow state and retrieve it here,
          // redirecting the user to the correct uri
          if (state) {
            const { returnTo = '/' } = decodeObject<{ returnTo: string }>(state);
            if (data.refreshToken) {
              // Save the refresh token to renew the auth session in future
              localStorage.setItem(STORAGE_TOKEN_KEY, data.refreshToken);
            }
            // Go to the requested url or home /
            push(returnTo);
          }
          // Store the token in memory
          setToken(data);
        })
        .catch(err => {
          console.error(err);
          setToken(null);
        })
      .finally(() => localStorage.removeItem(STORAGE_VERIFIER_KEY));
    } else {
      // Check if there is an old refresh token in storage
      const refreshToken = localStorage.getItem(STORAGE_TOKEN_KEY);
      if (!refreshToken) {
        // No refresh token found, redirect to the auth server
        redirectToAuthorize();
      } else {
        // A token is found, try to exchange it for a new access token
        refreshAccessToken(refreshToken);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const user = useMemo<AuthUser | null>(() => {
    if (token?.data.id_token) {
      return decodeJwt(token.data.id_token);
    }
    return skipAll ? {
      sub: window.userId,
      name: 'Mock',
      surname: 'Surname',
      nickname: 'Mockuser',
    } : null;
  }, [skipAll, token]);

  useEffect(() => {
    if (user && window.customerly) {
      window.customerly.update({
        email: user.email,
        name: user.name,
        user_id: user.sub,
        attributes: {
          env: process.env.NODE_ENV,
          application: 'medic',
        },
      });
    }
  }, [user]);

  const value = useMemo<AuthContext>(() => ({
    ready: !!token || !!skipAll,
    token,
    user,
    refreshToken: refreshAccessToken,
    getToken: () => token?.accessToken || '',
    logout,
    settingsUrl,
  }), [token, refreshAccessToken, logout, skipAll, user]);

  if (!token && !skipAll) {
    return <Loading/>;
  }

  return (
    <AuthCtx.Provider value={value}>
      {children}
    </AuthCtx.Provider>
  )
}

export class SkipAllError extends Error {}
