import { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  PendingInvitesDocument,
  PendingInvitesQuery,
  SentInvitesQuery,
  SentInvitesDocument,
  DeleteInviteMutation,
  DeleteInviteMutationVariables,
  DeleteInviteDocument,
} from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { useConfirmDialog } from '../useConfirmDialog';
import { useToast } from '@hu-care/react-ui-store';
import { useProfile } from '../../contexts/profile.context';

export const usePendingInvites = () => {
  const { data, ...rest } = useQuery<PendingInvitesQuery>(PendingInvitesDocument);

  return useMemo(() => ({
    pendingInvites: data?.pendingInvites || [],
    ...rest,
  }), [
    data,
    rest,
  ]);
}

export const useSentInvites = () => {
  const { actingAs } = useProfile();
  const { data, ...rest } = useQuery<SentInvitesQuery>(SentInvitesDocument, {
    variables: {
      medicId: actingAs!.id,
    },
    skip: !actingAs,
  });

  return useMemo(() => ({
    sentInvites: data?.sentInvites || [],
    ...rest,
  }), [
    data,
    rest,
  ]);
}

export const useDeleteInvite = (id: string, withConfirm?: Boolean) => {
  const { t } = useTranslation();
  const dialog = useConfirmDialog();
  const toast = useToast();

  const [deleteInvite] = useMutation<DeleteInviteMutation, DeleteInviteMutationVariables>(DeleteInviteDocument);

  return useMemo(() => {
    const action = async () => {
      try {
        await deleteInvite({
          variables: {
            id,
          },
          update: (cache, mutationResult) => {
            if (!mutationResult.data?.deleteInvite) {
              return;
            }
            cache.evict({
              id: cache.identify({ __typename: 'Invite', id }),
              broadcast: true,
            });
            cache.gc();
          },
        });
        toast.success(t('invite:delete-invite-success'));
      } catch (e) {
        toast.error(t('errors:cannot-delete-invite'));
      } finally {
        dialog.close()
      }
    };

    if (withConfirm) {
      return () => dialog.confirm({
        onConfirm: action,
        title: t<string>('invite:confirm-delete'),
      });
    } else {
      return action;
    }
  }, [deleteInvite, withConfirm, id, dialog, t, toast]);
}