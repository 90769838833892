import { ReactNode, useState, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  SwipeableDrawer, Typography,
} from '@material-ui/core';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Menu } from '@material-ui/icons';
import { getRoute, RoutesKeys } from '../../routes';
import { Link } from 'react-router-dom';
import { ProfessionalRole } from '../../generated/graphql';
import { useProfile } from '../../contexts/profile.context';
import { useDialog } from '@hu-care/react-ui-store';
import { UploadPrivacy } from '../upload-privacy';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useEffect } from 'react';
import { ChatIcon } from '../../utils/icons';

declare const window: any;

const useStyles = makeStyles(theme =>
  createStyles({
    centered: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    drawer: {
      minWidth: 240,
    },
    subheader: {
      background: theme.palette.primary.main,
      color: 'white',
      textTransform: 'uppercase',
    },
    sidebarFooter: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

type SidebarLink = {
  label: string;
  to?: RoutesKeys;
  icon?: ReactNode;
  roles?: ProfessionalRole[];
  hideImpersonate?: boolean;
  target?: string;
  href?: string;
  onClick?: (e: MouseEvent) => void;
}

const settings: SidebarLink[] = [
  // {
  //   label: 'menu:profiles',
  //   to: RoutesKeys.profiles,
  // },
  {
    label: 'menu:profile',
    to: RoutesKeys.profile,
    roles: [ProfessionalRole.Medic, ProfessionalRole.Operator, ProfessionalRole.OrganizationSa],
  },
  {
    label: 'menu:assistants',
    to: RoutesKeys.assistants,
    roles: [ProfessionalRole.Medic, ProfessionalRole.Operator, ProfessionalRole.OrganizationSa],
    hideImpersonate: true,
  },
  {
    label: 'menu:sent-invites',
    to: RoutesKeys.sentInvites,
    roles: [ProfessionalRole.Medic, ProfessionalRole.Operator, ProfessionalRole.OrganizationSa],
  },
  {
    label: 'menu:pending-invites',
    to: RoutesKeys.pendingInvites,
    roles: [ProfessionalRole.Medic, ProfessionalRole.Operator, ProfessionalRole.OrganizationSa],
    hideImpersonate: true,
  },
  {
    label: 'menu:working-hours',
    to: RoutesKeys.agendaConfigure,
    roles: [ProfessionalRole.Medic, ProfessionalRole.Operator, ProfessionalRole.OrganizationSa],
  },
]

const SidebarLinkListItem: FC<{ link: SidebarLink }> = ({ link: item }) => {
  const { t } = useTranslation();
  const component = item.to ? Link : 'a';
  const componentProps = item.to ? {
    to: getRoute(item.to),
  } : {
    href: item.href,
    target: item.target,
    onClick: item.onClick,
  };

  return (
    <ListItem button component={component as any} dense {...componentProps}>
      {item.icon && (
        <ListItemIcon>
          {item.icon}
        </ListItemIcon>
      )}
      <ListItemText primary={t(item.label)} />
    </ListItem>
  )
}

export const Sidebar: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { actingAs, isImpersonating } = useProfile();
  const dialog = useDialog();
  const isMobile = useIsMobile();

  const onUploadPrivacyClick = useCallback((e: MouseEvent) => {
    e.preventDefault();

    if (!actingAs || [ProfessionalRole.Assistant].includes(actingAs.role)) {
      return;
    }
    dialog.open({
      title: t('menu:upload-privacy-policy'),
      body: <UploadPrivacy/>,
      fullWidth: true,
      fullScreen: isMobile,
      maxWidth: 'sm',
    });
  }, [actingAs, dialog, t, isMobile]);

  const activities: SidebarLink[] = [
    {
      label: 'menu:upload-privacy-policy',
      href: '#',
      onClick: onUploadPrivacyClick,
      roles: [ProfessionalRole.Medic, ProfessionalRole.Operator, ProfessionalRole.OrganizationSa],
    },
    {
      label: 'menu:find-substitute',
      href: 'https://www.blogmmg.it/cercasostituto.php',
      target: '_blank',
    },
  ];

  useEffect(() => {
    if (('customerly' in window) && window.customerly) {
      if (isMenuOpen) {
        window.customerly.hide();
      } else {
        window.customerly.show();
      }
    }
  }, [isMenuOpen]);

  const openCustomerly = useCallback(() => {
    if (('customerly' in window) && (window as any).customerly) {
      window.customerly.open();
    }
  }, []);

  const canSeeActivities = true; // TODO check if user is MMG or PLS

  const filterLinks = useCallback((links: SidebarLink[]) => {
    return links
      .filter(link => {
        return !link.roles
          || (
            actingAs
            && link.roles.includes(actingAs.__typename as ProfessionalRole)
            && (!isImpersonating || !link.hideImpersonate)
          )
      })
  }, [actingAs, isImpersonating]);

  return (
    <>
      <IconButton onClick={() => setMenuOpen(true)} color="inherit">
        <Menu />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        open={isMenuOpen}
        onOpen={() => undefined}
        onClose={() => setMenuOpen(false)}
        >
        <div
          className={classes.drawer}
          role="presentation"
          onClick={() => setMenuOpen(false)}
          onKeyDown={() => setMenuOpen(false)}
        >
          <List
            subheader={
              <ListSubheader component="div" className={classes.subheader}>
                {t('menu:settings')}
              </ListSubheader>
            }>
            {filterLinks(settings).map(item => (
              <SidebarLinkListItem link={item} key={item.label}/>
            ))}
          </List>

          {canSeeActivities && <>
            <Divider />

            <List
              subheader={
                <ListSubheader component="div" className={classes.subheader}>
                  {t('menu:activities')}
                </ListSubheader>
              }>
              {filterLinks(activities).map(item => (
                <SidebarLinkListItem link={item} key={item.label}/>
              ))}
            </List>
          </>}
          <div className={classes.sidebarFooter}>
            <Box clone marginBottom={1}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<ChatIcon />}
                onClick={openCustomerly}
              >
                {t('menu:assistance')}
              </Button>
            </Box>
            <Typography variant="subtitle2">
              {t('menu:patient-link.intro')}
            </Typography>
            <Box clone mt={1}>
              <Button
                variant="contained"
                color="primary"
                component="a"
                href="https://patient.app.casadellasaluteweb.it"
                target="_blank"
              >
                {t('menu:patient-link.link')}
              </Button>
            </Box>
          </div>
        </div>
      </SwipeableDrawer>
    </>
  )
}
