import { memo, Suspense, useCallback, useEffect, useState } from 'react';
import { Switch, useLocation } from 'react-router';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { Loading, LayoutProvider } from '@hu-care/react-layout';
import MainContainer from './containers/main.container';
import { Route } from 'react-router-dom';
import AuthCallback from './containers/auth-callback.container';
import { SkipAllError, useAuth } from './contexts/auth.context';
import { makeClient } from './apollo/client';
import { ProfileProvider } from './contexts/profile.context';
import { logger } from './utils/logger';
import { SharedDialog } from '@hu-care/react-ui-store';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { commonRoutes } from './routes/common.routes';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK!);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [pathname]);

  return null;
}

export const App = memo(() => {
  const { ready, getToken, refreshToken, token, user, logout } = useAuth();
  const [apollo, setApollo] = useState<ApolloClient<any> | null>(null);
  const [errored, setErrored] = useState<Error | boolean>(false);

  if (errored) {
    // Big error, catch by global Error boundary, user needs to reload the page
    throw errored;
  }

  useEffect(() => {
    if (user) {
      logger.addContext('user', {
        id: user.sub,
        email: user.email,
      });
    }
  }, [user]);

  const onRefreshFail = useCallback((err?: Error) => {
    if (err && err instanceof SkipAllError) {
      setErrored(err);
    } else {
      logout();
    }
  }, [logout, setErrored]);

  useEffect(() => {
    if (!ready) {
      return;
    }
    setApollo(
      makeClient({
        tokenGetter: getToken,
        refreshToken: () => refreshToken(token?.refreshToken),
        onRefreshFail: onRefreshFail,
      }),
    );
  }, [ready, getToken, refreshToken, token, onRefreshFail]);

  if (!apollo) {
    return null;
  }

  return (
    <ApolloProvider client={apollo}>
      <Elements stripe={stripePromise}>
        <ScrollToTop />
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path="/callback">
              <AuthCallback/>
            </Route>
            <ProfileProvider>
              <LayoutProvider routes={commonRoutes}>
                <MainContainer />
              </LayoutProvider>
              <SharedDialog />
            </ProfileProvider>
          </Switch>
        </Suspense>
      </Elements>
    </ApolloProvider>
  );
});
