import { FC } from 'react';
import { Loading } from '@hu-care/react-layout';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
  },
}));

export const CenteredLoading: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Loading/>
    </div>
  )
}
