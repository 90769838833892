import { FC, FormEventHandler } from 'react';
import { Box, Button, Divider, Grid, TextField, Typography } from '@material-ui/core';
import { ArrayField, Controller, UseFormMethods } from 'react-hook-form';
import { AttachFile, Send } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { DragOverlay } from '../drag-overlay';
import { useTranslation } from 'react-i18next';
import { AttachmentsPreview } from '../attachments-preview';
import { ACCEPTED_FILES_HUMAN, convertBytesToMbsOrKbs } from '../../utils/upload';

export const useStyles = makeStyles(theme => ({
  '@keyframes progress': {
    '0%': {
      backgroundPosition: '0 0',
    },
    '100%': {
      backgroundPosition: '-70px 0',
    },
  },
  dragRoot: {
    position: 'relative',
    '&:focus': {
      outline: 'none',
    },
  },
  dragOverlay: {
    animation: '$progress 2s linear infinite !important',
    // eslint-disable-next-line max-len
    backgroundImage: `repeating-linear-gradient(-45deg, ${theme.palette.background.paper}, ${theme.palette.background.paper} 25px, ${theme.palette.divider} 25px, ${theme.palette.divider} 50px)`,
    backgroundSize: '150% 100%',
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.grey.A200,
    borderWidth: 4,
    opacity: 0.7,
    borderStyle: 'dashed',
    margin: theme.spacing(1),
    display: 'block',
    position: 'absolute',
    left: '-24px',
    bottom: '-8px',
    right: '-24px',
    top: '-8px',
    zIndex: 9,
  },
  dragOverlayText: {
    position: 'absolute',
    zIndex: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    padding: theme.spacing(3),
  },
}));

interface SendMailFormPayload {
  title: string;
  body: string;
}

export interface SendMailFormProps {
  maxFiles: number;
  maxFileSize: number;
  onDrop: DropzoneOptions['onDrop'];
  onSubmit: FormEventHandler<HTMLFormElement>;
  control: UseFormMethods<SendMailFormPayload>['control'];
  errors: UseFormMethods<SendMailFormPayload>['errors'];
  onFileRemove: (idx: number | number[]) => void;
  files: Partial<ArrayField<{ file: File }>>[];
  loading: boolean;
  onCancel: () => void;
}

export const SendMailFormComponent: FC<
  SendMailFormProps
> = ({ maxFiles, maxFileSize, onSubmit, onDrop, control, errors, onFileRemove, files, loading, onCancel }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    maxFiles,
    maxSize: maxFileSize,
  });

  return (
    <div {...getRootProps({ className: classes.dragRoot })}>
      {isDragActive && <DragOverlay/>}
      <form onSubmit={onSubmit} noValidate>
        <Box pb={2}>
          <Controller
            name="title"
            control={control}
            fullWidth
            variant="outlined"
            label={t('mail-subject')}
            required
            rules={{
              required: true,
            }}
            error={!!errors.title}
            helperText={errors.title?.message}
            as={<TextField/>}
          />
        </Box>
        <Box pb={2}>
          <Controller
            name="body"
            control={control}
            fullWidth
            variant="outlined"
            multiline
            rows={5}
            required
            label={t('mail-body')}
            rules={{
              required: true,
            }}
            error={!!errors.body}
            helperText={errors.body?.message}
            as={<TextField/>}
          />
        </Box>

        <Box>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <AttachmentsPreview attachments={files} onRemove={onFileRemove} />
            </Grid>
            <Grid item>
              <label htmlFor="mail-attachments">
                <Button startIcon={<AttachFile />} component="span">
                  {t('add-attachment')}
                </Button>
                <input id="mail-attachments" {...getInputProps()} />
              </label>
              <Typography variant="caption">
                {
                  t('max-file-size', {
                    size: convertBytesToMbsOrKbs(maxFileSize),
                    formats: ACCEPTED_FILES_HUMAN.join(', '),
                  })
                }
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box py={2}>
          <Divider/>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Button onClick={onCancel} disabled={loading}>{t('cancel')}</Button>
          <Button
            endIcon={<Send/>}
            type="submit"
            disabled={loading}
          >
            {t('send')}
          </Button>
        </Box>
      </form>
    </div>
  )
}
