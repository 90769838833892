import { cloneElement, memo, useCallback, useState } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Link,
  Fab,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { NotificationDataFragment } from '../../generated/graphql';
import { Skeleton } from '@material-ui/lab';
import { notificationData } from '../../utils/notification';
import { useTranslation } from 'react-i18next';
import { MoreHoriz, Tab } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { formatDistanceToNow } from 'date-fns';
import { it } from 'date-fns/esm/locale';
import { useIsMobile } from '../../hooks/useIsMobile';
import clsx from 'clsx';
import { DoneIcon } from '../../utils/icons';
import { useReadNotification } from '../../hooks/notification/useReadNotifications';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  listItem: {
    position: 'relative',
    '&:hover $notificationListItemMenu': {
      opacity: 1,
    },
  },
  listItemIcon: {
    minWidth: theme.spacing(4),
  },
  listItemTextUnread: {
    fontWeight: theme.typography.fontWeightBold,
  },
  listItemActionLink: {
    cursor: 'pointer',
  },
  notificationListItemMenu: {
    position: 'absolute',
    opacity: 0,
    transition: 'opacity background-color ease 0.2s',
    right: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));

export interface NotificationWidgetItemProps {
  notification?: NotificationDataFragment;
  loading: boolean;
  divider?: boolean;
  onClose?: () => void;
}

export const NotificationItem = memo<NotificationWidgetItemProps>(({ notification, divider, loading, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const data = notification && notificationData(notification, t);
  const isMobile = useIsMobile();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();

  const { onRead, loading: readLoading } = useReadNotification();

  const markAsRead = useCallback(() => {
    if (notification && !notification.readAt) {
      onRead(notification.id);
    }
  }, [notification, onRead]);

  const onActionClick = useCallback(() => {
    markAsRead();
    if (data?.action?.url) {
      history.push(data.action.url);
      if (onClose) {
        onClose();
      }
    }
  }, [data, history, onClose, markAsRead]);

  const read = notification?.readAt;

  const icon = data?.icon ? cloneElement(data.icon as any, {
    fontSize: 'small',
    fontWeight: 'bold',
    color: read ? 'inherit' : 'primary',
  }) : <Skeleton variant="circle" width={24} height={24}/>;

  const availableActions = [];
  if (!read) {
    availableActions.push({
      label: t('mark-as-read'),
      icon: <DoneIcon/>,
      onClick: markAsRead,
    });
  }
  if (data?.action) {
    availableActions.push({
      label: data.action.text,
      icon: <Tab/>,
      onClick: onActionClick,
    });
  }

  if (!data) {
    return null;
  }

  return (
    <ListItem divider={divider} dense className={classes.listItem}>
      {!isMobile && (
        <ListItemIcon className={classes.listItemIcon}>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText
        disableTypography
        primary={loading ? (
          <Skeleton width={120}/>
        ) : (
          <Typography variant="body2" className={clsx({
            [classes.listItemTextUnread]: !read,
          })}>{data?.title}</Typography>
        )}
        secondary={loading ? <Skeleton width={250}/> : (
          <>
            <Typography variant="body2" color="textSecondary">{data?.message}</Typography>
            <Box flexBasis="100%" display="flex" justifyContent="space-between">
              {data?.action && (
                <Link
                  variant="body2"
                  className={clsx({
                    [classes.listItemTextUnread]: !read,
                  }, classes.listItemActionLink)}
                  onClick={onActionClick}
                  color="primary"
                >
                  {data.action.text}
                </Link>
              )}
              {data?.createdAt && (
                <Typography variant="subtitle2" component="span">
                  {' '}{formatDistanceToNow(data.createdAt, {
                  locale: it,
                  addSuffix: true,
                })}
                </Typography>
              )}
            </Box>
          </>
        )}
      />
      {(notification && availableActions.length > 0) && (
        <>
          <Fab
            size="small"
            className={classes.notificationListItemMenu}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreHoriz/>
          </Fab>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {availableActions.map(action => (
              <MenuItem
                key={action.label}
                onClick={action.onClick}
                dense
                button
                disabled={readLoading}
              >
                <ListItemIcon style={{ minWidth: 36 }}>
                  {action.icon}
                </ListItemIcon>
                <Typography variant="inherit">{action.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </ListItem>
  );
});
