import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery, useLazyQuery } from '@apollo/client';
import { Typography, Box } from '@material-ui/core';

import { useDialog } from '@hu-care/react-ui-store';

import { useIsMobile } from './useIsMobile';

import {
  ProfessionalQuery,
  ProfessionalQueryVariables,
  ProfessionalDocument,
  ProfessionalDataFragment,
  NetworkStatus,
  ProfessionalRole,
} from '../generated/graphql';

import { useProfile } from '../contexts/profile.context';
import { getProfessionalFullName, isMedic, isOperator } from '../utils/profiles';

import { useChatPopup } from '../contexts/chat.context';
import { ChatIcon, MailIcon } from '../utils/icons';
import { SendMail } from '../components/send-mail';



export const useProfessional = (id?: string) => {
  const { data, ...rest } = useQuery<ProfessionalQuery, ProfessionalQueryVariables>(ProfessionalDocument, {
    variables: {
      id: id!,
    },
    skip: id === undefined,
  });

  return useMemo(() => ({
    professional: data?.professional,
    ...rest,
  }), [
    data,
    rest,
  ]);
}

export const useProfessionalProfile = (id?: string) => {
  const { data, ...rest } = useQuery<ProfessionalQuery, ProfessionalQueryVariables>(ProfessionalDocument, {
    variables: {
      id: id!,
    },
    skip: id === undefined,
    errorPolicy: 'all',
  });

  return useMemo(() => ({
    professional: data?.professional,
    ...rest,
  }), [
    data,
    rest,
  ]);
}

export const useLazyProfessional = () => {
  const [fetchProfessional, rest] = useLazyQuery<ProfessionalQuery, ProfessionalQueryVariables>(ProfessionalDocument);

  return useMemo(() => ({
    fetchProfessional,
    ...rest,
  }), [
    fetchProfessional,
    rest,
  ]);
}

export type ProfileAction = {
  icon: JSX.Element;
  name: string;
  callback: () => void;
};

export type ProfileDialActionListItem = {
  roles?: ProfessionalRole[];
  excludeIf?: Boolean;
  action: ProfileAction;
}

const canChat = (p: ProfessionalDataFragment) => {
  const hasUser = !!p.userId;
  if (isMedic(p) || isOperator(p)) {
    return hasUser && p.chatEnabled;
  }
  return hasUser;
}

export const useProfessionalDetailActions = (p: ProfessionalDataFragment, withConfirm?: Boolean) => {
  const { t } = useTranslation();
  const { isImpersonating, actingAs } = useProfile();
  const dialog = useDialog();
  const isMobile = useIsMobile();
  const { open } = useChatPopup();

  const openSendMail = useCallback(() => {
    if (!actingAs || !p.userId) {
      return;
    }
    dialog.open({
      body: (
        <SendMail
          onSend={dialog.close}
          onCancel={dialog.close}
          professional={actingAs}
          recipientIds={[p.userId]}
        />
      ),
      title: (
        <Box display="flex" alignItems="center">
          <Box mr={2} display="flex"><MailIcon /></Box>
          <Typography>{getProfessionalFullName(p, t)}</Typography>
        </Box>
      ),
      maxWidth: 'md',
      fullWidth: true,
      fullScreen: isMobile,
    })
  }, [actingAs, dialog, t, p, isMobile]);

  const allActions = useMemo<ProfileDialActionListItem[]>(() => [
    {
      excludeIf: isImpersonating,
      statuses: [NetworkStatus.Approved],
      action: {
        icon: <MailIcon />,
        name: t('menu:mail'),
        callback: openSendMail,
      },
    },
    ...canChat(p) ? [
      {
        excludeIf: isImpersonating,
        statuses: [NetworkStatus.Approved],
        action: {
          icon: <ChatIcon />,
          name: t('menu:chat'),
          callback: () => open(p.user!),
        },
      },
    ] : [],
  ], [t, isImpersonating, openSendMail, p, open]);

  return useMemo(() => (
    allActions
      .filter(({ roles, excludeIf }) => {
        if (excludeIf) return false;

        const roleStatusOk = !roles
          || (roles as string[]).includes(p.__typename!);

        return roleStatusOk;
      })
      .map(a => a.action)
  ), [allActions, p])
}
