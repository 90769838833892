/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  Time: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  Weekday: any;
  TaxId: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Professional = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  role: ProfessionalRole;
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  hasActiveLicense: Scalars['Boolean'];
  fullName: Scalars['String'];
  organizations: Array<OrganizationProfessional>;
  paymentMethods: Array<PaymentMethod>;
  licenses: Array<License>;
  specializations: Array<Specialization>;
  provisions: Array<ProfessionalProvision>;
  clinics: Array<Clinic>;
  networks: Array<Network>;
  user?: Maybe<User>;
  activeLicense?: Maybe<License>;
  privacyPolicy?: Maybe<Attachment>;
};

export type BaseEntity = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export enum ProfessionalRole {
  Medic = 'Medic',
  Assistant = 'Assistant',
  Operator = 'Operator',
  OrganizationSa = 'OrganizationSa'
}

export type AgendaEvent = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: AgendaEventType;
  day: Scalars['Date'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  notes: Scalars['String'];
  createdBy: Scalars['String'];
  professionalId: Scalars['String'];
  clinicId: Scalars['String'];
  clinic: ClinicRoom;
  professional: Professional;
  creator?: Maybe<User>;
};

export enum AgendaEventType {
  Appointment = 'Appointment',
  Break = 'Break'
}



export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  hasMore: Scalars['Boolean'];
  total: Scalars['Int'];
};

export type Specialization = BaseEntity & {
  __typename?: 'Specialization';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
};

export type Provision = BaseEntity & {
  __typename?: 'Provision';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  defaultPrice: Scalars['Float'];
  defaultDuration: Scalars['Int'];
  code: Scalars['String'];
  mandatory: Scalars['Boolean'];
};

export type Attachment = BaseEntity & {
  __typename?: 'Attachment';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  mimeType?: Maybe<Scalars['String']>;
  fileSize: Scalars['Int'];
  storeType: StoreType;
  url: Scalars['String'];
};

export enum StoreType {
  ScwBucket = 'SCW_BUCKET',
  External = 'EXTERNAL'
}

export type Point = {
  __typename?: 'Point';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Report = BaseEntity & {
  __typename?: 'Report';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  professionalId?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  medicName: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  createdBy: Scalars['String'];
  reportDate: Scalars['DateTime'];
  sharedWith: Array<PatientProfessional>;
  creator?: Maybe<User>;
  patient: Patient;
  attachments?: Maybe<Array<Attachment>>;
  professional?: Maybe<Professional>;
};

export type Organization = BaseEntity & {
  __typename?: 'Organization';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  serviceAccount: OrganizationSa;
  professionals: Array<OrganizationProfessional>;
};

export type OrganizationProfessional = BaseEntity & {
  __typename?: 'OrganizationProfessional';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  professionalId: Scalars['String'];
  role: OrganizationRole;
  organization: Organization;
  professional: Professional;
};

export enum OrganizationRole {
  Admin = 'ADMIN',
  Medic = 'MEDIC'
}

export type District = {
  __typename?: 'District';
  name: Scalars['String'];
  code: Scalars['String'];
};

export type Region = {
  __typename?: 'Region';
  name: Scalars['String'];
  code: Scalars['String'];
};

export type City = BaseEntity & {
  __typename?: 'City';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  code: Scalars['String'];
  registryCode: Scalars['String'];
  cap: Array<Scalars['String']>;
  isDistrict: Scalars['Boolean'];
  district: District;
  region: Region;
  coordinates?: Maybe<Point>;
};

export type EmbedAddress = {
  __typename?: 'EmbedAddress';
  address?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
  municipalityName?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  nationName?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Point>;
  municipalityId?: Maybe<Scalars['String']>;
  municipality?: Maybe<City>;
};

export type CryptedEmbedAddress = {
  __typename?: 'CryptedEmbedAddress';
  address: Scalars['String'];
  municipalityName: Scalars['String'];
  districtName: Scalars['String'];
  nationName: Scalars['String'];
  municipalityId?: Maybe<Scalars['String']>;
};

export type PatientProfessional = BaseEntity & {
  __typename?: 'PatientProfessional';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: PatientProfessionalStatus;
  createdBy: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<CryptedEmbedAddress>;
  professionalId?: Maybe<Scalars['String']>;
  organizationProfessionalId?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  reports: Array<Report>;
  fullName: Scalars['String'];
  patient: Patient;
  upcomingAppointments: Array<Appointment>;
  professional?: Maybe<Professional>;
};


export type PatientProfessionalUpcomingAppointmentsArgs = {
  daysLimit?: Maybe<Scalars['Int']>;
};

export enum PatientProfessionalStatus {
  RequestedByMedic = 'REQUESTED_BY_MEDIC',
  RequestedByPatient = 'REQUESTED_BY_PATIENT',
  Inactive = 'INACTIVE',
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Archived = 'ARCHIVED',
  Hidden = 'HIDDEN'
}

export type Clinic = BaseEntity & {
  __typename?: 'Clinic';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  professionalId: Scalars['String'];
  address: EmbedAddress;
  professional: Professional;
  rooms: Array<ClinicRoom>;
};

export type ClinicRoom = BaseEntity & {
  __typename?: 'ClinicRoom';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  meta: Scalars['JSON'];
  clinicId: Scalars['String'];
  provisions: Array<ProfessionalProvision>;
  clinic: Clinic;
};


export type ProfessionalProvision = BaseEntity & {
  __typename?: 'ProfessionalProvision';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  professionalId: Scalars['String'];
  provisionId: Scalars['String'];
  price: Scalars['Float'];
  duration: Scalars['Int'];
  mandatory: Scalars['Boolean'];
  provision: Provision;
};

export type AgendaEntry = BaseEntity & {
  __typename?: 'AgendaEntry';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  weekday: Scalars['Weekday'];
  override?: Maybe<Scalars['Date']>;
  disabled: Scalars['Boolean'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  informers: Scalars['Int'];
  assistantIds: Array<Scalars['String']>;
  visibility: AgendaEntryVisibility;
  agendaWeekId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  professionalId?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  clinic: ClinicRoom;
  professional?: Maybe<Professional>;
  assistants: Array<Maybe<Assistant>>;
};


export enum AgendaEntryVisibility {
  OnlyAssistants = 'ONLY_ASSISTANTS',
  All = 'ALL',
  OnlyAssisted = 'ONLY_ASSISTED',
  OnlyFreelancer = 'ONLY_FREELANCER'
}

export type AgendaWeek = BaseEntity & {
  __typename?: 'AgendaWeek';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  order: Scalars['Int'];
  agendaListId: Scalars['String'];
  entries: Array<AgendaEntry>;
};

export type AgendaList = BaseEntity & {
  __typename?: 'AgendaList';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  status: AgendaListStatus;
  from: Scalars['Date'];
  to?: Maybe<Scalars['Date']>;
  openFor: Scalars['Int'];
  professionalId: Scalars['String'];
  weeks: Array<AgendaWeek>;
};

export enum AgendaListStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Archived = 'ARCHIVED'
}

export type Network = BaseEntity & {
  __typename?: 'Network';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: NetworkStatus;
  canImpersonate: Scalars['Int'];
  ownerId: Scalars['String'];
  inverseId: Scalars['String'];
  inverse: Professional;
};

export enum NetworkStatus {
  Requested = 'REQUESTED',
  Approved = 'APPROVED',
  Received = 'RECEIVED',
  Blacklisted = 'BLACKLISTED'
}

export type PaymentMethod = BaseEntity & {
  __typename?: 'PaymentMethod';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: PaymentType;
  data: Scalars['JSON'];
};

export enum PaymentType {
  Stripe = 'STRIPE',
  Group = 'GROUP',
  Manual = 'MANUAL'
}

export type LicenseType = {
  __typename?: 'LicenseType';
  code: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stripeProduct: StripeProduct;
};

export type License = BaseEntity & {
  __typename?: 'License';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  expiresAt: Scalars['DateTime'];
  transactionType: PaymentType;
  transactionId: Scalars['String'];
  transactionData: Scalars['JSON'];
  code: Scalars['String'];
  licenseType: LicenseType;
  priceId: Scalars['String'];
};

export type EmbedContact = {
  __typename?: 'EmbedContact';
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
};

export type Medic = Professional & BaseEntity & {
  __typename?: 'Medic';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  role: ProfessionalRole;
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  hasActiveLicense: Scalars['Boolean'];
  fullName: Scalars['String'];
  organizations: Array<OrganizationProfessional>;
  paymentMethods: Array<PaymentMethod>;
  licenses: Array<License>;
  specializations: Array<Specialization>;
  provisions: Array<ProfessionalProvision>;
  clinics: Array<Clinic>;
  networks: Array<Network>;
  user?: Maybe<User>;
  activeLicense?: Maybe<License>;
  privacyPolicy?: Maybe<Attachment>;
  registerNumber?: Maybe<Scalars['String']>;
  type?: Maybe<MedicType>;
  freeLancer: Scalars['Boolean'];
  contacts?: Maybe<EmbedContact>;
  termsAndConditions: Scalars['Boolean'];
  registerDistrictId?: Maybe<Scalars['String']>;
  chatEnabled: Scalars['Boolean'];
  requestsEnabled: Scalars['Boolean'];
  registerDistrict?: Maybe<City>;
};

export enum MedicType {
  Mmg = 'MMG',
  Pls = 'PLS'
}

export type Assistant = Professional & BaseEntity & {
  __typename?: 'Assistant';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  role: ProfessionalRole;
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  hasActiveLicense: Scalars['Boolean'];
  fullName: Scalars['String'];
  organizations: Array<OrganizationProfessional>;
  paymentMethods: Array<PaymentMethod>;
  licenses: Array<License>;
  specializations: Array<Specialization>;
  provisions: Array<ProfessionalProvision>;
  clinics: Array<Clinic>;
  networks: Array<Network>;
  user?: Maybe<User>;
  activeLicense?: Maybe<License>;
  privacyPolicy?: Maybe<Attachment>;
  assistantTermsAndConditions: Scalars['Boolean'];
};

export type Operator = Professional & BaseEntity & {
  __typename?: 'Operator';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  role: ProfessionalRole;
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  hasActiveLicense: Scalars['Boolean'];
  fullName: Scalars['String'];
  organizations: Array<OrganizationProfessional>;
  paymentMethods: Array<PaymentMethod>;
  licenses: Array<License>;
  specializations: Array<Specialization>;
  provisions: Array<ProfessionalProvision>;
  clinics: Array<Clinic>;
  networks: Array<Network>;
  user?: Maybe<User>;
  activeLicense?: Maybe<License>;
  privacyPolicy?: Maybe<Attachment>;
  profession?: Maybe<OperatorProfession>;
  chatEnabled: Scalars['Boolean'];
  requestsEnabled: Scalars['Boolean'];
  contacts?: Maybe<EmbedContact>;
  termsAndConditions: Scalars['Boolean'];
};

export enum OperatorProfession {
  Nurse = 'NURSE',
  Psychologist = 'PSYCHOLOGIST',
  Physiotherapist = 'PHYSIOTHERAPIST',
  Dietician = 'DIETICIAN',
  Podiatrist = 'PODIATRIST',
  Pharmacist = 'PHARMACIST'
}

export type OrganizationSa = Professional & BaseEntity & {
  __typename?: 'OrganizationSa';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  role: ProfessionalRole;
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  hasActiveLicense: Scalars['Boolean'];
  fullName: Scalars['String'];
  organizations: Array<OrganizationProfessional>;
  paymentMethods: Array<PaymentMethod>;
  licenses: Array<License>;
  specializations: Array<Specialization>;
  provisions: Array<ProfessionalProvision>;
  clinics: Array<Clinic>;
  networks: Array<Network>;
  user?: Maybe<User>;
  activeLicense?: Maybe<License>;
  privacyPolicy?: Maybe<Attachment>;
  contacts?: Maybe<EmbedContact>;
  managedOrganization: Organization;
};

export type PatientUser = BaseEntity & {
  __typename?: 'PatientUser';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  type: PatientType;
  termsAndConditions: Scalars['Boolean'];
  patientId: Scalars['String'];
  patient: Patient;
  user: User;
};

export enum PatientType {
  Owner = 'OWNER',
  FamilyMember = 'FAMILY_MEMBER',
  Tutor = 'TUTOR'
}

export type Patient = BaseEntity & {
  __typename?: 'Patient';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  surname: Scalars['String'];
  taxId: Scalars['TaxId'];
  birthDay: Scalars['DateTime'];
  createdBy: Scalars['String'];
  fullName: Scalars['String'];
  personalMedicId?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  personalMedic?: Maybe<Medic>;
  patientProfessionals: Array<PatientProfessional>;
  users: Array<PatientUser>;
};


export type Appointment = AgendaEvent & BaseEntity & {
  __typename?: 'Appointment';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: AgendaEventType;
  day: Scalars['Date'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  notes: Scalars['String'];
  createdBy: Scalars['String'];
  professionalId: Scalars['String'];
  clinicId: Scalars['String'];
  clinic: ClinicRoom;
  professional: Professional;
  creator?: Maybe<User>;
  patientName?: Maybe<Scalars['String']>;
  patientTaxId?: Maybe<Scalars['TaxId']>;
  patientProfessionalId?: Maybe<Scalars['String']>;
  provisionId?: Maybe<Scalars['String']>;
  provision?: Maybe<ProfessionalProvision>;
  patientProfessional?: Maybe<PatientProfessional>;
};

export type Break = AgendaEvent & BaseEntity & {
  __typename?: 'Break';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: AgendaEventType;
  day: Scalars['Date'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  notes: Scalars['String'];
  createdBy: Scalars['String'];
  professionalId: Scalars['String'];
  clinicId: Scalars['String'];
  clinic: ClinicRoom;
  professional: Professional;
  creator?: Maybe<User>;
};

export type Notification = BaseEntity & {
  __typename?: 'Notification';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  destinationProfile?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  readAt?: Maybe<Scalars['DateTime']>;
  data: Scalars['JSON'];
};

export type RequestMessage = BaseEntity & {
  __typename?: 'RequestMessage';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  message?: Maybe<Scalars['String']>;
  readAt?: Maybe<Scalars['DateTime']>;
  createdBy: Scalars['String'];
  patientId?: Maybe<Scalars['String']>;
  author?: Maybe<RequestAuthor>;
  creator?: Maybe<User>;
  attachments?: Maybe<Array<Attachment>>;
};

export type RequestAuthor = Medic | Operator | Patient | OrganizationSa;

export type Request = BaseEntity & {
  __typename?: 'Request';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  result?: Maybe<RequestResult>;
  createdBy: Scalars['String'];
  private: Scalars['Boolean'];
  type: RequestType;
  senderPatientId?: Maybe<Scalars['String']>;
  recipientPatientId?: Maybe<Scalars['String']>;
  messages: Array<RequestMessage>;
  creator?: Maybe<User>;
  recipient: RequestAuthor;
  sender: RequestAuthor;
  recipientPatient?: Maybe<Patient>;
  senderPatient?: Maybe<Patient>;
};

export enum RequestResult {
  Ok = 'OK',
  Ko = 'KO'
}

export enum RequestType {
  HealthAdvice = 'HEALTH_ADVICE',
  DrugsExams = 'DRUGS_EXAMS',
  Informations = 'INFORMATIONS',
  MedicRequest = 'MEDIC_REQUEST'
}

export type Invite = BaseEntity & {
  __typename?: 'Invite';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  email: Scalars['String'];
  type: InviteType;
  inviteRole?: Maybe<Scalars['String']>;
  invitedUserId?: Maybe<Scalars['String']>;
  inviter: Professional;
};

export enum InviteType {
  Assistant = 'ASSISTANT',
  Organization = 'ORGANIZATION',
  Patient = 'PATIENT'
}

export type UserTraits = {
  __typename?: 'UserTraits';
  email: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  birthDay?: Maybe<Scalars['DateTime']>;
};

export type UserEmailAddress = {
  __typename?: 'UserEmailAddress';
  id: Scalars['ID'];
  value: Scalars['String'];
  verified: Scalars['Boolean'];
  via: Scalars['String'];
  status: Scalars['String'];
  verifiedAt?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  role: Scalars['String'];
  traits: UserTraits;
  verifiableAddresses: Array<UserEmailAddress>;
};

export type PatientPaginatedResponse = {
  __typename?: 'PatientPaginatedResponse';
  items: Array<Patient>;
  paginationInfo: PaginationInfo;
};

export type NotificationPaginatedResponse = {
  __typename?: 'NotificationPaginatedResponse';
  items: Array<Notification>;
  paginationInfo: PaginationInfo;
};

export type ProfessionalPaginatedResponse = {
  __typename?: 'ProfessionalPaginatedResponse';
  items: Array<Professional>;
  paginationInfo: PaginationInfo;
};

export type PatientProfessionalPaginatedResponse = {
  __typename?: 'PatientProfessionalPaginatedResponse';
  items: Array<PatientProfessional>;
  paginationInfo: PaginationInfo;
};

export type StripePrice = {
  __typename?: 'StripePrice';
  id: Scalars['String'];
  productId: Scalars['String'];
  amount: Scalars['Float'];
};

export type StripeProduct = {
  __typename?: 'StripeProduct';
  code: Scalars['String'];
  id: Scalars['String'];
  monthlyId: Scalars['String'];
  yearlyId: Scalars['String'];
  monthly: StripePrice;
  yearly: StripePrice;
};

export type CityPaginatedResponse = {
  __typename?: 'CityPaginatedResponse';
  items: Array<City>;
  paginationInfo: PaginationInfo;
};

export type CupProvider = {
  __typename?: 'CupProvider';
  id: Scalars['ID'];
  date: Scalars['Date'];
  duration: Scalars['String'];
  price: Scalars['String'];
  address: Scalars['String'];
  addressName: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
  trackUrl: Scalars['String'];
};

export type ProvisionPaginatedResponse = {
  __typename?: 'ProvisionPaginatedResponse';
  items: Array<Provision>;
  paginationInfo: PaginationInfo;
};

export type AgendaEntryInDay = {
  __typename?: 'AgendaEntryInDay';
  id: Scalars['ID'];
  weekday: Scalars['Weekday'];
  override?: Maybe<Scalars['Date']>;
  disabled: Scalars['Boolean'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  informers: Scalars['Int'];
  assistantIds: Array<Scalars['String']>;
  visibility: AgendaEntryVisibility;
  day: Scalars['Date'];
  clinicId: Scalars['String'];
  professionalId?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  agendaWeekId?: Maybe<Scalars['String']>;
  professional?: Maybe<Professional>;
  assistants: Array<Maybe<Assistant>>;
  clinic: ClinicRoom;
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  day: Scalars['Date'];
  slots: Array<Scalars['Time']>;
};

export type ClinicRoomFromDay = {
  __typename?: 'ClinicRoomFromDay';
  room: ClinicRoom;
  fromDay: Scalars['Date'];
};

export type RequestPaginatedResponse = {
  __typename?: 'RequestPaginatedResponse';
  items: Array<Request>;
  paginationInfo: PaginationInfo;
};

export type ReportsPaginatedResponse = {
  __typename?: 'ReportsPaginatedResponse';
  items: Array<Report>;
  paginationInfo: PaginationInfo;
};

export type Query = {
  __typename?: 'Query';
  agendaEntriesInDays: Array<AgendaEntryInDay>;
  agendaEntryByDay: Array<AgendaEntryInDay>;
  agendaEvent: AgendaEvent;
  agendaEventsByDay: Array<AgendaEvent>;
  agendaEventsInDays: Array<AgendaEvent>;
  agendaList: AgendaList;
  agendaLists: Array<AgendaList>;
  appointment: Appointment;
  assistantProfile?: Maybe<Assistant>;
  cities: CityPaginatedResponse;
  city: City;
  clinic: Clinic;
  clinicRoomByProvision: Array<ClinicRoomFromDay>;
  clinics: Array<Clinic>;
  cupProviders: Array<CupProvider>;
  defaultProvisions: Array<Provision>;
  getInvoice?: Maybe<Invoice>;
  /** Restituisce primo numero fattura disponibile */
  getInvoiceNextNumber?: Maybe<Scalars['Int']>;
  /** Get user level options for invoice system */
  getInvoiceOptions?: Maybe<InvoiceOptions>;
  /** Return a list of valid payment types */
  getInvoicePaymentTypes: Array<InvoicePaymentType>;
  /** Return a list of valid vat regimes */
  getInvoiceVatCodes: Array<InvoiceVatCode>;
  /** Get all invoices for a professional, optionally filtered by year */
  getInvoices?: Maybe<InvoicePaginator>;
  /** Get all invoices issued to a patient id optionally filtered by year */
  getInvoicesByPatient?: Maybe<InvoicePaginator>;
  /** Get all invoices issued to a tax_id optionally filtered by year */
  getInvoicesByRecipientTaxId?: Maybe<InvoicePaginator>;
  impersonateMedic: Medic;
  invite: Invite;
  licenseType: LicenseType;
  licenseTypes: Array<LicenseType>;
  managedOrganizations: Array<OrganizationProfessional>;
  medicProfile?: Maybe<Medic>;
  network: Network;
  notification: Notification;
  notifications: NotificationPaginatedResponse;
  operatorProfile?: Maybe<Operator>;
  organization: Organization;
  organizations: Array<Organization>;
  patient: Patient;
  patientProfessional: PatientProfessional;
  patientProfessionals: PatientProfessionalPaginatedResponse;
  patientProfiles?: Maybe<Array<PatientUser>>;
  patientReports: ReportsPaginatedResponse;
  patientToAssociate?: Maybe<Patient>;
  patientUser: PatientUser;
  patientUsers: Array<PatientUser>;
  patients: PatientPaginatedResponse;
  pendingInvites: Array<Invite>;
  professional: Professional;
  professionalReport: Report;
  professionalReports: ReportsPaginatedResponse;
  professionals: ProfessionalPaginatedResponse;
  professionalsByEmail: Array<Professional>;
  provision: Provision;
  provisions: ProvisionPaginatedResponse;
  receivedRequestsAsProfessional: RequestPaginatedResponse;
  report: Report;
  request: Request;
  searchPatientByTaxId?: Maybe<SearchPatientUnion>;
  searchPatientProfessionals: PatientProfessionalPaginatedResponse;
  sentInvites: Array<Invite>;
  sentRequestsAsPatient: RequestPaginatedResponse;
  sentRequestsAsProfessional: RequestPaginatedResponse;
  specialization: Specialization;
  specializations: Array<Specialization>;
  timeslots: Array<TimeSlot>;
  whoami: User;
};


export type QueryAgendaEntriesInDaysArgs = {
  clinicIds?: Maybe<Array<Scalars['String']>>;
  to: Scalars['Date'];
  from: Scalars['Date'];
  medicId: Scalars['String'];
};


export type QueryAgendaEntryByDayArgs = {
  clinicIds?: Maybe<Array<Scalars['String']>>;
  day: Scalars['Date'];
  medicId: Scalars['String'];
};


export type QueryAgendaEventArgs = {
  id: Scalars['ID'];
};


export type QueryAgendaEventsByDayArgs = {
  clinicIds?: Maybe<Array<Scalars['String']>>;
  day: Scalars['Date'];
  medicId: Scalars['String'];
};


export type QueryAgendaEventsInDaysArgs = {
  clinicIds?: Maybe<Array<Scalars['String']>>;
  to: Scalars['Date'];
  from: Scalars['Date'];
  medicId: Scalars['String'];
};


export type QueryAgendaListArgs = {
  id: Scalars['ID'];
};


export type QueryAgendaListsArgs = {
  filter?: Maybe<MedicAgendaFilterInput>;
  order?: Maybe<MedicAgendaOrderInput>;
  medicId: Scalars['String'];
};


export type QueryAppointmentArgs = {
  id: Scalars['ID'];
};


export type QueryCitiesArgs = {
  filter?: Maybe<CityFilterInput>;
  order?: Maybe<CityOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCityArgs = {
  id: Scalars['ID'];
};


export type QueryClinicArgs = {
  id: Scalars['ID'];
};


export type QueryClinicRoomByProvisionArgs = {
  distance?: Maybe<Scalars['Int']>;
  coordinates?: Maybe<LatLngInput>;
  medicId?: Maybe<Scalars['String']>;
  from: Scalars['Date'];
  patientId: Scalars['String'];
  provisionId: Scalars['String'];
};


export type QueryClinicsArgs = {
  filter?: Maybe<FindClinicFilterInput>;
  order?: Maybe<ClinicOrderInput>;
};


export type QueryCupProvidersArgs = {
  coordinates: LatLngInput;
  provisionId: Scalars['String'];
};


export type QueryGetInvoiceArgs = {
  invoiceId: Scalars['Int'];
};


export type QueryGetInvoiceNextNumberArgs = {
  professionalId: Scalars['ID'];
  year: Scalars['Int'];
};


export type QueryGetInvoiceOptionsArgs = {
  professionalId?: Maybe<Scalars['Int']>;
};


export type QueryGetInvoicesArgs = {
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  professionalId: Scalars['Int'];
  sortBy?: Maybe<Array<GetInvoicesSortByOrderByClause>>;
  year?: Maybe<Scalars['Int']>;
};


export type QueryGetInvoicesByPatientArgs = {
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  patient_id: Scalars['Int'];
  professional_id: Scalars['Int'];
  sortBy?: Maybe<Array<GetInvoicesByPatientSortByOrderByClause>>;
  year?: Maybe<Scalars['Int']>;
};


export type QueryGetInvoicesByRecipientTaxIdArgs = {
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  recipient_tax_id: Scalars['String'];
  sortBy?: Maybe<Array<GetInvoicesByRecipientTaxIdSortByOrderByClause>>;
  year?: Maybe<Scalars['Int']>;
};


export type QueryImpersonateMedicArgs = {
  id: Scalars['String'];
};


export type QueryInviteArgs = {
  id: Scalars['ID'];
};


export type QueryLicenseTypeArgs = {
  code: Scalars['String'];
};


export type QueryNetworkArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationsArgs = {
  filter?: Maybe<NotificationFilterInput>;
  order?: Maybe<NotificationOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationsArgs = {
  filter?: Maybe<OrganizationFilterInput>;
  order?: Maybe<OrganizationOrderInput>;
};


export type QueryPatientArgs = {
  id: Scalars['ID'];
};


export type QueryPatientProfessionalArgs = {
  id: Scalars['ID'];
};


export type QueryPatientProfessionalsArgs = {
  filter?: Maybe<PatientProfessionalFilterInput>;
  order?: Maybe<PatientProfessionalOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  medicId: Scalars['String'];
};


export type QueryPatientReportsArgs = {
  filter?: Maybe<ReportFilterInput>;
  order?: Maybe<ReportOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  patientId: Scalars['String'];
};


export type QueryPatientUserArgs = {
  id: Scalars['ID'];
};


export type QueryPatientUsersArgs = {
  filter?: Maybe<PatientUserFilterInput>;
  order?: Maybe<PatientUserOrderInput>;
};


export type QueryPatientsArgs = {
  filter?: Maybe<PatientFilterInput>;
  order?: Maybe<PatientOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryProfessionalArgs = {
  id: Scalars['ID'];
};


export type QueryProfessionalReportArgs = {
  id: Scalars['String'];
  medicId: Scalars['String'];
};


export type QueryProfessionalReportsArgs = {
  order?: Maybe<ReportOrderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  medicId: Scalars['String'];
};


export type QueryProfessionalsArgs = {
  filter?: Maybe<ProfessionalFilterInput>;
  order?: Maybe<ProfessionalOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryProfessionalsByEmailArgs = {
  email: Scalars['String'];
};


export type QueryProvisionArgs = {
  id: Scalars['ID'];
};


export type QueryProvisionsArgs = {
  filter?: Maybe<ProvisionFilterInput>;
  order?: Maybe<ProvisionOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryReceivedRequestsAsProfessionalArgs = {
  filter?: Maybe<RequestFilterInput>;
  order?: Maybe<RequestOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  medicId: Scalars['String'];
};


export type QueryReportArgs = {
  id: Scalars['ID'];
};


export type QueryRequestArgs = {
  id: Scalars['ID'];
};


export type QuerySearchPatientByTaxIdArgs = {
  taxId: Scalars['TaxId'];
};


export type QuerySearchPatientProfessionalsArgs = {
  medicId: Scalars['String'];
  filter?: Maybe<PatientProfessionalFilterInput>;
  order?: Maybe<PatientProfessionalOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  patientSearch: Scalars['String'];
};


export type QuerySentInvitesArgs = {
  medicId: Scalars['String'];
};


export type QuerySentRequestsAsPatientArgs = {
  filter?: Maybe<RequestFilterInput>;
  order?: Maybe<RequestOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  patientId: Scalars['String'];
};


export type QuerySentRequestsAsProfessionalArgs = {
  filter?: Maybe<RequestFilterInput>;
  order?: Maybe<RequestOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  medicId: Scalars['String'];
};


export type QuerySpecializationArgs = {
  id: Scalars['ID'];
};


export type QuerySpecializationsArgs = {
  filter?: Maybe<SpecializationFilterInput>;
  order?: Maybe<SpecializationOrderInput>;
};


export type QueryTimeslotsArgs = {
  clinicIds?: Maybe<Array<Scalars['String']>>;
  to: Scalars['Date'];
  from: Scalars['Date'];
  provisionId: Scalars['String'];
};

export type NotificationFilterInput = {
  _or?: Maybe<Array<NotificationFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  userId?: Maybe<StringFieldFilterInput>;
  destinationProfile?: Maybe<StringFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  readAt?: Maybe<DateFieldFilterInput>;
};

export type IdFieldFilterInput = {
  eq?: Maybe<Scalars['ID']>;
  ne?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Scalars['ID']>>;
  nin?: Maybe<Array<Scalars['ID']>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type DateFieldFilterInput = {
  eq?: Maybe<Scalars['DateTime']>;
  ne?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  nin?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type StringFieldFilterInput = {
  eq?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  nin?: Maybe<Array<Scalars['String']>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type NotificationOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  userId?: Maybe<Order>;
  destinationProfile?: Maybe<Order>;
  name?: Maybe<Order>;
  readAt?: Maybe<Order>;
};

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PatientFilterInput = {
  _or?: Maybe<Array<PatientFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  surname?: Maybe<StringFieldFilterInput>;
  taxId?: Maybe<TaxIdScalarFieldFilterInput>;
  birthDay?: Maybe<DateFieldFilterInput>;
  createdBy?: Maybe<StringFieldFilterInput>;
};

export type TaxIdScalarFieldFilterInput = {
  eq?: Maybe<Scalars['TaxId']>;
  ne?: Maybe<Scalars['TaxId']>;
  in?: Maybe<Array<Scalars['TaxId']>>;
  nin?: Maybe<Array<Scalars['TaxId']>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type PatientOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
  surname?: Maybe<Order>;
  taxId?: Maybe<Order>;
  birthDay?: Maybe<Order>;
  createdBy?: Maybe<Order>;
};

export type SearchPatientUnion = PublicPatient | Patient;

export type PublicPatient = {
  __typename?: 'PublicPatient';
  taxId: Scalars['TaxId'];
};

export type PatientUserFilterInput = {
  _or?: Maybe<Array<PatientUserFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  userId?: Maybe<StringFieldFilterInput>;
  type?: Maybe<PatientTypeFieldFilterInput>;
  patient?: Maybe<PatientFilterInput>;
};

export type PatientTypeFieldFilterInput = {
  eq?: Maybe<PatientType>;
  ne?: Maybe<PatientType>;
  in?: Maybe<Array<PatientType>>;
  nin?: Maybe<Array<PatientType>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type PatientUserOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  userId?: Maybe<Order>;
  type?: Maybe<Order>;
};

export type ProfessionalFilterInput = {
  _or?: Maybe<Array<ProfessionalFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  role?: Maybe<ProfessionalRoleFieldFilterInput>;
  userId?: Maybe<StringFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  surname?: Maybe<StringFieldFilterInput>;
  organizations?: Maybe<OrganizationProfessionalFilterInput>;
  paymentMethods?: Maybe<PaymentMethodFilterInput>;
  licenses?: Maybe<LicenseFilterInput>;
  specializations?: Maybe<SpecializationFilterInput>;
  provisions?: Maybe<ProfessionalProvisionFilterInput>;
};

export type ProfessionalRoleFieldFilterInput = {
  eq?: Maybe<ProfessionalRole>;
  ne?: Maybe<ProfessionalRole>;
  in?: Maybe<Array<ProfessionalRole>>;
  nin?: Maybe<Array<ProfessionalRole>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type OrganizationProfessionalFilterInput = {
  _or?: Maybe<Array<OrganizationProfessionalFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
};

export type PaymentMethodFilterInput = {
  _or?: Maybe<Array<PaymentMethodFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  type?: Maybe<PaymentTypeFieldFilterInput>;
  data?: Maybe<JsonFieldFilterInput>;
};

export type PaymentTypeFieldFilterInput = {
  eq?: Maybe<PaymentType>;
  ne?: Maybe<PaymentType>;
  in?: Maybe<Array<PaymentType>>;
  nin?: Maybe<Array<PaymentType>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type JsonFieldFilterInput = {
  eq?: Maybe<Scalars['JSON']>;
  ne?: Maybe<Scalars['JSON']>;
  in?: Maybe<Array<Scalars['JSON']>>;
  nin?: Maybe<Array<Scalars['JSON']>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type LicenseFilterInput = {
  _or?: Maybe<Array<LicenseFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  expiresAt?: Maybe<DateFieldFilterInput>;
  transactionType?: Maybe<PaymentTypeFieldFilterInput>;
  transactionId?: Maybe<StringFieldFilterInput>;
  licenseType?: Maybe<LicenseTypeFilterInput>;
};

export type LicenseTypeFilterInput = {
  _or?: Maybe<Array<LicenseTypeFilterInput>>;
};

export type SpecializationFilterInput = {
  _or?: Maybe<Array<SpecializationFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
};

export type ProfessionalProvisionFilterInput = {
  _or?: Maybe<Array<ProfessionalProvisionFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  professionalId?: Maybe<StringFieldFilterInput>;
  provisionId?: Maybe<StringFieldFilterInput>;
  price?: Maybe<FloatFieldFilterInput>;
  duration?: Maybe<IntFieldFilterInput>;
  mandatory?: Maybe<BooleanFieldFilterInput>;
  provision?: Maybe<ProvisionFilterInput>;
};

export type FloatFieldFilterInput = {
  eq?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  nin?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type IntFieldFilterInput = {
  eq?: Maybe<Scalars['Int']>;
  ne?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  nin?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type BooleanFieldFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type ProvisionFilterInput = {
  _or?: Maybe<Array<ProvisionFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  code?: Maybe<StringFieldFilterInput>;
};

export type ProfessionalOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  role?: Maybe<Order>;
  userId?: Maybe<Order>;
  name?: Maybe<Order>;
  surname?: Maybe<Order>;
};

export type PatientProfessionalFilterInput = {
  _or?: Maybe<Array<PatientProfessionalFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  status?: Maybe<PatientProfessionalStatusFieldFilterInput>;
  createdBy?: Maybe<StringFieldFilterInput>;
  email?: Maybe<StringFieldFilterInput>;
  phone?: Maybe<StringFieldFilterInput>;
  reports?: Maybe<ReportFilterInput>;
};

export type PatientProfessionalStatusFieldFilterInput = {
  eq?: Maybe<PatientProfessionalStatus>;
  ne?: Maybe<PatientProfessionalStatus>;
  in?: Maybe<Array<PatientProfessionalStatus>>;
  nin?: Maybe<Array<PatientProfessionalStatus>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type ReportFilterInput = {
  _or?: Maybe<Array<ReportFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  medicName?: Maybe<StringFieldFilterInput>;
  title?: Maybe<StringFieldFilterInput>;
  description?: Maybe<StringFieldFilterInput>;
  createdBy?: Maybe<StringFieldFilterInput>;
  reportDate?: Maybe<DateFieldFilterInput>;
};

export type PatientProfessionalOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  status?: Maybe<Order>;
  createdBy?: Maybe<Order>;
  email?: Maybe<Order>;
  phone?: Maybe<Order>;
};

export type CityFilterInput = {
  _or?: Maybe<Array<CityFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  code?: Maybe<StringFieldFilterInput>;
  registryCode?: Maybe<StringFieldFilterInput>;
  isDistrict?: Maybe<CityIsDistrictFieldFilterInput>;
};

export type CityIsDistrictFieldFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
};

export type CityOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
  code?: Maybe<Order>;
  registryCode?: Maybe<Order>;
  isDistrict?: Maybe<Order>;
};

export type OrganizationFilterInput = {
  _or?: Maybe<Array<OrganizationFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  serviceAccount?: Maybe<OrganizationSaFilterInput>;
};

export type OrganizationSaFilterInput = {
  _or?: Maybe<Array<OrganizationSaFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  role?: Maybe<ProfessionalRoleFieldFilterInput>;
  userId?: Maybe<StringFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  surname?: Maybe<StringFieldFilterInput>;
  organizations?: Maybe<OrganizationProfessionalFilterInput>;
  paymentMethods?: Maybe<PaymentMethodFilterInput>;
  licenses?: Maybe<LicenseFilterInput>;
  specializations?: Maybe<SpecializationFilterInput>;
  provisions?: Maybe<ProfessionalProvisionFilterInput>;
};

export type OrganizationOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
};

export type ProvisionOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
  code?: Maybe<Order>;
};

export type LatLngInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type MedicAgendaFilterInput = {
  _or?: Maybe<Array<AgendaListFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  status?: Maybe<AgendaListStatusFieldFilterInput>;
  openFor?: Maybe<IntFieldFilterInput>;
  from?: Maybe<DateDFilterInputType>;
  to?: Maybe<DateDFilterInputType>;
};

export type AgendaListFilterInput = {
  _or?: Maybe<Array<AgendaListFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  status?: Maybe<AgendaListStatusFieldFilterInput>;
  openFor?: Maybe<IntFieldFilterInput>;
};

export type AgendaListStatusFieldFilterInput = {
  eq?: Maybe<AgendaListStatus>;
  ne?: Maybe<AgendaListStatus>;
  in?: Maybe<Array<AgendaListStatus>>;
  nin?: Maybe<Array<AgendaListStatus>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type DateDFilterInputType = {
  eq?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Scalars['Date']>>;
  nin?: Maybe<Array<Scalars['Date']>>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type MedicAgendaOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
  status?: Maybe<Order>;
  from?: Maybe<Order>;
  to?: Maybe<Order>;
  openFor?: Maybe<Order>;
};

export type FindClinicFilterInput = {
  _or?: Maybe<Array<ClinicFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  phone?: Maybe<StringFieldFilterInput>;
  professional?: Maybe<ProfessionalFilterInput>;
  geolocation?: Maybe<GeolocationFilterInput>;
  address?: Maybe<AddressFilterInput>;
};

export type ClinicFilterInput = {
  _or?: Maybe<Array<ClinicFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  phone?: Maybe<StringFieldFilterInput>;
  professional?: Maybe<ProfessionalFilterInput>;
};

export type GeolocationFilterInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  distance?: Maybe<Scalars['Int']>;
};

export type AddressFilterInput = {
  address?: Maybe<StringFieldFilterInput>;
  coordinates?: Maybe<GeolocationFilterInput>;
};

export type ClinicOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
  phone?: Maybe<Order>;
};

export type RequestFilterInput = {
  _or?: Maybe<Array<RequestFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  title?: Maybe<StringFieldFilterInput>;
  result?: Maybe<RequestResultFieldFilterInput>;
  createdBy?: Maybe<StringFieldFilterInput>;
  type?: Maybe<RequestTypeFieldFilterInput>;
  messages?: Maybe<RequestMessageFilterInput>;
};

export type RequestResultFieldFilterInput = {
  eq?: Maybe<RequestResult>;
  ne?: Maybe<RequestResult>;
  in?: Maybe<Array<RequestResult>>;
  nin?: Maybe<Array<RequestResult>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type RequestTypeFieldFilterInput = {
  eq?: Maybe<RequestType>;
  ne?: Maybe<RequestType>;
  in?: Maybe<Array<RequestType>>;
  nin?: Maybe<Array<RequestType>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type RequestMessageFilterInput = {
  _or?: Maybe<Array<RequestMessageFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  message?: Maybe<StringFieldFilterInput>;
  readAt?: Maybe<DateFieldFilterInput>;
  createdBy?: Maybe<StringFieldFilterInput>;
};

export type RequestOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  title?: Maybe<Order>;
  result?: Maybe<Order>;
  createdBy?: Maybe<Order>;
  type?: Maybe<Order>;
};

export type SpecializationOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
};

export type ReportOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  medicName?: Maybe<Order>;
  title?: Maybe<Order>;
  description?: Maybe<Order>;
  createdBy?: Maybe<Order>;
  reportDate?: Maybe<Order>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptAssistantInvite: Professional;
  acceptOrganizationInvite: OrganizationProfessional;
  addChatUser: Scalars['Boolean'];
  addSpecializationToMedic: Specialization;
  answerRequestAsProfessional: RequestMessage;
  archivePatientProfessional: PatientProfessional;
  assignMedicToEntry: AgendaEntry;
  assignPersonalMedic?: Maybe<Medic>;
  copyAgendaList: AgendaList;
  createAgendaEntry: AgendaEntry;
  createAgendaList: AgendaList;
  createAgendaWeek: AgendaWeek;
  createAppointment: Appointment;
  createAppointmentAsPatient: Appointment;
  createBreak: Break;
  createChat: Scalars['String'];
  createClinic: Clinic;
  createClinicRoom: ClinicRoom;
  createManyAgendaEntry: Array<AgendaEntry>;
  createMedic: Medic;
  /** @deprecated use createProfessionalProvision */
  createMedicProvision: ProfessionalProvision;
  createOperator: Operator;
  createOrUpdateInvoice?: Maybe<Invoice>;
  createOrUpdateInvoiceOptions?: Maybe<InvoiceOptions>;
  createOrUpdateInvoiceRow?: Maybe<InvoiceRow>;
  createOverrideEntry: AgendaEntryInDay;
  createPatientProfessional: PatientProfessional;
  createPatientProfessionalAsPatient: PatientProfessional;
  createProfessionalProvision: ProfessionalProvision;
  createReportAsPatient: Report;
  createReportAsProfessional: Report;
  createRequestAsPatient: Request;
  createRequestAsProfessional: Request;
  createUserPatient: PatientUser;
  deleteAgendaEntry: Scalars['ID'];
  deleteAgendaList: Scalars['ID'];
  deleteAgendaWeek: Scalars['ID'];
  deleteAppointment: Scalars['ID'];
  deleteAppointmentAsPatient: Scalars['ID'];
  deleteAssistantProfile?: Maybe<Scalars['ID']>;
  deleteBreak: Scalars['ID'];
  deleteClinic: Scalars['ID'];
  deleteClinicRoom: Scalars['ID'];
  deleteConnection: Scalars['ID'];
  deleteInvite: Scalars['Boolean'];
  deleteInvoice?: Maybe<Invoice>;
  deleteInvoiceRow?: Maybe<InvoiceRow>;
  deleteMedicProfile?: Maybe<Scalars['ID']>;
  deleteOperatorProfile?: Maybe<Scalars['ID']>;
  deleteProfessionalProvision: Scalars['ID'];
  deleteReport: Scalars['ID'];
  deleteReportAsProfessional: Scalars['ID'];
  deleteRequest: Scalars['ID'];
  deleteUserPatient: Scalars['ID'];
  dissociateFromEntry: Scalars['ID'];
  getChatAccessToken: Scalars['String'];
  grantUserAccessToPatient: PatientUser;
  inviteAssistant: Invite;
  invitePatient: Invite;
  inviteToOrganization: Invite;
  markManyNotificationsAsRead: Array<Notification>;
  markNotificationAsRead: Notification;
  markRequestMessageAsReadAsProfessional: RequestMessage;
  mockNotification: Notification;
  overrideDay: Array<AgendaEntryInDay>;
  removeProfessionalFromOrganization: Scalars['ID'];
  removeSpecializationFromMedic: Scalars['ID'];
  requestConnection: Network;
  resetDay: Array<AgendaEntryInDay>;
  resolveRequest: Request;
  revokeReportShare: Scalars['ID'];
  revokeSharePatientWithProfessional: Scalars['Boolean'];
  revokeUserAccessToPatient: Scalars['ID'];
  sendMail: Scalars['Boolean'];
  sendMassiveEmail: Scalars['Boolean'];
  sharePatientWithProfessional: Scalars['Boolean'];
  shareReport: PatientProfessional;
  updateAgendaEntry: AgendaEntry;
  updateAgendaList: AgendaList;
  updateAgendaWeek: AgendaWeek;
  updateAppointment: Appointment;
  updateAssistant: Assistant;
  updateBreak: Break;
  updateClinic: Clinic;
  updateClinicRoom: ClinicRoom;
  updateConnection: Network;
  updateMedic: Medic;
  updateOperator: Operator;
  updatePatient: Patient;
  updatePatientProfessional: PatientProfessional;
  updatePatientProfessionalStatus: PatientProfessional;
  updateProfessionalProvision: ProfessionalProvision;
  updateReportAsProfessional: Report;
  uploadPrivacyPolicy: Attachment;
};


export type MutationAcceptAssistantInviteArgs = {
  assistantTermsAndConditions: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationAcceptOrganizationInviteArgs = {
  id: Scalars['String'];
  medicId: Scalars['String'];
};


export type MutationAddChatUserArgs = {
  userId: Scalars['ID'];
};


export type MutationAddSpecializationToMedicArgs = {
  id: Scalars['String'];
};


export type MutationAnswerRequestAsProfessionalArgs = {
  message: CreateRequestMessageInput;
  medicId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationArchivePatientProfessionalArgs = {
  id: Scalars['String'];
};


export type MutationAssignMedicToEntryArgs = {
  medicId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationAssignPersonalMedicArgs = {
  medicId?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
};


export type MutationCopyAgendaListArgs = {
  agendaList: CopyAgendaListInput;
  id: Scalars['String'];
};


export type MutationCreateAgendaEntryArgs = {
  agendaEntry: CreateAgendaEntryInput;
  weekId: Scalars['String'];
};


export type MutationCreateAgendaListArgs = {
  numberOfWeeks?: Maybe<Scalars['Int']>;
  agendaList: CreateAgendaListInput;
  medicId?: Maybe<Scalars['String']>;
};


export type MutationCreateAgendaWeekArgs = {
  order: Scalars['Float'];
  agendaId: Scalars['String'];
};


export type MutationCreateAppointmentArgs = {
  appointment: CreateAppointmentInput;
  medicId: Scalars['String'];
};


export type MutationCreateAppointmentAsPatientArgs = {
  appointment: CreateAppointmentInput;
  patientId: Scalars['String'];
  medicId: Scalars['String'];
};


export type MutationCreateBreakArgs = {
  break: CreateBreakInput;
  medicId: Scalars['String'];
};


export type MutationCreateChatArgs = {
  userId: Scalars['ID'];
};


export type MutationCreateClinicArgs = {
  clinic: CreateClinicInput;
  medicId: Scalars['String'];
};


export type MutationCreateClinicRoomArgs = {
  room: CreateClinicRoomInput;
  clinicId: Scalars['String'];
};


export type MutationCreateManyAgendaEntryArgs = {
  agendaEntries: Array<CreateAgendaEntryInput>;
  weekId: Scalars['String'];
};


export type MutationCreateMedicArgs = {
  medic: CreateMedicInput;
};


export type MutationCreateMedicProvisionArgs = {
  provision: CreateProfessionalProvisionInput;
  id: Scalars['String'];
};


export type MutationCreateOperatorArgs = {
  operator: CreateOperatorInput;
};


export type MutationCreateOrUpdateInvoiceArgs = {
  input: InvoiceInput;
};


export type MutationCreateOrUpdateInvoiceOptionsArgs = {
  input: InvoiceOptionsInput;
};


export type MutationCreateOrUpdateInvoiceRowArgs = {
  input?: Maybe<InvoiceRowInput>;
};


export type MutationCreateOverrideEntryArgs = {
  date: Scalars['Date'];
  agendaEntry: CreateAgendaEntryInput;
  medicId: Scalars['String'];
};


export type MutationCreatePatientProfessionalArgs = {
  patientProfessional: CreatePatientProfessionalInput;
  patient: CreatePatientInput;
  medicId: Scalars['String'];
};


export type MutationCreatePatientProfessionalAsPatientArgs = {
  professionalId: Scalars['String'];
  patientId: Scalars['String'];
};


export type MutationCreateProfessionalProvisionArgs = {
  provision: CreateProfessionalProvisionInput;
  id: Scalars['String'];
};


export type MutationCreateReportAsPatientArgs = {
  patientProfessionalId?: Maybe<Scalars['String']>;
  report: CreateReportInput;
  patientId: Scalars['String'];
};


export type MutationCreateReportAsProfessionalArgs = {
  report: CreateReportInput;
  patientProfessionalId: Scalars['String'];
};


export type MutationCreateRequestAsPatientArgs = {
  patientId: Scalars['String'];
  request: CreateRequestInput;
};


export type MutationCreateRequestAsProfessionalArgs = {
  medicId: Scalars['String'];
  request: CreateRequestInput;
};


export type MutationCreateUserPatientArgs = {
  patientId?: Maybe<Scalars['String']>;
  patient?: Maybe<CreateUserPatientInput>;
  termsAndConditions: Scalars['Boolean'];
  type?: Maybe<PatientType>;
};


export type MutationDeleteAgendaEntryArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAgendaListArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAgendaWeekArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAppointmentArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAppointmentAsPatientArgs = {
  id: Scalars['String'];
  patientId: Scalars['String'];
};


export type MutationDeleteBreakArgs = {
  id: Scalars['String'];
};


export type MutationDeleteClinicArgs = {
  id: Scalars['String'];
};


export type MutationDeleteClinicRoomArgs = {
  id: Scalars['String'];
};


export type MutationDeleteConnectionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteInviteArgs = {
  id: Scalars['String'];
};


export type MutationDeleteInvoiceArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteInvoiceRowArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteProfessionalProvisionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteReportArgs = {
  id: Scalars['String'];
};


export type MutationDeleteReportAsProfessionalArgs = {
  id: Scalars['String'];
};


export type MutationDeleteRequestArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUserPatientArgs = {
  id: Scalars['String'];
};


export type MutationDissociateFromEntryArgs = {
  id: Scalars['String'];
};


export type MutationGrantUserAccessToPatientArgs = {
  type: PatientType;
  userId: Scalars['String'];
  patientId: Scalars['String'];
};


export type MutationInviteAssistantArgs = {
  user: InviteUserInput;
  medicId: Scalars['String'];
};


export type MutationInvitePatientArgs = {
  patientProfessionalId: Scalars['String'];
  email: Scalars['String'];
  medicId: Scalars['String'];
};


export type MutationInviteToOrganizationArgs = {
  user: InviteUserInput;
  role: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationMarkManyNotificationsAsReadArgs = {
  readAt?: Maybe<Scalars['DateTime']>;
  ids: Array<Scalars['String']>;
};


export type MutationMarkNotificationAsReadArgs = {
  readAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
};


export type MutationMarkRequestMessageAsReadAsProfessionalArgs = {
  readAt?: Maybe<Scalars['DateTime']>;
  medicId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationOverrideDayArgs = {
  date: Scalars['Date'];
  medicId: Scalars['String'];
};


export type MutationRemoveProfessionalFromOrganizationArgs = {
  medicId: Scalars['String'];
  orgId: Scalars['String'];
};


export type MutationRemoveSpecializationFromMedicArgs = {
  id: Scalars['String'];
};


export type MutationRequestConnectionArgs = {
  requestId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationResetDayArgs = {
  date: Scalars['Date'];
  medicId: Scalars['String'];
};


export type MutationResolveRequestArgs = {
  result: RequestResult;
  id: Scalars['String'];
};


export type MutationRevokeReportShareArgs = {
  patientProfessionalId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRevokeSharePatientWithProfessionalArgs = {
  patientProfessionalId: Scalars['String'];
  medicId: Scalars['String'];
  orgId: Scalars['String'];
};


export type MutationRevokeUserAccessToPatientArgs = {
  userId: Scalars['String'];
  patientId: Scalars['String'];
};


export type MutationSendMailArgs = {
  attachments?: Maybe<Array<Scalars['Upload']>>;
  body: Scalars['String'];
  title: Scalars['String'];
  to: Array<Scalars['String']>;
  medicId: Scalars['String'];
};


export type MutationSendMassiveEmailArgs = {
  attachments?: Maybe<Array<Scalars['Upload']>>;
  body: Scalars['String'];
  title: Scalars['String'];
  target: MassiveEmailTarget;
  medicId: Scalars['String'];
};


export type MutationSharePatientWithProfessionalArgs = {
  patientProfessionalId: Scalars['String'];
  medicId: Scalars['String'];
  orgId: Scalars['String'];
};


export type MutationShareReportArgs = {
  patientProfessionalId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationUpdateAgendaEntryArgs = {
  agendaEntry: UpdateAgendaEntryInput;
  id: Scalars['String'];
};


export type MutationUpdateAgendaListArgs = {
  agendaList: UpdateAgendaListInput;
  id: Scalars['String'];
};


export type MutationUpdateAgendaWeekArgs = {
  order: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationUpdateAppointmentArgs = {
  appointment: UpdateAppointmentInput;
  id: Scalars['String'];
};


export type MutationUpdateAssistantArgs = {
  assistant: UpdateAssistantInput;
};


export type MutationUpdateBreakArgs = {
  break: UpdateBreakInput;
  id: Scalars['String'];
};


export type MutationUpdateClinicArgs = {
  clinic: UpdateClinicInput;
  id: Scalars['String'];
};


export type MutationUpdateClinicRoomArgs = {
  room: UpdateClinicRoomInput;
  id: Scalars['String'];
};


export type MutationUpdateConnectionArgs = {
  status: NetworkStatus;
  id: Scalars['String'];
};


export type MutationUpdateMedicArgs = {
  medic: UpdateMedicInput;
};


export type MutationUpdateOperatorArgs = {
  operator: UpdateOperatorInput;
};


export type MutationUpdatePatientArgs = {
  patient: UpdatePatientInput;
  patientId: Scalars['String'];
};


export type MutationUpdatePatientProfessionalArgs = {
  patient: UpdatePatientProfessionalInput;
  id: Scalars['String'];
};


export type MutationUpdatePatientProfessionalStatusArgs = {
  patientId?: Maybe<Scalars['String']>;
  medicId?: Maybe<Scalars['String']>;
  status: UpdatablePatientProfessionalStatus;
  id: Scalars['String'];
};


export type MutationUpdateProfessionalProvisionArgs = {
  provision: UpdateProfessionalProvisionInput;
  id: Scalars['String'];
};


export type MutationUpdateReportAsProfessionalArgs = {
  report: UpdateReportInput;
  id: Scalars['String'];
};


export type MutationUploadPrivacyPolicyArgs = {
  link?: Maybe<Scalars['String']>;
  file?: Maybe<CreateAttachmentInput>;
  medicId: Scalars['String'];
};

export type UpdatePatientInput = {
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  birthDay?: Maybe<Scalars['DateTime']>;
  address?: Maybe<CreateEmbedAddressInput>;
};

export type CreateEmbedAddressInput = {
  address?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
  municipalityId: Scalars['String'];
  coordinates?: Maybe<LatLngInput>;
};

export type CreateUserPatientInput = {
  name: Scalars['String'];
  surname: Scalars['String'];
  taxId: Scalars['TaxId'];
  birthDay: Scalars['DateTime'];
  address?: Maybe<CreateEmbedAddressInput>;
};

export type CreateMedicInput = {
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  registerNumber?: Maybe<Scalars['String']>;
  type?: Maybe<MedicType>;
  freeLancer?: Maybe<Scalars['Boolean']>;
  chatEnabled?: Maybe<Scalars['Boolean']>;
  requestsEnabled?: Maybe<Scalars['Boolean']>;
  registerDistrictId?: Maybe<Scalars['String']>;
  address?: Maybe<CreateEmbedAddressInput>;
  contacts?: Maybe<CreateEmbedContactInput>;
  termsAndConditions: Scalars['Boolean'];
};

export type CreateEmbedContactInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
};

export type UpdateMedicInput = {
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  registerNumber?: Maybe<Scalars['String']>;
  type?: Maybe<MedicType>;
  freeLancer?: Maybe<Scalars['Boolean']>;
  chatEnabled?: Maybe<Scalars['Boolean']>;
  requestsEnabled?: Maybe<Scalars['Boolean']>;
  registerDistrictId?: Maybe<Scalars['String']>;
  address?: Maybe<CreateEmbedAddressInput>;
  contacts?: Maybe<CreateEmbedContactInput>;
};

export type UpdateAssistantInput = {
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};

export type CreateAttachmentInput = {
  file: Scalars['Upload'];
  destination: Scalars['String'];
};


export type CreatePatientProfessionalInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address: CreateCryptedEmbedAddressInput;
  isPersonalMedic?: Maybe<Scalars['Boolean']>;
};

export type CreateCryptedEmbedAddressInput = {
  address: Scalars['String'];
  municipalityId: Scalars['String'];
};

export type CreatePatientInput = {
  name: Scalars['String'];
  surname: Scalars['String'];
  taxId: Scalars['TaxId'];
  birthDay: Scalars['DateTime'];
};

export enum UpdatablePatientProfessionalStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED'
}

export type UpdatePatientProfessionalInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<CreateCryptedEmbedAddressInput>;
};

export enum MassiveEmailTarget {
  AllPatients = 'ALL_PATIENTS',
  PersonalPatients = 'PERSONAL_PATIENTS',
  FreelancerPatients = 'FREELANCER_PATIENTS',
  Networks = 'NETWORKS'
}

export type InviteUserInput = {
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['TaxId']>;
  phone?: Maybe<Scalars['String']>;
};

export type CreateOperatorInput = {
  profession?: Maybe<OperatorProfession>;
  termsAndConditions: Scalars['Boolean'];
};

export type UpdateOperatorInput = {
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  profession?: Maybe<OperatorProfession>;
  chatEnabled?: Maybe<Scalars['Boolean']>;
  requestsEnabled?: Maybe<Scalars['Boolean']>;
  address?: Maybe<CreateEmbedAddressInput>;
  contacts?: Maybe<CreateEmbedContactInput>;
};

export type CreateProfessionalProvisionInput = {
  provisionId: Scalars['String'];
  price: Scalars['Float'];
  duration: Scalars['Int'];
};

export type UpdateProfessionalProvisionInput = {
  price?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Int']>;
};

export type CreateAgendaListInput = {
  name: Scalars['String'];
  from: Scalars['Date'];
  openFor: Scalars['Int'];
  status?: Maybe<AgendaListStatus>;
};

export type UpdateAgendaListInput = {
  name?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Date']>;
  openFor?: Maybe<Scalars['Int']>;
  status?: Maybe<AgendaListStatus>;
  updatePreviousList?: Maybe<Scalars['Boolean']>;
};

export type CopyAgendaListInput = {
  from: Scalars['Date'];
  status?: Maybe<AgendaListStatus>;
  openFor?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type CreateAgendaEntryInput = {
  weekday: Scalars['Weekday'];
  disabled: Scalars['Boolean'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  informers: Scalars['Int'];
  visibility: AgendaEntryVisibility;
  clinicId: Scalars['String'];
  assistantIds?: Maybe<Array<Scalars['String']>>;
};

export type UpdateAgendaEntryInput = {
  weekday?: Maybe<Scalars['Weekday']>;
  disabled?: Maybe<Scalars['Boolean']>;
  from?: Maybe<Scalars['Time']>;
  to?: Maybe<Scalars['Time']>;
  informers?: Maybe<Scalars['Int']>;
  visibility?: Maybe<AgendaEntryVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  assistantIds?: Maybe<Array<Scalars['String']>>;
};

export type CreateClinicInput = {
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  rooms?: Maybe<Array<CreateClinicRoomInput>>;
  address: CreateEmbedAddressInput;
};

export type CreateClinicRoomInput = {
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  provisionIds?: Maybe<Array<Scalars['String']>>;
  meta: Scalars['JSON'];
};

export type UpdateClinicInput = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<CreateEmbedAddressInput>;
};

export type UpdateClinicRoomInput = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  provisionIds?: Maybe<Array<Scalars['String']>>;
  meta?: Maybe<Scalars['JSON']>;
};

export type CreateAppointmentInput = {
  day: Scalars['Date'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  notes?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  provisionId?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patientName?: Maybe<Scalars['String']>;
  patientTaxId?: Maybe<Scalars['String']>;
};

export type UpdateAppointmentInput = {
  notes?: Maybe<Scalars['String']>;
  patientName?: Maybe<Scalars['String']>;
  patientTaxId?: Maybe<Scalars['String']>;
};

export type CreateBreakInput = {
  day: Scalars['Date'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  notes?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
};

export type UpdateBreakInput = {
  notes?: Maybe<Scalars['String']>;
};

export type CreateRequestInput = {
  recipientId: Scalars['String'];
  recipientType: RequestRecipientType;
  title?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  type: RequestType;
  private?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<Array<CreateAttachmentInput>>;
};

export enum RequestRecipientType {
  Patient = 'Patient',
  Professional = 'Professional'
}

export type CreateRequestMessageInput = {
  message: Scalars['String'];
  attachments?: Maybe<Array<CreateAttachmentInput>>;
};

export type CreateReportInput = {
  title: Scalars['String'];
  description: Scalars['String'];
  attachments?: Maybe<Array<CreateAttachmentInput>>;
  medicName?: Maybe<Scalars['String']>;
  reportDate?: Maybe<Scalars['DateTime']>;
};

export type UpdateReportInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<CreateAttachmentInput>>;
  medicName?: Maybe<Scalars['String']>;
  reportDate?: Maybe<Scalars['DateTime']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onNewNotification: Notification;
};

/** Pagination information about the corresponding list of items. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Total count of available items in the page. */
  count: Scalars['Int'];
  /** Current pagination page. */
  currentPage: Scalars['Int'];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** If collection has more pages. */
  hasMorePages: Scalars['Boolean'];
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Last page number of the collection. */
  lastPage: Scalars['Int'];
  /** Number of items per page in the collection. */
  perPage: Scalars['Int'];
  /** Total items available in the collection. */
  total: Scalars['Int'];
};

export type InvoiceInput = {
  /** Importo del bollo, se dovuto */
  bollo: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['Date']>;
  footer?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invoiceRows?: Maybe<Array<InvoiceRowRelatedInput>>;
  invoiceType: InvoiceType;
  /** Rifiuto del consenso all'invio al sistema TS */
  noTs?: Maybe<Scalars['Boolean']>;
  number: Scalars['Int'];
  patientId?: Maybe<Scalars['ID']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  payedWith?: Maybe<Scalars['Int']>;
  paymentInfo?: Maybe<Scalars['String']>;
  professionalId: Scalars['Int'];
  recipientAddress: Scalars['String'];
  recipientAnag: Scalars['String'];
  recipientLocation?: Maybe<Scalars['String']>;
  recipientTaxId?: Maybe<Scalars['String']>;
  /** Serie della fattura, opzionale */
  serie?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  /** Documento da inviare al sistema TS */
  ts?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vatCode: Scalars['Int'];
  vatId?: Maybe<Scalars['String']>;
  vatPercent?: Maybe<Scalars['Float']>;
  /** Ritenuta d'acconto */
  withholdingTax?: Maybe<Scalars['Boolean']>;
  year: Scalars['Int'];
};

export type InvoiceOptionsInput = {
  defaultInvoiceType: InvoiceType;
  defaultPaymentInfo?: Maybe<Scalars['String']>;
  defaultSerie?: Maybe<Scalars['String']>;
  defaultVatCode?: Maybe<Scalars['Int']>;
  professionalId: Scalars['Int'];
  taxId?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
};

export type InvoiceRowInput = {
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  invoiceId: Scalars['Int'];
  isNote?: Maybe<Scalars['Boolean']>;
  sequence?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vatCode?: Maybe<Scalars['Int']>;
};

export type InvoiceRowRelatedInput = {
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  invoiceId?: Maybe<Scalars['Int']>;
  isNote?: Maybe<Scalars['Boolean']>;
  sequence?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vatCode?: Maybe<Scalars['Int']>;
};

/** Order by clause for the `sortBy` argument on the query `getInvoicesByPatient`. */
export type GetInvoicesByPatientSortByOrderByClause = {
  /** The column that is used for ordering. */
  field: SortInvoices;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for the `sortBy` argument on the query `getInvoicesByRecipientTaxId`. */
export type GetInvoicesByRecipientTaxIdSortByOrderByClause = {
  /** The column that is used for ordering. */
  field: SortInvoices;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for the `sortBy` argument on the query `getInvoices`. */
export type GetInvoicesSortByOrderByClause = {
  /** The column that is used for ordering. */
  field: SortInvoices;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type Invoice = {
  __typename?: 'Invoice';
  /** Importo del bollo, se dovuto */
  bollo: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['Date']>;
  footer?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  invoiceRows: Array<InvoiceRow>;
  invoiceType: InvoiceType;
  /** Rifiuto del consenso all'invio al sistema TS */
  noTs?: Maybe<Scalars['Boolean']>;
  number: Scalars['Int'];
  patientId?: Maybe<Scalars['ID']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  payedWith?: Maybe<Scalars['Int']>;
  paymentInfo?: Maybe<Scalars['String']>;
  professionalId: Scalars['Int'];
  recipientAddress: Scalars['String'];
  recipientAnag: Scalars['String'];
  recipientLocation?: Maybe<Scalars['String']>;
  recipientTaxId?: Maybe<Scalars['String']>;
  /** Serie della fattura, opzionale */
  serie?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  /** Documento da inviare al sistema TS */
  ts?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vatCode: Scalars['Int'];
  vatId?: Maybe<Scalars['String']>;
  vatPercent?: Maybe<Scalars['Float']>;
  /** Ritenuta d'acconto */
  withholdingTax?: Maybe<Scalars['Boolean']>;
  year: Scalars['Int'];
};

export type InvoiceOptions = {
  __typename?: 'InvoiceOptions';
  defaultInvoiceType: InvoiceType;
  defaultPaymentInfo?: Maybe<Scalars['String']>;
  defaultSerie?: Maybe<Scalars['String']>;
  defaultVatCode?: Maybe<Scalars['Int']>;
  professionalId: Scalars['Int'];
  taxId?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
};

/** A paginated list of Invoice items. */
export type InvoicePaginator = {
  __typename?: 'InvoicePaginator';
  /** A list of Invoice items. */
  data: Array<Invoice>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type InvoicePaymentType = {
  __typename?: 'InvoicePaymentType';
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type InvoiceRow = {
  __typename?: 'InvoiceRow';
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['Int'];
  invoiceId: Scalars['Int'];
  isNote?: Maybe<Scalars['Boolean']>;
  sequence?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vatCode?: Maybe<Scalars['Int']>;
};

export type InvoiceVatCode = {
  __typename?: 'InvoiceVatCode';
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  percentage: Scalars['Float'];
};

export enum InvoiceType {
  Fattura = 'FATTURA',
  Notadicredito = 'NOTADICREDITO',
  Notula = 'NOTULA',
  Ricevuta = 'RICEVUTA'
}

/** Order by clause for invoices list. */
export enum SortInvoices {
  Number = 'NUMBER',
  Year = 'YEAR'
}

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type CityDataFragment = (
  { __typename?: 'City' }
  & Pick<City, 'id' | 'registryCode' | 'name'>
  & { region: (
    { __typename?: 'Region' }
    & Pick<Region, 'name' | 'code'>
  ), district: (
    { __typename?: 'District' }
    & Pick<District, 'name' | 'code'>
  ) }
);

export type EmbedAddressDataFragment = (
  { __typename?: 'EmbedAddress' }
  & Pick<EmbedAddress, 'municipalityName' | 'districtName' | 'address' | 'location' | 'cap' | 'municipalityId'>
  & { municipality?: Maybe<(
    { __typename?: 'City' }
    & CityDataFragment
  )> }
);

export type CrypedEmbedDataFragment = (
  { __typename?: 'CryptedEmbedAddress' }
  & Pick<CryptedEmbedAddress, 'municipalityName' | 'municipalityId' | 'districtName' | 'nationName' | 'address'>
);

export type AgendaEntryBasicDataFragment = (
  { __typename?: 'AgendaEntry' }
  & Pick<AgendaEntry, 'id' | 'disabled' | 'weekday' | 'override' | 'informers' | 'assistantIds' | 'professionalId' | 'visibility' | 'agendaWeekId' | 'from' | 'to' | 'clinicId' | 'ownerId'>
);

export type AgendaEntryDataFragment = (
  { __typename?: 'AgendaEntry' }
  & { clinic: (
    { __typename?: 'ClinicRoom' }
    & ClinicRoomDataFragment
  ) }
  & AgendaEntryBasicDataFragment
);

export type AgendaEntryInDayDataFragment = (
  { __typename?: 'AgendaEntryInDay' }
  & Pick<AgendaEntryInDay, 'day' | 'id' | 'disabled' | 'weekday' | 'override' | 'professionalId' | 'informers' | 'assistantIds' | 'visibility' | 'agendaWeekId' | 'from' | 'to' | 'clinicId' | 'ownerId'>
  & { clinic: (
    { __typename?: 'ClinicRoom' }
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) }
    & ClinicRoomDataFragment
  ) }
);

export type AgendaWeekBasicDataFragment = (
  { __typename?: 'AgendaWeek' }
  & Pick<AgendaWeek, 'id' | 'order'>
);

export type AgendaWeekDataFragment = (
  { __typename?: 'AgendaWeek' }
  & { entries: Array<(
    { __typename?: 'AgendaEntry' }
    & AgendaEntryDataFragment
  )> }
  & AgendaWeekBasicDataFragment
);

export type AgendaListBasicDataFragment = (
  { __typename?: 'AgendaList' }
  & Pick<AgendaList, 'id' | 'from' | 'to' | 'openFor' | 'status' | 'name'>
);

export type AgendaListDataFragment = (
  { __typename?: 'AgendaList' }
  & { weeks: Array<(
    { __typename?: 'AgendaWeek' }
    & AgendaWeekDataFragment
  )> }
  & AgendaListBasicDataFragment
);

export type AppointmentBasicDataFragment = (
  { __typename?: 'Appointment' }
  & Pick<Appointment, 'id' | 'patientName' | 'patientTaxId' | 'patientProfessionalId' | 'from' | 'to' | 'day' | 'clinicId' | 'createdAt' | 'notes'>
  & { provision?: Maybe<(
    { __typename?: 'ProfessionalProvision' }
    & Pick<ProfessionalProvision, 'id'>
    & { provision: (
      { __typename?: 'Provision' }
      & Pick<Provision, 'id' | 'name'>
    ) }
  )> }
);

export type BreakBasicDataFragment = (
  { __typename?: 'Break' }
  & Pick<Break, 'id' | 'day' | 'from' | 'to' | 'createdAt' | 'clinicId' | 'notes'>
);

export type AppointmentDataFragment = (
  { __typename?: 'Appointment' }
  & Pick<Appointment, 'notes'>
  & { patientProfessional?: Maybe<(
    { __typename?: 'PatientProfessional' }
    & PatientProfessionalDataFragment
  )>, provision?: Maybe<(
    { __typename?: 'ProfessionalProvision' }
    & ProfessionalProvisionDataFragment
  )>, clinic: (
    { __typename?: 'ClinicRoom' }
    & Pick<ClinicRoom, 'id' | 'name' | 'phone'>
  ), creator?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
  & AppointmentBasicDataFragment
);

export type BreakDataFragment = (
  { __typename?: 'Break' }
  & Pick<Break, 'notes'>
  & { clinic: (
    { __typename?: 'ClinicRoom' }
    & Pick<ClinicRoom, 'id' | 'name' | 'phone'>
  ), creator?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
  & BreakBasicDataFragment
);

type AgendaEventBasicData_Appointment_Fragment = (
  { __typename?: 'Appointment' }
  & AppointmentBasicDataFragment
);

type AgendaEventBasicData_Break_Fragment = (
  { __typename?: 'Break' }
  & BreakBasicDataFragment
);

export type AgendaEventBasicDataFragment = AgendaEventBasicData_Appointment_Fragment | AgendaEventBasicData_Break_Fragment;

type AgendaEventData_Appointment_Fragment = (
  { __typename?: 'Appointment' }
  & AppointmentDataFragment
);

type AgendaEventData_Break_Fragment = (
  { __typename?: 'Break' }
  & BreakDataFragment
);

export type AgendaEventDataFragment = AgendaEventData_Appointment_Fragment | AgendaEventData_Break_Fragment;

export type AssistantDataFragment = (
  { __typename?: 'Assistant' }
  & { specializations: Array<(
    { __typename?: 'Specialization' }
    & SpecializationDataFragment
  )>, provisions: Array<(
    { __typename?: 'ProfessionalProvision' }
    & ProfessionalProvisionDataFragment
  )>, clinics: Array<(
    { __typename?: 'Clinic' }
    & ClinicDataFragment
  )>, networks: Array<(
    { __typename?: 'Network' }
    & NetworkDataFragment
  )> }
  & AssistantBasicDataFragment
);

export type AssistantBasicDataFragment = (
  { __typename?: 'Assistant' }
  & Pick<Assistant, 'userId' | 'name' | 'surname' | 'role' | 'id' | 'createdAt' | 'updatedAt' | 'assistantTermsAndConditions'>
);

export type AttachmentDataFragment = (
  { __typename?: 'Attachment' }
  & Pick<Attachment, 'id' | 'url' | 'mimeType' | 'name'>
);

export type ClinicDataFragment = (
  { __typename?: 'Clinic' }
  & Pick<Clinic, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'phone'>
  & { rooms: Array<(
    { __typename?: 'ClinicRoom' }
    & ClinicRoomDataFragment
  )>, address: (
    { __typename?: 'EmbedAddress' }
    & EmbedAddressDataFragment
  ) }
);

export type ClinicRoomDataFragment = (
  { __typename?: 'ClinicRoom' }
  & Pick<ClinicRoom, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'phone' | 'meta' | 'clinicId'>
  & { provisions: Array<(
    { __typename?: 'ProfessionalProvision' }
    & ProfessionalProvisionDataFragment
  )> }
);

export type PaginationInfoDataFragment = (
  { __typename?: 'PaginationInfo' }
  & Pick<PaginationInfo, 'hasMore' | 'total'>
);

export type InviteDataFragment = (
  { __typename?: 'Invite' }
  & Pick<Invite, 'id' | 'inviteRole' | 'email' | 'type' | 'invitedUserId'>
  & { inviter: (
    { __typename?: 'Medic' }
    & ProfessionalBasicData_Medic_Fragment
  ) | (
    { __typename?: 'Assistant' }
    & ProfessionalBasicData_Assistant_Fragment
  ) | (
    { __typename?: 'Operator' }
    & ProfessionalBasicData_Operator_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & ProfessionalBasicData_OrganizationSa_Fragment
  ) }
);

export type InvoiceBasicDataFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'bollo' | 'createdAt' | 'date' | 'footer' | 'header' | 'id' | 'invoiceType' | 'noTs' | 'number' | 'patientId' | 'payedAt' | 'payedWith' | 'paymentInfo' | 'professionalId' | 'recipientAddress' | 'recipientAnag' | 'recipientLocation' | 'recipientTaxId' | 'serie' | 'taxId' | 'ts' | 'updatedAt' | 'vatId' | 'vatPercent' | 'withholdingTax' | 'year' | 'vatCode'>
);

export type InvoiceDataFragment = (
  { __typename?: 'Invoice' }
  & { invoiceRows: Array<(
    { __typename?: 'InvoiceRow' }
    & InvoiceRowDataFragment
  )> }
  & InvoiceBasicDataFragment
);

export type InvoiceRowDataFragment = (
  { __typename?: 'InvoiceRow' }
  & Pick<InvoiceRow, 'amount' | 'createdAt' | 'description' | 'id' | 'invoiceId' | 'isNote' | 'sequence' | 'updatedAt'>
);

export type InvoiceOptionsDataFragment = (
  { __typename?: 'InvoiceOptions' }
  & Pick<InvoiceOptions, 'defaultInvoiceType' | 'defaultPaymentInfo' | 'defaultSerie' | 'defaultVatCode' | 'professionalId' | 'taxId' | 'vatId'>
);

export type InvoiceVatCodeDataFragment = (
  { __typename?: 'InvoiceVatCode' }
  & Pick<InvoiceVatCode, 'description' | 'id' | 'name' | 'percentage'>
);

export type InvoicePaymentTypeDataFragment = (
  { __typename?: 'InvoicePaymentType' }
  & Pick<InvoicePaymentType, 'description' | 'id' | 'name'>
);

export type LicenseTypeDataFragment = (
  { __typename?: 'LicenseType' }
  & Pick<LicenseType, 'code' | 'name' | 'description'>
  & { stripeProduct: (
    { __typename?: 'StripeProduct' }
    & StripeProductDataFragment
  ) }
);

export type StripeProductDataFragment = (
  { __typename?: 'StripeProduct' }
  & Pick<StripeProduct, 'code' | 'id' | 'monthlyId' | 'yearlyId'>
  & { monthly: (
    { __typename?: 'StripePrice' }
    & StripePriceDataFragment
  ), yearly: (
    { __typename?: 'StripePrice' }
    & StripePriceDataFragment
  ) }
);

export type StripePriceDataFragment = (
  { __typename?: 'StripePrice' }
  & Pick<StripePrice, 'id' | 'productId' | 'amount'>
);

export type LicenseDataFragment = (
  { __typename?: 'License' }
  & Pick<License, 'id' | 'expiresAt' | 'transactionData' | 'transactionType'>
  & { licenseType: (
    { __typename?: 'LicenseType' }
    & Pick<LicenseType, 'code'>
  ) }
);

export type MedicDataFragment = (
  { __typename?: 'Medic' }
  & { specializations: Array<(
    { __typename?: 'Specialization' }
    & SpecializationDataFragment
  )>, provisions: Array<(
    { __typename?: 'ProfessionalProvision' }
    & ProfessionalProvisionDataFragment
  )>, clinics: Array<(
    { __typename?: 'Clinic' }
    & ClinicDataFragment
  )>, networks: Array<(
    { __typename?: 'Network' }
    & NetworkDataFragment
  )> }
  & MedicBasicDataFragment
);

export type MedicBasicDataFragment = (
  { __typename?: 'Medic' }
  & Pick<Medic, 'id' | 'userId' | 'role' | 'name' | 'surname' | 'registerNumber' | 'type' | 'freeLancer' | 'chatEnabled' | 'requestsEnabled' | 'header' | 'footer' | 'hasActiveLicense' | 'termsAndConditions'>
  & { privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, registerDistrict?: Maybe<(
    { __typename?: 'City' }
    & CityDataFragment
  )>, activeLicense?: Maybe<(
    { __typename?: 'License' }
    & LicenseDataFragment
  )>, address?: Maybe<(
    { __typename?: 'EmbedAddress' }
    & EmbedAddressDataFragment
  )>, contacts?: Maybe<(
    { __typename?: 'EmbedContact' }
    & ContactDataFragment
  )> }
);

export type NetworkDataFragment = (
  { __typename?: 'Network' }
  & Pick<Network, 'id' | 'createdAt' | 'updatedAt' | 'canImpersonate' | 'status'>
  & { inverse: (
    { __typename?: 'Medic' }
    & ProfessionalBasicData_Medic_Fragment
  ) | (
    { __typename?: 'Assistant' }
    & ProfessionalBasicData_Assistant_Fragment
  ) | (
    { __typename?: 'Operator' }
    & ProfessionalBasicData_Operator_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & ProfessionalBasicData_OrganizationSa_Fragment
  ) }
);

export type NotificationDataFragment = (
  { __typename?: 'Notification' }
  & Pick<Notification, 'id' | 'userId' | 'readAt' | 'createdAt' | 'data' | 'name' | 'destinationProfile'>
);

export type OperatorDataFragment = (
  { __typename?: 'Operator' }
  & { specializations: Array<(
    { __typename?: 'Specialization' }
    & SpecializationDataFragment
  )>, provisions: Array<(
    { __typename?: 'ProfessionalProvision' }
    & ProfessionalProvisionDataFragment
  )>, clinics: Array<(
    { __typename?: 'Clinic' }
    & ClinicDataFragment
  )>, networks: Array<(
    { __typename?: 'Network' }
    & NetworkDataFragment
  )> }
  & OperatorBasicDataFragment
);

export type OperatorBasicDataFragment = (
  { __typename?: 'Operator' }
  & Pick<Operator, 'id' | 'role' | 'profession' | 'userId' | 'name' | 'surname' | 'chatEnabled' | 'requestsEnabled' | 'header' | 'footer' | 'hasActiveLicense' | 'termsAndConditions'>
  & { privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )>, activeLicense?: Maybe<(
    { __typename?: 'License' }
    & LicenseDataFragment
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, address?: Maybe<(
    { __typename?: 'EmbedAddress' }
    & EmbedAddressDataFragment
  )>, contacts?: Maybe<(
    { __typename?: 'EmbedContact' }
    & ContactDataFragment
  )> }
);

export type OrganizationDataFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'id' | 'name'>
  & { serviceAccount: (
    { __typename?: 'OrganizationSa' }
    & OrganizationSaBasicDataFragment
  ) }
);

export type OrganizationProfessionalsDataFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'id'>
  & { professionals: Array<(
    { __typename?: 'OrganizationProfessional' }
    & OrganizationProfessionalPersonalDataFragment
  )> }
);

export type OrganizationSaDataFragment = (
  { __typename?: 'OrganizationSa' }
  & { clinics: Array<(
    { __typename?: 'Clinic' }
    & ClinicDataFragment
  )>, networks: Array<(
    { __typename?: 'Network' }
    & NetworkDataFragment
  )>, managedOrganization: (
    { __typename?: 'Organization' }
    & OrganizationProfessionalsDataFragment
  ), provisions: Array<(
    { __typename?: 'ProfessionalProvision' }
    & ProfessionalProvisionDataFragment
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
  & OrganizationSaBasicDataFragment
);

export type OrganizationSaBasicDataFragment = (
  { __typename?: 'OrganizationSa' }
  & Pick<OrganizationSa, 'id' | 'role' | 'userId' | 'name' | 'surname' | 'header' | 'footer'>
);

export type OrganizationProfessionalDataFragment = (
  { __typename?: 'OrganizationProfessional' }
  & Pick<OrganizationProfessional, 'id' | 'role'>
  & { organization: (
    { __typename?: 'Organization' }
    & OrganizationDataFragment
  ) }
);

export type OrganizationProfessionalPersonalDataFragment = (
  { __typename?: 'OrganizationProfessional' }
  & Pick<OrganizationProfessional, 'id' | 'role'>
  & { professional: (
    { __typename?: 'Medic' }
    & ProfessionalBasicData_Medic_Fragment
  ) | (
    { __typename?: 'Assistant' }
    & ProfessionalBasicData_Assistant_Fragment
  ) | (
    { __typename?: 'Operator' }
    & ProfessionalBasicData_Operator_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & ProfessionalBasicData_OrganizationSa_Fragment
  ) }
);

export type PatientBasicDataFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'surname' | 'taxId' | 'birthDay' | 'personalMedicId'>
  & { address?: Maybe<(
    { __typename?: 'EmbedAddress' }
    & EmbedAddressDataFragment
  )> }
);

export type PatientDataFragment = (
  { __typename?: 'Patient' }
  & { users: Array<(
    { __typename?: 'PatientUser' }
    & PatientUserDataFragment
  )> }
  & PatientBasicDataFragment
);

export type PatientUserDataFragment = (
  { __typename?: 'PatientUser' }
  & Pick<PatientUser, 'id' | 'createdAt' | 'updatedAt' | 'userId' | 'patientId' | 'type'>
  & { user: (
    { __typename?: 'User' }
    & UserDataFragment
  ) }
);

export type PatientProfessionalDataFragment = (
  { __typename?: 'PatientProfessional' }
  & Pick<PatientProfessional, 'id' | 'createdAt' | 'updatedAt' | 'status' | 'professionalId' | 'patientId' | 'organizationProfessionalId' | 'fullName' | 'email' | 'phone'>
  & { address?: Maybe<(
    { __typename?: 'CryptedEmbedAddress' }
    & CrypedEmbedDataFragment
  )>, patient: (
    { __typename?: 'Patient' }
    & PatientDataFragment
  ) }
);

export type PatientPaginatedResponseDataFragment = (
  { __typename?: 'PatientPaginatedResponse' }
  & { items: Array<(
    { __typename?: 'Patient' }
    & PatientDataFragment
  )>, paginationInfo: (
    { __typename?: 'PaginationInfo' }
    & PaginationInfoDataFragment
  ) }
);

export type PatientProfessionalPaginatedResponseDataFragment = (
  { __typename?: 'PatientProfessionalPaginatedResponse' }
  & { items: Array<(
    { __typename?: 'PatientProfessional' }
    & PatientProfessionalDataFragment
  )>, paginationInfo: (
    { __typename?: 'PaginationInfo' }
    & PaginationInfoDataFragment
  ) }
);

export type ProfessionalProvisionDataFragment = (
  { __typename?: 'ProfessionalProvision' }
  & Pick<ProfessionalProvision, 'id' | 'duration' | 'price' | 'mandatory' | 'createdAt' | 'updatedAt'>
  & { provision: (
    { __typename?: 'Provision' }
    & ProvisionDataFragment
  ) }
);

type ProfessionalData_Medic_Fragment = (
  { __typename?: 'Medic' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
  & MedicDataFragment
);

type ProfessionalData_Assistant_Fragment = (
  { __typename?: 'Assistant' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
  & AssistantDataFragment
);

type ProfessionalData_Operator_Fragment = (
  { __typename?: 'Operator' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
  & OperatorDataFragment
);

type ProfessionalData_OrganizationSa_Fragment = (
  { __typename?: 'OrganizationSa' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
  & OrganizationSaDataFragment
);

export type ProfessionalDataFragment = ProfessionalData_Medic_Fragment | ProfessionalData_Assistant_Fragment | ProfessionalData_Operator_Fragment | ProfessionalData_OrganizationSa_Fragment;

type ProfessionalBasicData_Medic_Fragment = (
  { __typename?: 'Medic' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
  & MedicBasicDataFragment
);

type ProfessionalBasicData_Assistant_Fragment = (
  { __typename?: 'Assistant' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
  & AssistantBasicDataFragment
);

type ProfessionalBasicData_Operator_Fragment = (
  { __typename?: 'Operator' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
  & OperatorBasicDataFragment
);

type ProfessionalBasicData_OrganizationSa_Fragment = (
  { __typename?: 'OrganizationSa' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
  & OrganizationSaBasicDataFragment
);

export type ProfessionalBasicDataFragment = ProfessionalBasicData_Medic_Fragment | ProfessionalBasicData_Assistant_Fragment | ProfessionalBasicData_Operator_Fragment | ProfessionalBasicData_OrganizationSa_Fragment;

type SearchProfessionalData_Medic_Fragment = (
  { __typename?: 'Medic' }
  & { specializations: Array<(
    { __typename?: 'Specialization' }
    & SpecializationDataFragment
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
  & MedicBasicDataFragment
);

type SearchProfessionalData_Assistant_Fragment = (
  { __typename?: 'Assistant' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
  & AssistantBasicDataFragment
);

type SearchProfessionalData_Operator_Fragment = (
  { __typename?: 'Operator' }
  & { specializations: Array<(
    { __typename?: 'Specialization' }
    & SpecializationDataFragment
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
  & OperatorBasicDataFragment
);

type SearchProfessionalData_OrganizationSa_Fragment = (
  { __typename?: 'OrganizationSa' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
  & OrganizationSaBasicDataFragment
);

export type SearchProfessionalDataFragment = SearchProfessionalData_Medic_Fragment | SearchProfessionalData_Assistant_Fragment | SearchProfessionalData_Operator_Fragment | SearchProfessionalData_OrganizationSa_Fragment;

export type ContactDataFragment = (
  { __typename?: 'EmbedContact' }
  & Pick<EmbedContact, 'email' | 'phone' | 'fax'>
);

export type ProvisionDataFragment = (
  { __typename?: 'Provision' }
  & Pick<Provision, 'id' | 'name' | 'defaultPrice' | 'defaultDuration' | 'code'>
);

export type DefaultProvisionDataFragment = (
  { __typename?: 'Provision' }
  & Pick<Provision, 'mandatory'>
  & ProvisionDataFragment
);

export type ReportBasicDataFragment = (
  { __typename?: 'Report' }
  & Pick<Report, 'id' | 'title' | 'description' | 'createdBy' | 'createdAt' | 'medicName' | 'professionalId' | 'reportDate'>
  & { attachments?: Maybe<Array<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )>> }
);

export type ReportSharedDataFragment = (
  { __typename?: 'Report' }
  & { sharedWith: Array<(
    { __typename?: 'PatientProfessional' }
    & Pick<PatientProfessional, 'id' | 'professionalId'>
  )>, patient: (
    { __typename?: 'Patient' }
    & PatientDataFragment
  ), professional?: Maybe<(
    { __typename?: 'Medic' }
    & ProfessionalBasicData_Medic_Fragment
  ) | (
    { __typename?: 'Assistant' }
    & ProfessionalBasicData_Assistant_Fragment
  ) | (
    { __typename?: 'Operator' }
    & ProfessionalBasicData_Operator_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & ProfessionalBasicData_OrganizationSa_Fragment
  )> }
  & ReportBasicDataFragment
);

type RequestAuthorData_Medic_Fragment = (
  { __typename?: 'Medic' }
  & Pick<Medic, 'id' | 'role' | 'name' | 'surname'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
);

type RequestAuthorData_Operator_Fragment = (
  { __typename?: 'Operator' }
  & Pick<Operator, 'id' | 'name' | 'surname' | 'role'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
);

type RequestAuthorData_Patient_Fragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'name' | 'surname' | 'taxId'>
);

type RequestAuthorData_OrganizationSa_Fragment = (
  { __typename?: 'OrganizationSa' }
  & Pick<OrganizationSa, 'id' | 'name' | 'surname' | 'role'>
);

export type RequestAuthorDataFragment = RequestAuthorData_Medic_Fragment | RequestAuthorData_Operator_Fragment | RequestAuthorData_Patient_Fragment | RequestAuthorData_OrganizationSa_Fragment;

export type RequestMessageDataFragment = (
  { __typename?: 'RequestMessage' }
  & Pick<RequestMessage, 'id' | 'message' | 'readAt' | 'createdAt'>
  & { creator?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, author?: Maybe<(
    { __typename?: 'Medic' }
    & RequestAuthorData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & RequestAuthorData_Operator_Fragment
  ) | (
    { __typename?: 'Patient' }
    & RequestAuthorData_Patient_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & RequestAuthorData_OrganizationSa_Fragment
  )>, attachments?: Maybe<Array<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )>> }
);

export type RequestDataFragment = (
  { __typename?: 'Request' }
  & Pick<Request, 'id' | 'title' | 'type' | 'result' | 'private' | 'createdAt'>
  & { messages: Array<(
    { __typename?: 'RequestMessage' }
    & RequestMessageDataFragment
  )>, recipient: (
    { __typename?: 'Medic' }
    & RequestAuthorData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & RequestAuthorData_Operator_Fragment
  ) | (
    { __typename?: 'Patient' }
    & RequestAuthorData_Patient_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & RequestAuthorData_OrganizationSa_Fragment
  ), sender: (
    { __typename?: 'Medic' }
    & RequestAuthorData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & RequestAuthorData_Operator_Fragment
  ) | (
    { __typename?: 'Patient' }
    & RequestAuthorData_Patient_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & RequestAuthorData_OrganizationSa_Fragment
  ) }
);

export type SpecializationDataFragment = (
  { __typename?: 'Specialization' }
  & Pick<Specialization, 'id' | 'name' | 'createdAt'>
);

export type UserTraitsDataFragment = (
  { __typename?: 'UserTraits' }
  & Pick<UserTraits, 'email' | 'avatar' | 'taxId' | 'name' | 'surname' | 'birthDay'>
);

export type UserEmailAddressDataFragment = (
  { __typename?: 'UserEmailAddress' }
  & Pick<UserEmailAddress, 'id' | 'value' | 'verified' | 'via' | 'status'>
);

export type UserDataFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'role'>
  & { traits: (
    { __typename?: 'UserTraits' }
    & UserTraitsDataFragment
  ) }
);

export type CreateAgendaListMutationVariables = Exact<{
  medicId: Scalars['String'];
  agendaList: CreateAgendaListInput;
  numberOfWeeks?: Maybe<Scalars['Int']>;
}>;


export type CreateAgendaListMutation = (
  { __typename?: 'Mutation' }
  & { createAgendaList: (
    { __typename?: 'AgendaList' }
    & AgendaListDataFragment
  ) }
);

export type UpdateAgendaListMutationVariables = Exact<{
  id: Scalars['String'];
  agendaList: UpdateAgendaListInput;
}>;


export type UpdateAgendaListMutation = (
  { __typename?: 'Mutation' }
  & { updateAgendaList: (
    { __typename?: 'AgendaList' }
    & AgendaListDataFragment
  ) }
);

export type DeleteAgendaListMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteAgendaListMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAgendaList'>
);

export type AddWeekMutationVariables = Exact<{
  agendaListId: Scalars['String'];
  order: Scalars['Float'];
}>;


export type AddWeekMutation = (
  { __typename?: 'Mutation' }
  & { createAgendaWeek: (
    { __typename?: 'AgendaWeek' }
    & AgendaWeekDataFragment
  ) }
);

export type UpdateWeekMutationVariables = Exact<{
  id: Scalars['String'];
  order: Scalars['Float'];
}>;


export type UpdateWeekMutation = (
  { __typename?: 'Mutation' }
  & { updateAgendaWeek: (
    { __typename?: 'AgendaWeek' }
    & AgendaWeekDataFragment
  ) }
);

export type DeleteWeekMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteWeekMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAgendaWeek'>
);

export type CreateAgendaEntryMutationVariables = Exact<{
  weekId: Scalars['String'];
  agendaEntry: CreateAgendaEntryInput;
}>;


export type CreateAgendaEntryMutation = (
  { __typename?: 'Mutation' }
  & { createAgendaEntry: (
    { __typename?: 'AgendaEntry' }
    & AgendaEntryDataFragment
  ) }
);

export type UpdateAgendaEntryMutationVariables = Exact<{
  id: Scalars['String'];
  agendaEntry: UpdateAgendaEntryInput;
}>;


export type UpdateAgendaEntryMutation = (
  { __typename?: 'Mutation' }
  & { updateAgendaEntry: (
    { __typename?: 'AgendaEntry' }
    & AgendaEntryDataFragment
  ) }
);

export type DeleteAgendaEntryMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteAgendaEntryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAgendaEntry'>
);

export type CreateManyAgendaEntriesMutationVariables = Exact<{
  weekId: Scalars['String'];
  agendaEntries: Array<CreateAgendaEntryInput> | CreateAgendaEntryInput;
}>;


export type CreateManyAgendaEntriesMutation = (
  { __typename?: 'Mutation' }
  & { createManyAgendaEntry: Array<(
    { __typename?: 'AgendaEntry' }
    & AgendaEntryDataFragment
  )> }
);

export type OverrideDayMutationVariables = Exact<{
  day: Scalars['Date'];
  medicId: Scalars['String'];
}>;


export type OverrideDayMutation = (
  { __typename?: 'Mutation' }
  & { overrideDay: Array<(
    { __typename?: 'AgendaEntryInDay' }
    & AgendaEntryInDayDataFragment
  )> }
);

export type CreateOverrideEntryMutationVariables = Exact<{
  date: Scalars['Date'];
  agendaEntry: CreateAgendaEntryInput;
  medicId: Scalars['String'];
}>;


export type CreateOverrideEntryMutation = (
  { __typename?: 'Mutation' }
  & { createOverrideEntry: (
    { __typename?: 'AgendaEntryInDay' }
    & AgendaEntryInDayDataFragment
  ) }
);

export type ResetDayMutationVariables = Exact<{
  day: Scalars['Date'];
  medicId: Scalars['String'];
}>;


export type ResetDayMutation = (
  { __typename?: 'Mutation' }
  & { resetDay: Array<(
    { __typename?: 'AgendaEntryInDay' }
    & AgendaEntryInDayDataFragment
  )> }
);

export type CreateBreakMutationVariables = Exact<{
  medicId: Scalars['String'];
  break: CreateBreakInput;
}>;


export type CreateBreakMutation = (
  { __typename?: 'Mutation' }
  & { createBreak: (
    { __typename?: 'Break' }
    & BreakDataFragment
  ) }
);

export type CreateAppointmentMutationVariables = Exact<{
  medicId: Scalars['String'];
  appointment: CreateAppointmentInput;
}>;


export type CreateAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { createAppointment: (
    { __typename?: 'Appointment' }
    & AppointmentDataFragment
  ) }
);

export type UpdateBreakMutationVariables = Exact<{
  id: Scalars['String'];
  break: UpdateBreakInput;
}>;


export type UpdateBreakMutation = (
  { __typename?: 'Mutation' }
  & { updateBreak: (
    { __typename?: 'Break' }
    & BreakDataFragment
  ) }
);

export type UpdateAppointmentMutationVariables = Exact<{
  id: Scalars['String'];
  appointment: UpdateAppointmentInput;
}>;


export type UpdateAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { updateAppointment: (
    { __typename?: 'Appointment' }
    & AppointmentDataFragment
  ) }
);

export type DeleteBreakMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteBreakMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBreak'>
);

export type DeleteAppointmentMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteAppointmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAppointment'>
);

export type CopyAgendaListMutationVariables = Exact<{
  id: Scalars['String'];
  agendaList: CopyAgendaListInput;
}>;


export type CopyAgendaListMutation = (
  { __typename?: 'Mutation' }
  & { copyAgendaList: (
    { __typename?: 'AgendaList' }
    & AgendaListDataFragment
  ) }
);

export type AssignMedicToEntryMutationVariables = Exact<{
  medicId: Scalars['String'];
  id: Scalars['String'];
}>;


export type AssignMedicToEntryMutation = (
  { __typename?: 'Mutation' }
  & { assignMedicToEntry: (
    { __typename?: 'AgendaEntry' }
    & AgendaEntryDataFragment
  ) }
);

export type DissociateFromEntryMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DissociateFromEntryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'dissociateFromEntry'>
);

export type ChatAccessTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type ChatAccessTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'getChatAccessToken'>
);

export type StartChatMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type StartChatMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createChat'>
);

export type CreateClinicMutationVariables = Exact<{
  medicId: Scalars['String'];
  clinic: CreateClinicInput;
}>;


export type CreateClinicMutation = (
  { __typename?: 'Mutation' }
  & { createClinic: (
    { __typename?: 'Clinic' }
    & ClinicDataFragment
  ) }
);

export type UpdateClinicMutationVariables = Exact<{
  id: Scalars['String'];
  clinic: UpdateClinicInput;
}>;


export type UpdateClinicMutation = (
  { __typename?: 'Mutation' }
  & { updateClinic: (
    { __typename?: 'Clinic' }
    & ClinicDataFragment
  ) }
);

export type DeleteClinicMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteClinicMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteClinic'>
);

export type CreateClinicRoomMutationVariables = Exact<{
  clinicId: Scalars['String'];
  room: CreateClinicRoomInput;
}>;


export type CreateClinicRoomMutation = (
  { __typename?: 'Mutation' }
  & { createClinicRoom: (
    { __typename?: 'ClinicRoom' }
    & ClinicRoomDataFragment
  ) }
);

export type UpdateClinicRoomMutationVariables = Exact<{
  id: Scalars['String'];
  room: UpdateClinicRoomInput;
}>;


export type UpdateClinicRoomMutation = (
  { __typename?: 'Mutation' }
  & { updateClinicRoom: (
    { __typename?: 'ClinicRoom' }
    & ClinicRoomDataFragment
  ) }
);

export type DeleteClinicRoomMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteClinicRoomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteClinicRoom'>
);

export type InvitePatientMutationVariables = Exact<{
  medicId: Scalars['String'];
  email: Scalars['String'];
  patientProfessionalId: Scalars['String'];
}>;


export type InvitePatientMutation = (
  { __typename?: 'Mutation' }
  & { invitePatient: (
    { __typename?: 'Invite' }
    & Pick<Invite, 'id'>
  ) }
);

export type InviteAssistantMutationVariables = Exact<{
  medicId: Scalars['String'];
  email: Scalars['String'];
}>;


export type InviteAssistantMutation = (
  { __typename?: 'Mutation' }
  & { inviteAssistant: (
    { __typename?: 'Invite' }
    & Pick<Invite, 'id'>
  ) }
);

export type InviteToOrganizationMutationVariables = Exact<{
  orgId: Scalars['String'];
  role: Scalars['String'];
  email: Scalars['String'];
}>;


export type InviteToOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { inviteToOrganization: (
    { __typename?: 'Invite' }
    & Pick<Invite, 'id'>
  ) }
);

export type AcceptAssistantInviteMutationVariables = Exact<{
  id: Scalars['String'];
  tos: Scalars['Boolean'];
}>;


export type AcceptAssistantInviteMutation = (
  { __typename?: 'Mutation' }
  & { acceptAssistantInvite: { __typename?: 'Medic' } | (
    { __typename?: 'Assistant' }
    & AssistantBasicDataFragment
  ) | { __typename?: 'Operator' } | { __typename?: 'OrganizationSa' } }
);

export type AcceptOrganizationInviteMutationVariables = Exact<{
  id: Scalars['String'];
  medicId: Scalars['String'];
}>;


export type AcceptOrganizationInviteMutation = (
  { __typename?: 'Mutation' }
  & { acceptOrganizationInvite: (
    { __typename?: 'OrganizationProfessional' }
    & Pick<OrganizationProfessional, 'id'>
  ) }
);

export type DeleteInviteMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteInviteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteInvite'>
);

export type UpsertInvoiceOptionsMutationVariables = Exact<{
  data: InvoiceOptionsInput;
}>;


export type UpsertInvoiceOptionsMutation = (
  { __typename?: 'Mutation' }
  & { createOrUpdateInvoiceOptions?: Maybe<(
    { __typename?: 'InvoiceOptions' }
    & InvoiceOptionsDataFragment
  )> }
);

export type UpsertInvoiceMutationVariables = Exact<{
  data: InvoiceInput;
}>;


export type UpsertInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createOrUpdateInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceDataFragment
  )> }
);

export type UpsertInvoiceRowMutationVariables = Exact<{
  data?: Maybe<InvoiceRowInput>;
}>;


export type UpsertInvoiceRowMutation = (
  { __typename?: 'Mutation' }
  & { createOrUpdateInvoiceRow?: Maybe<(
    { __typename?: 'InvoiceRow' }
    & InvoiceRowDataFragment
  )> }
);

export type DeleteInvoiceRowMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteInvoiceRowMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvoiceRow?: Maybe<(
    { __typename?: 'InvoiceRow' }
    & Pick<InvoiceRow, 'id'>
  )> }
);

export type DeleteInvoiceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id'>
  )> }
);

export type UpdateMedicProfileMutationVariables = Exact<{
  medic: UpdateMedicInput;
}>;


export type UpdateMedicProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateMedic: (
    { __typename?: 'Medic' }
    & MedicBasicDataFragment
  ) }
);

export type AddSpecializationToMedicMutationVariables = Exact<{
  specializationId: Scalars['String'];
}>;


export type AddSpecializationToMedicMutation = (
  { __typename?: 'Mutation' }
  & { addSpecializationToMedic: (
    { __typename?: 'Specialization' }
    & SpecializationDataFragment
  ) }
);

export type RemoveSpecializationFromMedicMutationVariables = Exact<{
  specializationId: Scalars['String'];
}>;


export type RemoveSpecializationFromMedicMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeSpecializationFromMedic'>
);

export type CreateProfessionalProvisionMutationVariables = Exact<{
  medicId: Scalars['String'];
  provision: CreateProfessionalProvisionInput;
}>;


export type CreateProfessionalProvisionMutation = (
  { __typename?: 'Mutation' }
  & { createProfessionalProvision: (
    { __typename?: 'ProfessionalProvision' }
    & ProfessionalProvisionDataFragment
  ) }
);

export type UpdateProfessionalProvisionMutationVariables = Exact<{
  id: Scalars['String'];
  provision: UpdateProfessionalProvisionInput;
}>;


export type UpdateProfessionalProvisionMutation = (
  { __typename?: 'Mutation' }
  & { updateProfessionalProvision: (
    { __typename?: 'ProfessionalProvision' }
    & ProfessionalProvisionDataFragment
  ) }
);

export type DeleteProfessionalProvisionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteProfessionalProvisionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProfessionalProvision'>
);

export type CreateMedicMutationVariables = Exact<{
  medic: CreateMedicInput;
}>;


export type CreateMedicMutation = (
  { __typename?: 'Mutation' }
  & { createMedic: (
    { __typename?: 'Medic' }
    & MedicDataFragment
  ) }
);

export type SendMailMutationVariables = Exact<{
  medicId: Scalars['String'];
  to: Array<Scalars['String']> | Scalars['String'];
  title: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type SendMailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendMail'>
);

export type SendMassiveEmailMutationVariables = Exact<{
  medicId: Scalars['String'];
  target: MassiveEmailTarget;
  title: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type SendMassiveEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendMassiveEmail'>
);

export type UploadPrivacyMutationVariables = Exact<{
  medicId: Scalars['String'];
  file?: Maybe<CreateAttachmentInput>;
  link?: Maybe<Scalars['String']>;
}>;


export type UploadPrivacyMutation = (
  { __typename?: 'Mutation' }
  & { uploadPrivacyPolicy: (
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  ) }
);

export type RequestConnectionMutationVariables = Exact<{
  requestId: Scalars['String'];
  id: Scalars['String'];
}>;


export type RequestConnectionMutation = (
  { __typename?: 'Mutation' }
  & { requestConnection: (
    { __typename?: 'Network' }
    & NetworkDataFragment
  ) }
);

export type UpdateConnectionMutationVariables = Exact<{
  status: NetworkStatus;
  id: Scalars['String'];
}>;


export type UpdateConnectionMutation = (
  { __typename?: 'Mutation' }
  & { updateConnection: (
    { __typename?: 'Network' }
    & NetworkDataFragment
  ) }
);

export type DeleteConnectionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteConnectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteConnection'>
);

export type ReadNotificationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ReadNotificationMutation = (
  { __typename?: 'Mutation' }
  & { markNotificationAsRead: (
    { __typename?: 'Notification' }
    & NotificationDataFragment
  ) }
);

export type ReadManyNotificationsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type ReadManyNotificationsMutation = (
  { __typename?: 'Mutation' }
  & { markManyNotificationsAsRead: Array<(
    { __typename?: 'Notification' }
    & NotificationDataFragment
  )> }
);

export type CreateOperatorMutationVariables = Exact<{
  operator: CreateOperatorInput;
}>;


export type CreateOperatorMutation = (
  { __typename?: 'Mutation' }
  & { createOperator: (
    { __typename?: 'Operator' }
    & OperatorDataFragment
  ) }
);

export type UpdateOperatorProfileMutationVariables = Exact<{
  operator: UpdateOperatorInput;
}>;


export type UpdateOperatorProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateOperator: (
    { __typename?: 'Operator' }
    & OperatorBasicDataFragment
  ) }
);

export type CreatePatientProfessionalMutationVariables = Exact<{
  patient: CreatePatientInput;
  patientProfessional: CreatePatientProfessionalInput;
  medicId: Scalars['String'];
}>;


export type CreatePatientProfessionalMutation = (
  { __typename?: 'Mutation' }
  & { createPatientProfessional: (
    { __typename?: 'PatientProfessional' }
    & PatientProfessionalDataFragment
  ) }
);

export type UpdatePatientProfessionalMutationVariables = Exact<{
  patient: UpdatePatientProfessionalInput;
  id: Scalars['String'];
}>;


export type UpdatePatientProfessionalMutation = (
  { __typename?: 'Mutation' }
  & { updatePatientProfessional: (
    { __typename?: 'PatientProfessional' }
    & PatientProfessionalDataFragment
  ) }
);

export type ArchivePatientProfessionalMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ArchivePatientProfessionalMutation = (
  { __typename?: 'Mutation' }
  & { archivePatientProfessional: (
    { __typename?: 'PatientProfessional' }
    & PatientProfessionalDataFragment
  ) }
);

export type SharePatientWithProfessionalMutationVariables = Exact<{
  patientProfessionalId: Scalars['String'];
  medicId: Scalars['String'];
  orgId: Scalars['String'];
}>;


export type SharePatientWithProfessionalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sharePatientWithProfessional'>
);

export type RevokeSharePatientWithProfessionalMutationVariables = Exact<{
  patientProfessionalId: Scalars['String'];
  medicId: Scalars['String'];
  orgId: Scalars['String'];
}>;


export type RevokeSharePatientWithProfessionalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revokeSharePatientWithProfessional'>
);

export type UpdatePatientProfessionalStatusMutationVariables = Exact<{
  id: Scalars['String'];
  medicId: Scalars['String'];
  status: UpdatablePatientProfessionalStatus;
}>;


export type UpdatePatientProfessionalStatusMutation = (
  { __typename?: 'Mutation' }
  & { updatePatientProfessionalStatus: (
    { __typename?: 'PatientProfessional' }
    & Pick<PatientProfessional, 'id' | 'status'>
  ) }
);

export type CreateReportMutationVariables = Exact<{
  patientProfessionalId: Scalars['String'];
  report: CreateReportInput;
}>;


export type CreateReportMutation = (
  { __typename?: 'Mutation' }
  & { createReportAsProfessional: (
    { __typename?: 'Report' }
    & ReportSharedDataFragment
  ) }
);

export type DeleteReportMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteReportAsProfessional'>
);

export type UpdateReportMutationVariables = Exact<{
  id: Scalars['String'];
  report: UpdateReportInput;
}>;


export type UpdateReportMutation = (
  { __typename?: 'Mutation' }
  & { updateReportAsProfessional: (
    { __typename?: 'Report' }
    & ReportSharedDataFragment
  ) }
);

export type ReadRequestMessageMutationVariables = Exact<{
  id: Scalars['String'];
  medicId: Scalars['String'];
  readAt: Scalars['DateTime'];
}>;


export type ReadRequestMessageMutation = (
  { __typename?: 'Mutation' }
  & { markRequestMessageAsReadAsProfessional: (
    { __typename?: 'RequestMessage' }
    & RequestMessageDataFragment
  ) }
);

export type AddRequestMessageMutationVariables = Exact<{
  requestId: Scalars['String'];
  medicId: Scalars['String'];
  message: CreateRequestMessageInput;
}>;


export type AddRequestMessageMutation = (
  { __typename?: 'Mutation' }
  & { answerRequestAsProfessional: (
    { __typename?: 'RequestMessage' }
    & RequestMessageDataFragment
  ) }
);

export type ResolveRequestMutationVariables = Exact<{
  requestId: Scalars['String'];
  result: RequestResult;
}>;


export type ResolveRequestMutation = (
  { __typename?: 'Mutation' }
  & { resolveRequest: (
    { __typename?: 'Request' }
    & Pick<Request, 'result'>
  ) }
);

export type CreateRequestMutationVariables = Exact<{
  medicId: Scalars['String'];
  request: CreateRequestInput;
}>;


export type CreateRequestMutation = (
  { __typename?: 'Mutation' }
  & { createRequestAsProfessional: (
    { __typename?: 'Request' }
    & RequestDataFragment
  ) }
);

export type DeleteRequestMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRequest'>
);

export type SearchCityQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type SearchCityQuery = (
  { __typename?: 'Query' }
  & { cities: (
    { __typename?: 'CityPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'City' }
      & CityDataFragment
    )> }
  ) }
);

export type SearchDistrictQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type SearchDistrictQuery = (
  { __typename?: 'Query' }
  & { cities: (
    { __typename?: 'CityPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'City' }
      & CityDataFragment
    )> }
  ) }
);

export type GetCityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCityQuery = (
  { __typename?: 'Query' }
  & { city: (
    { __typename?: 'City' }
    & CityDataFragment
  ) }
);

export type AgendaEntriesByDayQueryVariables = Exact<{
  medicId: Scalars['String'];
  day: Scalars['Date'];
}>;


export type AgendaEntriesByDayQuery = (
  { __typename?: 'Query' }
  & { agendaEntryByDay: Array<(
    { __typename?: 'AgendaEntryInDay' }
    & AgendaEntryInDayDataFragment
  )> }
);

export type AgendaEntriesInDaysQueryVariables = Exact<{
  medicId: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;


export type AgendaEntriesInDaysQuery = (
  { __typename?: 'Query' }
  & { agendaEntriesInDays: Array<(
    { __typename?: 'AgendaEntryInDay' }
    & AgendaEntryInDayDataFragment
  )> }
);

export type AgendaListsQueryVariables = Exact<{
  medicId: Scalars['String'];
}>;


export type AgendaListsQuery = (
  { __typename?: 'Query' }
  & { agendaLists: Array<(
    { __typename?: 'AgendaList' }
    & AgendaListBasicDataFragment
  )> }
);

export type AgendaListQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AgendaListQuery = (
  { __typename?: 'Query' }
  & { agendaList: (
    { __typename?: 'AgendaList' }
    & AgendaListDataFragment
  ) }
);

export type AgendaEventsByDayQueryVariables = Exact<{
  medicId: Scalars['String'];
  day: Scalars['Date'];
  clinicIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type AgendaEventsByDayQuery = (
  { __typename?: 'Query' }
  & { agendaEventsByDay: Array<(
    { __typename?: 'Appointment' }
    & AgendaEventData_Appointment_Fragment
  ) | (
    { __typename?: 'Break' }
    & AgendaEventData_Break_Fragment
  )> }
);

export type AgendaEventsInDaysQueryVariables = Exact<{
  medicId: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  clinicIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type AgendaEventsInDaysQuery = (
  { __typename?: 'Query' }
  & { agendaEventsInDays: Array<(
    { __typename?: 'Appointment' }
    & AgendaEventBasicData_Appointment_Fragment
  ) | (
    { __typename?: 'Break' }
    & AgendaEventBasicData_Break_Fragment
  )> }
);

export type AgendaEventQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AgendaEventQuery = (
  { __typename?: 'Query' }
  & { agendaEvent: (
    { __typename?: 'Appointment' }
    & AgendaEventData_Appointment_Fragment
  ) | (
    { __typename?: 'Break' }
    & AgendaEventData_Break_Fragment
  ) }
);

export type AppointmentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AppointmentQuery = (
  { __typename?: 'Query' }
  & { appointment: (
    { __typename?: 'Appointment' }
    & AppointmentDataFragment
  ) }
);

export type AgendaEntriesAndEventsByDayQueryVariables = Exact<{
  medicId: Scalars['String'];
  day: Scalars['Date'];
}>;


export type AgendaEntriesAndEventsByDayQuery = (
  { __typename?: 'Query' }
  & { agendaEntryByDay: Array<(
    { __typename?: 'AgendaEntryInDay' }
    & AgendaEntryInDayDataFragment
  )>, agendaEventsByDay: Array<(
    { __typename?: 'Appointment' }
    & AgendaEventData_Appointment_Fragment
  ) | (
    { __typename?: 'Break' }
    & AgendaEventData_Break_Fragment
  )> }
);

export type AgendaEntriesAndEventsInDaysQueryVariables = Exact<{
  medicId: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;


export type AgendaEntriesAndEventsInDaysQuery = (
  { __typename?: 'Query' }
  & { agendaEntriesInDays: Array<(
    { __typename?: 'AgendaEntryInDay' }
    & AgendaEntryInDayDataFragment
  )>, agendaEventsInDays: Array<(
    { __typename?: 'Appointment' }
    & AgendaEventBasicData_Appointment_Fragment
  ) | (
    { __typename?: 'Break' }
    & AgendaEventBasicData_Break_Fragment
  )> }
);

export type TimeslotsQueryVariables = Exact<{
  provisionId: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  clinicIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type TimeslotsQuery = (
  { __typename?: 'Query' }
  & { timeslots: Array<(
    { __typename?: 'TimeSlot' }
    & Pick<TimeSlot, 'day' | 'slots'>
  )> }
);

export type ClinicsByIdsQueryVariables = Exact<{
  medicId: Scalars['ID'];
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ClinicsByIdsQuery = (
  { __typename?: 'Query' }
  & { clinics: Array<(
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name' | 'phone'>
  )> }
);

export type PendingInvitesQueryVariables = Exact<{ [key: string]: never; }>;


export type PendingInvitesQuery = (
  { __typename?: 'Query' }
  & { pendingInvites: Array<(
    { __typename?: 'Invite' }
    & InviteDataFragment
  )> }
);

export type SentInvitesQueryVariables = Exact<{
  medicId: Scalars['String'];
}>;


export type SentInvitesQuery = (
  { __typename?: 'Query' }
  & { sentInvites: Array<(
    { __typename?: 'Invite' }
    & InviteDataFragment
  )> }
);

export type InviteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InviteQuery = (
  { __typename?: 'Query' }
  & { invite: (
    { __typename?: 'Invite' }
    & InviteDataFragment
  ) }
);

export type InvoicesQueryVariables = Exact<{
  medicId: Scalars['Int'];
  year: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type InvoicesQuery = (
  { __typename?: 'Query' }
  & { getInvoices?: Maybe<(
    { __typename?: 'InvoicePaginator' }
    & { data: Array<(
      { __typename?: 'Invoice' }
      & InvoiceDataFragment
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'total' | 'hasMorePages'>
    ) }
  )> }
);

export type InvoiceQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type InvoiceQuery = (
  { __typename?: 'Query' }
  & { getInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceDataFragment
  )> }
);

export type NextInvoiceNumberQueryVariables = Exact<{
  medicId: Scalars['ID'];
  year: Scalars['Int'];
}>;


export type NextInvoiceNumberQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getInvoiceNextNumber'>
);

export type InvoiceOptionsQueryVariables = Exact<{
  medicId: Scalars['Int'];
}>;


export type InvoiceOptionsQuery = (
  { __typename?: 'Query' }
  & { getInvoiceOptions?: Maybe<(
    { __typename?: 'InvoiceOptions' }
    & InvoiceOptionsDataFragment
  )> }
);

export type InvoiceVatCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type InvoiceVatCodesQuery = (
  { __typename?: 'Query' }
  & { getInvoiceVatCodes: Array<(
    { __typename?: 'InvoiceVatCode' }
    & InvoiceVatCodeDataFragment
  )> }
);

export type InvoicePaymentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type InvoicePaymentTypesQuery = (
  { __typename?: 'Query' }
  & { getInvoicePaymentTypes: Array<(
    { __typename?: 'InvoicePaymentType' }
    & InvoicePaymentTypeDataFragment
  )> }
);

export type PatientInvoicesQueryVariables = Exact<{
  patientId: Scalars['Int'];
  professionalId: Scalars['Int'];
  year?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type PatientInvoicesQuery = (
  { __typename?: 'Query' }
  & { getInvoicesByPatient?: Maybe<(
    { __typename?: 'InvoicePaginator' }
    & { data: Array<(
      { __typename?: 'Invoice' }
      & InvoiceDataFragment
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'total' | 'hasMorePages'>
    ) }
  )> }
);

export type SearchRecipientQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type SearchRecipientQuery = (
  { __typename?: 'Query' }
  & { professionals: (
    { __typename?: 'ProfessionalPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Medic' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { traits: (
          { __typename?: 'UserTraits' }
          & Pick<UserTraits, 'taxId'>
        ) }
      )> }
      & ProfessionalBasicData_Medic_Fragment
    ) | (
      { __typename?: 'Assistant' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { traits: (
          { __typename?: 'UserTraits' }
          & Pick<UserTraits, 'taxId'>
        ) }
      )> }
      & ProfessionalBasicData_Assistant_Fragment
    ) | (
      { __typename?: 'Operator' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { traits: (
          { __typename?: 'UserTraits' }
          & Pick<UserTraits, 'taxId'>
        ) }
      )> }
      & ProfessionalBasicData_Operator_Fragment
    ) | (
      { __typename?: 'OrganizationSa' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { traits: (
          { __typename?: 'UserTraits' }
          & Pick<UserTraits, 'taxId'>
        ) }
      )> }
      & ProfessionalBasicData_OrganizationSa_Fragment
    )> }
  ), patients: (
    { __typename?: 'PatientPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Patient' }
      & PatientBasicDataFragment
    )> }
  ) }
);

export type LicenseTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type LicenseTypesQuery = (
  { __typename?: 'Query' }
  & { licenseTypes: Array<(
    { __typename?: 'LicenseType' }
    & LicenseTypeDataFragment
  )> }
);

export type NetworkQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NetworkQuery = (
  { __typename?: 'Query' }
  & { network: (
    { __typename?: 'Network' }
    & NetworkDataFragment
  ) }
);

export type NotificationsQueryVariables = Exact<{
  filters?: Maybe<NotificationFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type NotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications: (
    { __typename?: 'NotificationPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Notification' }
      & NotificationDataFragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & PaginationInfoDataFragment
    ) }
  ) }
);

export type NotificationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NotificationQuery = (
  { __typename?: 'Query' }
  & { notification: (
    { __typename?: 'Notification' }
    & NotificationDataFragment
  ) }
);

export type ManagedOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ManagedOrganizationsQuery = (
  { __typename?: 'Query' }
  & { managedOrganizations: Array<(
    { __typename?: 'OrganizationProfessional' }
    & OrganizationProfessionalDataFragment
  )> }
);

export type PatientsQueryVariables = Exact<{
  filter?: Maybe<PatientFilterInput>;
  order?: Maybe<PatientOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type PatientsQuery = (
  { __typename?: 'Query' }
  & { patients: (
    { __typename?: 'PatientPaginatedResponse' }
    & PatientPaginatedResponseDataFragment
  ) }
);

export type PatientProfessionalsQueryVariables = Exact<{
  filter?: Maybe<PatientProfessionalFilterInput>;
  order?: Maybe<PatientProfessionalOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  medicId: Scalars['String'];
}>;


export type PatientProfessionalsQuery = (
  { __typename?: 'Query' }
  & { patientProfessionals: (
    { __typename?: 'PatientProfessionalPaginatedResponse' }
    & PatientProfessionalPaginatedResponseDataFragment
  ) }
);

export type PatientProfessionalQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PatientProfessionalQuery = (
  { __typename?: 'Query' }
  & { patientProfessional: (
    { __typename?: 'PatientProfessional' }
    & { upcomingAppointments: Array<(
      { __typename?: 'Appointment' }
      & AppointmentDataFragment
    )>, reports: Array<(
      { __typename?: 'Report' }
      & ReportSharedDataFragment
    )> }
    & PatientProfessionalDataFragment
  ) }
);

export type SearchPatientProfessionalQueryVariables = Exact<{
  like: Scalars['String'];
  filter?: Maybe<PatientProfessionalFilterInput>;
  order?: Maybe<PatientProfessionalOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  medicId: Scalars['String'];
}>;


export type SearchPatientProfessionalQuery = (
  { __typename?: 'Query' }
  & { searchPatientProfessionals: (
    { __typename?: 'PatientProfessionalPaginatedResponse' }
    & PatientProfessionalPaginatedResponseDataFragment
  ) }
);

export type SearchPatientByTaxIdQueryVariables = Exact<{
  taxId: Scalars['TaxId'];
}>;


export type SearchPatientByTaxIdQuery = (
  { __typename?: 'Query' }
  & { searchPatientByTaxId?: Maybe<(
    { __typename?: 'PublicPatient' }
    & Pick<PublicPatient, 'taxId'>
  ) | (
    { __typename?: 'Patient' }
    & PatientBasicDataFragment
  )> }
);

export type ProfessionalQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProfessionalQuery = (
  { __typename?: 'Query' }
  & { professional: (
    { __typename?: 'Medic' }
    & ProfessionalData_Medic_Fragment
  ) | (
    { __typename?: 'Assistant' }
    & ProfessionalData_Assistant_Fragment
  ) | (
    { __typename?: 'Operator' }
    & ProfessionalData_Operator_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & ProfessionalData_OrganizationSa_Fragment
  ) }
);

export type ProfessionalsQueryVariables = Exact<{
  name: Scalars['String'];
  roleFilter?: Maybe<ProfessionalRoleFieldFilterInput>;
}>;


export type ProfessionalsQuery = (
  { __typename?: 'Query' }
  & { professionals: (
    { __typename?: 'ProfessionalPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Medic' }
      & ProfessionalBasicData_Medic_Fragment
    ) | (
      { __typename?: 'Assistant' }
      & ProfessionalBasicData_Assistant_Fragment
    ) | (
      { __typename?: 'Operator' }
      & ProfessionalBasicData_Operator_Fragment
    ) | (
      { __typename?: 'OrganizationSa' }
      & ProfessionalBasicData_OrganizationSa_Fragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & PaginationInfoDataFragment
    ) }
  ) }
);

export type ProfessionalsByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type ProfessionalsByEmailQuery = (
  { __typename?: 'Query' }
  & { professionalsByEmail: Array<(
    { __typename?: 'Medic' }
    & ProfessionalBasicData_Medic_Fragment
  ) | (
    { __typename?: 'Assistant' }
    & ProfessionalBasicData_Assistant_Fragment
  ) | (
    { __typename?: 'Operator' }
    & ProfessionalBasicData_Operator_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & ProfessionalBasicData_OrganizationSa_Fragment
  )> }
);

export type SearchProfessionalsQueryVariables = Exact<{
  name: Scalars['String'];
  roleFilter?: Maybe<ProfessionalRoleFieldFilterInput>;
}>;


export type SearchProfessionalsQuery = (
  { __typename?: 'Query' }
  & { professionals: (
    { __typename?: 'ProfessionalPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Medic' }
      & SearchProfessionalData_Medic_Fragment
    ) | (
      { __typename?: 'Assistant' }
      & SearchProfessionalData_Assistant_Fragment
    ) | (
      { __typename?: 'Operator' }
      & SearchProfessionalData_Operator_Fragment
    ) | (
      { __typename?: 'OrganizationSa' }
      & SearchProfessionalData_OrganizationSa_Fragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & PaginationInfoDataFragment
    ) }
  ) }
);

export type PatientProfilesQueryVariables = Exact<{ [key: string]: never; }>;


export type PatientProfilesQuery = (
  { __typename?: 'Query' }
  & { patientProfiles?: Maybe<Array<(
    { __typename?: 'PatientUser' }
    & PatientUserDataFragment
  )>> }
);

export type ProfessionalProfilesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfessionalProfilesQuery = (
  { __typename?: 'Query' }
  & { medicProfile?: Maybe<(
    { __typename?: 'Medic' }
    & MedicDataFragment
  )>, assistantProfile?: Maybe<(
    { __typename?: 'Assistant' }
    & { networks: Array<(
      { __typename?: 'Network' }
      & { inverse: (
        { __typename?: 'Medic' }
        & MedicBasicDataFragment
      ) | { __typename?: 'Assistant' } | (
        { __typename?: 'Operator' }
        & OperatorBasicDataFragment
      ) | { __typename?: 'OrganizationSa' } }
      & NetworkDataFragment
    )> }
    & AssistantBasicDataFragment
  )> }
);

export type MedicProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type MedicProfileQuery = (
  { __typename?: 'Query' }
  & { medicProfile?: Maybe<(
    { __typename?: 'Medic' }
    & Pick<Medic, 'id' | 'role' | 'hasActiveLicense' | 'userId' | 'type' | 'freeLancer' | 'name' | 'surname'>
  )> }
);

export type AssistantProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type AssistantProfileQuery = (
  { __typename?: 'Query' }
  & { assistantProfile?: Maybe<(
    { __typename?: 'Assistant' }
    & { networks: Array<(
      { __typename?: 'Network' }
      & { inverse: (
        { __typename?: 'Medic' }
        & { specializations: Array<(
          { __typename?: 'Specialization' }
          & Pick<Specialization, 'id'>
        )> }
        & MedicBasicDataFragment
      ) | { __typename?: 'Assistant' } | (
        { __typename?: 'Operator' }
        & { specializations: Array<(
          { __typename?: 'Specialization' }
          & Pick<Specialization, 'id'>
        )> }
        & OperatorBasicDataFragment
      ) | (
        { __typename?: 'OrganizationSa' }
        & OrganizationSaBasicDataFragment
      ) }
      & NetworkDataFragment
    )> }
    & AssistantBasicDataFragment
  )> }
);

export type OperatorProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type OperatorProfileQuery = (
  { __typename?: 'Query' }
  & { operatorProfile?: Maybe<(
    { __typename?: 'Operator' }
    & Pick<Operator, 'id' | 'role' | 'hasActiveLicense' | 'userId' | 'profession' | 'name' | 'surname'>
  )> }
);

export type SearchProvisionsQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type SearchProvisionsQuery = (
  { __typename?: 'Query' }
  & { provisions: (
    { __typename?: 'ProvisionPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Provision' }
      & ProvisionDataFragment
    )> }
  ) }
);

export type ProfessionalReportsQueryVariables = Exact<{
  medicId: Scalars['String'];
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
}>;


export type ProfessionalReportsQuery = (
  { __typename?: 'Query' }
  & { professionalReports: (
    { __typename?: 'ReportsPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Report' }
      & ReportSharedDataFragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & PaginationInfoDataFragment
    ) }
  ) }
);

export type ProfessionalReportQueryVariables = Exact<{
  medicId: Scalars['String'];
  id: Scalars['String'];
}>;


export type ProfessionalReportQuery = (
  { __typename?: 'Query' }
  & { professionalReport: (
    { __typename?: 'Report' }
    & ReportSharedDataFragment
  ) }
);

export type SentRequestsQueryVariables = Exact<{
  medicId: Scalars['String'];
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<RequestFilterInput>;
}>;


export type SentRequestsQuery = (
  { __typename?: 'Query' }
  & { sentRequestsAsProfessional: (
    { __typename?: 'RequestPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Request' }
      & RequestDataFragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & PaginationInfoDataFragment
    ) }
  ) }
);

export type ReceivedRequestsQueryVariables = Exact<{
  medicId: Scalars['String'];
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<RequestFilterInput>;
}>;


export type ReceivedRequestsQuery = (
  { __typename?: 'Query' }
  & { receivedRequestsAsProfessional: (
    { __typename?: 'RequestPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Request' }
      & RequestDataFragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & PaginationInfoDataFragment
    ) }
  ) }
);

export type RequestQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RequestQuery = (
  { __typename?: 'Query' }
  & { request: (
    { __typename?: 'Request' }
    & RequestDataFragment
  ) }
);

export type SearchSpecializationsQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type SearchSpecializationsQuery = (
  { __typename?: 'Query' }
  & { specializations: Array<(
    { __typename?: 'Specialization' }
    & SpecializationDataFragment
  )> }
);

export type OnNewNotificationSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnNewNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { onNewNotification: (
    { __typename?: 'Notification' }
    & NotificationDataFragment
  ) }
);

export const ProvisionDataFragmentDoc = gql`
    fragment ProvisionData on Provision {
  id
  name
  defaultPrice
  defaultDuration
  code
}
    `;
export const ProfessionalProvisionDataFragmentDoc = gql`
    fragment ProfessionalProvisionData on ProfessionalProvision {
  id
  duration
  price
  mandatory
  createdAt
  updatedAt
  provision {
    ...ProvisionData
  }
}
    ${ProvisionDataFragmentDoc}`;
export const ClinicRoomDataFragmentDoc = gql`
    fragment ClinicRoomData on ClinicRoom {
  id
  createdAt
  updatedAt
  name
  phone
  meta
  clinicId
  provisions {
    ...ProfessionalProvisionData
  }
}
    ${ProfessionalProvisionDataFragmentDoc}`;
export const AgendaEntryInDayDataFragmentDoc = gql`
    fragment AgendaEntryInDayData on AgendaEntryInDay {
  day
  id
  disabled
  weekday
  override
  professionalId
  informers
  assistantIds
  visibility
  agendaWeekId
  from
  to
  clinicId
  ownerId
  clinic {
    ...ClinicRoomData
    clinic {
      id
      name
    }
  }
}
    ${ClinicRoomDataFragmentDoc}`;
export const AgendaListBasicDataFragmentDoc = gql`
    fragment AgendaListBasicData on AgendaList {
  id
  from
  to
  openFor
  status
  name
}
    `;
export const AgendaWeekBasicDataFragmentDoc = gql`
    fragment AgendaWeekBasicData on AgendaWeek {
  id
  order
}
    `;
export const AgendaEntryBasicDataFragmentDoc = gql`
    fragment AgendaEntryBasicData on AgendaEntry {
  id
  disabled
  weekday
  override
  informers
  assistantIds
  professionalId
  visibility
  agendaWeekId
  from
  to
  clinicId
  ownerId
}
    `;
export const AgendaEntryDataFragmentDoc = gql`
    fragment AgendaEntryData on AgendaEntry {
  ...AgendaEntryBasicData
  clinic {
    ...ClinicRoomData
  }
}
    ${AgendaEntryBasicDataFragmentDoc}
${ClinicRoomDataFragmentDoc}`;
export const AgendaWeekDataFragmentDoc = gql`
    fragment AgendaWeekData on AgendaWeek {
  ...AgendaWeekBasicData
  entries {
    ...AgendaEntryData
  }
}
    ${AgendaWeekBasicDataFragmentDoc}
${AgendaEntryDataFragmentDoc}`;
export const AgendaListDataFragmentDoc = gql`
    fragment AgendaListData on AgendaList {
  ...AgendaListBasicData
  weeks {
    ...AgendaWeekData
  }
}
    ${AgendaListBasicDataFragmentDoc}
${AgendaWeekDataFragmentDoc}`;
export const AppointmentBasicDataFragmentDoc = gql`
    fragment AppointmentBasicData on Appointment {
  id
  patientName
  patientTaxId
  patientProfessionalId
  from
  to
  day
  clinicId
  createdAt
  notes
  provision {
    id
    provision {
      id
      name
    }
  }
}
    `;
export const BreakBasicDataFragmentDoc = gql`
    fragment BreakBasicData on Break {
  id
  day
  from
  to
  createdAt
  clinicId
  notes
}
    `;
export const AgendaEventBasicDataFragmentDoc = gql`
    fragment AgendaEventBasicData on AgendaEvent {
  ... on Appointment {
    ...AppointmentBasicData
  }
  ... on Break {
    ...BreakBasicData
  }
}
    ${AppointmentBasicDataFragmentDoc}
${BreakBasicDataFragmentDoc}`;
export const CrypedEmbedDataFragmentDoc = gql`
    fragment CrypedEmbedData on CryptedEmbedAddress {
  municipalityName
  municipalityId
  districtName
  nationName
  address
}
    `;
export const CityDataFragmentDoc = gql`
    fragment CityData on City {
  id
  registryCode
  name
  region {
    name
    code
  }
  district {
    name
    code
  }
}
    `;
export const EmbedAddressDataFragmentDoc = gql`
    fragment EmbedAddressData on EmbedAddress {
  municipalityName
  districtName
  address
  location
  cap
  municipalityId
  municipality {
    ...CityData
  }
}
    ${CityDataFragmentDoc}`;
export const PatientBasicDataFragmentDoc = gql`
    fragment PatientBasicData on Patient {
  id
  createdAt
  updatedAt
  name
  surname
  taxId
  birthDay
  personalMedicId
  address {
    ...EmbedAddressData
  }
}
    ${EmbedAddressDataFragmentDoc}`;
export const UserTraitsDataFragmentDoc = gql`
    fragment UserTraitsData on UserTraits {
  email
  avatar
  taxId
  name
  surname
  birthDay
}
    `;
export const UserDataFragmentDoc = gql`
    fragment UserData on User {
  id
  role
  traits {
    ...UserTraitsData
  }
}
    ${UserTraitsDataFragmentDoc}`;
export const PatientUserDataFragmentDoc = gql`
    fragment PatientUserData on PatientUser {
  id
  createdAt
  updatedAt
  userId
  patientId
  type
  user {
    ...UserData
  }
}
    ${UserDataFragmentDoc}`;
export const PatientDataFragmentDoc = gql`
    fragment PatientData on Patient {
  ...PatientBasicData
  users {
    ...PatientUserData
  }
}
    ${PatientBasicDataFragmentDoc}
${PatientUserDataFragmentDoc}`;
export const PatientProfessionalDataFragmentDoc = gql`
    fragment PatientProfessionalData on PatientProfessional {
  id
  createdAt
  updatedAt
  status
  professionalId
  patientId
  organizationProfessionalId
  fullName
  email
  phone
  address {
    ...CrypedEmbedData
  }
  patient {
    ...PatientData
  }
}
    ${CrypedEmbedDataFragmentDoc}
${PatientDataFragmentDoc}`;
export const AppointmentDataFragmentDoc = gql`
    fragment AppointmentData on Appointment {
  ...AppointmentBasicData
  patientProfessional {
    ...PatientProfessionalData
  }
  provision {
    ...ProfessionalProvisionData
  }
  clinic {
    id
    name
    phone
  }
  notes
  creator {
    ...UserData
  }
}
    ${AppointmentBasicDataFragmentDoc}
${PatientProfessionalDataFragmentDoc}
${ProfessionalProvisionDataFragmentDoc}
${UserDataFragmentDoc}`;
export const BreakDataFragmentDoc = gql`
    fragment BreakData on Break {
  ...BreakBasicData
  clinic {
    id
    name
    phone
  }
  notes
  creator {
    ...UserData
  }
}
    ${BreakBasicDataFragmentDoc}
${UserDataFragmentDoc}`;
export const AgendaEventDataFragmentDoc = gql`
    fragment AgendaEventData on AgendaEvent {
  ... on Appointment {
    ...AppointmentData
  }
  ... on Break {
    ...BreakData
  }
}
    ${AppointmentDataFragmentDoc}
${BreakDataFragmentDoc}`;
export const AttachmentDataFragmentDoc = gql`
    fragment AttachmentData on Attachment {
  id
  url
  mimeType
  name
}
    `;
export const LicenseDataFragmentDoc = gql`
    fragment LicenseData on License {
  id
  expiresAt
  transactionData
  transactionType
  licenseType {
    code
  }
}
    `;
export const ContactDataFragmentDoc = gql`
    fragment ContactData on EmbedContact {
  email
  phone
  fax
}
    `;
export const MedicBasicDataFragmentDoc = gql`
    fragment MedicBasicData on Medic {
  id
  userId
  role
  name
  surname
  registerNumber
  type
  freeLancer
  chatEnabled
  requestsEnabled
  header
  footer
  privacyPolicy {
    ...AttachmentData
  }
  user {
    ...UserData
  }
  registerDistrict {
    ...CityData
  }
  activeLicense {
    ...LicenseData
  }
  hasActiveLicense
  address {
    ...EmbedAddressData
  }
  contacts {
    ...ContactData
  }
  termsAndConditions
}
    ${AttachmentDataFragmentDoc}
${UserDataFragmentDoc}
${CityDataFragmentDoc}
${LicenseDataFragmentDoc}
${EmbedAddressDataFragmentDoc}
${ContactDataFragmentDoc}`;
export const AssistantBasicDataFragmentDoc = gql`
    fragment AssistantBasicData on Assistant {
  userId
  name
  surname
  role
  id
  createdAt
  updatedAt
  assistantTermsAndConditions
}
    `;
export const OperatorBasicDataFragmentDoc = gql`
    fragment OperatorBasicData on Operator {
  id
  role
  profession
  userId
  name
  surname
  chatEnabled
  requestsEnabled
  header
  footer
  privacyPolicy {
    ...AttachmentData
  }
  activeLicense {
    ...LicenseData
  }
  hasActiveLicense
  user {
    ...UserData
  }
  address {
    ...EmbedAddressData
  }
  contacts {
    ...ContactData
  }
  termsAndConditions
}
    ${AttachmentDataFragmentDoc}
${LicenseDataFragmentDoc}
${UserDataFragmentDoc}
${EmbedAddressDataFragmentDoc}
${ContactDataFragmentDoc}`;
export const OrganizationSaBasicDataFragmentDoc = gql`
    fragment OrganizationSaBasicData on OrganizationSa {
  id
  role
  userId
  name
  surname
  header
  footer
}
    `;
export const ProfessionalBasicDataFragmentDoc = gql`
    fragment ProfessionalBasicData on Professional {
  ... on Medic {
    ...MedicBasicData
  }
  ... on Assistant {
    ...AssistantBasicData
  }
  ... on Operator {
    ...OperatorBasicData
  }
  ... on OrganizationSa {
    ...OrganizationSaBasicData
  }
  user {
    ...UserData
  }
}
    ${MedicBasicDataFragmentDoc}
${AssistantBasicDataFragmentDoc}
${OperatorBasicDataFragmentDoc}
${OrganizationSaBasicDataFragmentDoc}
${UserDataFragmentDoc}`;
export const InviteDataFragmentDoc = gql`
    fragment InviteData on Invite {
  id
  inviteRole
  email
  type
  invitedUserId
  inviter {
    ...ProfessionalBasicData
  }
}
    ${ProfessionalBasicDataFragmentDoc}`;
export const InvoiceBasicDataFragmentDoc = gql`
    fragment InvoiceBasicData on Invoice {
  bollo
  createdAt
  date
  footer
  header
  id
  invoiceType
  noTs
  number
  patientId
  payedAt
  payedWith
  paymentInfo
  professionalId
  recipientAddress
  recipientAnag
  recipientLocation
  recipientTaxId
  serie
  taxId
  ts
  updatedAt
  vatId
  vatPercent
  withholdingTax
  year
  vatCode
}
    `;
export const InvoiceRowDataFragmentDoc = gql`
    fragment InvoiceRowData on InvoiceRow {
  amount
  createdAt
  description
  id
  invoiceId
  isNote
  sequence
  updatedAt
}
    `;
export const InvoiceDataFragmentDoc = gql`
    fragment InvoiceData on Invoice {
  ...InvoiceBasicData
  invoiceRows {
    ...InvoiceRowData
  }
}
    ${InvoiceBasicDataFragmentDoc}
${InvoiceRowDataFragmentDoc}`;
export const InvoiceOptionsDataFragmentDoc = gql`
    fragment InvoiceOptionsData on InvoiceOptions {
  defaultInvoiceType
  defaultPaymentInfo
  defaultSerie
  defaultVatCode
  professionalId
  taxId
  vatId
}
    `;
export const InvoiceVatCodeDataFragmentDoc = gql`
    fragment InvoiceVatCodeData on InvoiceVatCode {
  description
  id
  name
  percentage
}
    `;
export const InvoicePaymentTypeDataFragmentDoc = gql`
    fragment InvoicePaymentTypeData on InvoicePaymentType {
  description
  id
  name
}
    `;
export const StripePriceDataFragmentDoc = gql`
    fragment StripePriceData on StripePrice {
  id
  productId
  amount
}
    `;
export const StripeProductDataFragmentDoc = gql`
    fragment StripeProductData on StripeProduct {
  code
  id
  monthlyId
  yearlyId
  monthly {
    ...StripePriceData
  }
  yearly {
    ...StripePriceData
  }
}
    ${StripePriceDataFragmentDoc}`;
export const LicenseTypeDataFragmentDoc = gql`
    fragment LicenseTypeData on LicenseType {
  code
  name
  description
  stripeProduct {
    ...StripeProductData
  }
}
    ${StripeProductDataFragmentDoc}`;
export const NotificationDataFragmentDoc = gql`
    fragment NotificationData on Notification {
  id
  userId
  readAt
  createdAt
  data
  name
  destinationProfile
}
    `;
export const OrganizationDataFragmentDoc = gql`
    fragment OrganizationData on Organization {
  id
  name
  serviceAccount {
    ...OrganizationSaBasicData
  }
}
    ${OrganizationSaBasicDataFragmentDoc}`;
export const OrganizationProfessionalDataFragmentDoc = gql`
    fragment OrganizationProfessionalData on OrganizationProfessional {
  id
  role
  organization {
    ...OrganizationData
  }
}
    ${OrganizationDataFragmentDoc}`;
export const PaginationInfoDataFragmentDoc = gql`
    fragment PaginationInfoData on PaginationInfo {
  hasMore
  total
}
    `;
export const PatientPaginatedResponseDataFragmentDoc = gql`
    fragment PatientPaginatedResponseData on PatientPaginatedResponse {
  items {
    ...PatientData
  }
  paginationInfo {
    ...PaginationInfoData
  }
}
    ${PatientDataFragmentDoc}
${PaginationInfoDataFragmentDoc}`;
export const PatientProfessionalPaginatedResponseDataFragmentDoc = gql`
    fragment PatientProfessionalPaginatedResponseData on PatientProfessionalPaginatedResponse {
  items {
    ...PatientProfessionalData
  }
  paginationInfo {
    ...PaginationInfoData
  }
}
    ${PatientProfessionalDataFragmentDoc}
${PaginationInfoDataFragmentDoc}`;
export const SpecializationDataFragmentDoc = gql`
    fragment SpecializationData on Specialization {
  id
  name
  createdAt
}
    `;
export const ClinicDataFragmentDoc = gql`
    fragment ClinicData on Clinic {
  id
  createdAt
  updatedAt
  name
  phone
  rooms {
    ...ClinicRoomData
  }
  address {
    ...EmbedAddressData
  }
}
    ${ClinicRoomDataFragmentDoc}
${EmbedAddressDataFragmentDoc}`;
export const NetworkDataFragmentDoc = gql`
    fragment NetworkData on Network {
  id
  createdAt
  updatedAt
  canImpersonate
  status
  inverse {
    ...ProfessionalBasicData
  }
}
    ${ProfessionalBasicDataFragmentDoc}`;
export const MedicDataFragmentDoc = gql`
    fragment MedicData on Medic {
  ...MedicBasicData
  specializations {
    ...SpecializationData
  }
  provisions {
    ...ProfessionalProvisionData
  }
  clinics {
    ...ClinicData
  }
  networks {
    ...NetworkData
  }
}
    ${MedicBasicDataFragmentDoc}
${SpecializationDataFragmentDoc}
${ProfessionalProvisionDataFragmentDoc}
${ClinicDataFragmentDoc}
${NetworkDataFragmentDoc}`;
export const AssistantDataFragmentDoc = gql`
    fragment AssistantData on Assistant {
  ...AssistantBasicData
  specializations {
    ...SpecializationData
  }
  provisions {
    ...ProfessionalProvisionData
  }
  clinics {
    ...ClinicData
  }
  networks {
    ...NetworkData
  }
}
    ${AssistantBasicDataFragmentDoc}
${SpecializationDataFragmentDoc}
${ProfessionalProvisionDataFragmentDoc}
${ClinicDataFragmentDoc}
${NetworkDataFragmentDoc}`;
export const OperatorDataFragmentDoc = gql`
    fragment OperatorData on Operator {
  ...OperatorBasicData
  specializations {
    ...SpecializationData
  }
  provisions {
    ...ProfessionalProvisionData
  }
  clinics {
    ...ClinicData
  }
  networks {
    ...NetworkData
  }
}
    ${OperatorBasicDataFragmentDoc}
${SpecializationDataFragmentDoc}
${ProfessionalProvisionDataFragmentDoc}
${ClinicDataFragmentDoc}
${NetworkDataFragmentDoc}`;
export const OrganizationProfessionalPersonalDataFragmentDoc = gql`
    fragment OrganizationProfessionalPersonalData on OrganizationProfessional {
  id
  role
  professional {
    ...ProfessionalBasicData
  }
}
    ${ProfessionalBasicDataFragmentDoc}`;
export const OrganizationProfessionalsDataFragmentDoc = gql`
    fragment OrganizationProfessionalsData on Organization {
  id
  professionals {
    ...OrganizationProfessionalPersonalData
  }
}
    ${OrganizationProfessionalPersonalDataFragmentDoc}`;
export const OrganizationSaDataFragmentDoc = gql`
    fragment OrganizationSaData on OrganizationSa {
  ...OrganizationSaBasicData
  clinics {
    ...ClinicData
  }
  networks {
    ...NetworkData
  }
  managedOrganization {
    ...OrganizationProfessionalsData
  }
  provisions {
    ...ProfessionalProvisionData
  }
  privacyPolicy {
    ...AttachmentData
  }
}
    ${OrganizationSaBasicDataFragmentDoc}
${ClinicDataFragmentDoc}
${NetworkDataFragmentDoc}
${OrganizationProfessionalsDataFragmentDoc}
${ProfessionalProvisionDataFragmentDoc}
${AttachmentDataFragmentDoc}`;
export const ProfessionalDataFragmentDoc = gql`
    fragment ProfessionalData on Professional {
  user {
    ...UserData
  }
  ... on Medic {
    ...MedicData
  }
  ... on Assistant {
    ...AssistantData
  }
  ... on Operator {
    ...OperatorData
  }
  ... on OrganizationSa {
    ...OrganizationSaData
  }
}
    ${UserDataFragmentDoc}
${MedicDataFragmentDoc}
${AssistantDataFragmentDoc}
${OperatorDataFragmentDoc}
${OrganizationSaDataFragmentDoc}`;
export const SearchProfessionalDataFragmentDoc = gql`
    fragment SearchProfessionalData on Professional {
  ... on Medic {
    ...MedicBasicData
    specializations {
      ...SpecializationData
    }
  }
  ... on Assistant {
    ...AssistantBasicData
  }
  ... on Operator {
    ...OperatorBasicData
    specializations {
      ...SpecializationData
    }
  }
  ... on OrganizationSa {
    ...OrganizationSaBasicData
  }
  user {
    ...UserData
  }
}
    ${MedicBasicDataFragmentDoc}
${SpecializationDataFragmentDoc}
${AssistantBasicDataFragmentDoc}
${OperatorBasicDataFragmentDoc}
${OrganizationSaBasicDataFragmentDoc}
${UserDataFragmentDoc}`;
export const DefaultProvisionDataFragmentDoc = gql`
    fragment DefaultProvisionData on Provision {
  ...ProvisionData
  mandatory
}
    ${ProvisionDataFragmentDoc}`;
export const ReportBasicDataFragmentDoc = gql`
    fragment ReportBasicData on Report {
  id
  title
  description
  createdBy
  createdAt
  medicName
  professionalId
  reportDate
  attachments {
    ...AttachmentData
  }
}
    ${AttachmentDataFragmentDoc}`;
export const ReportSharedDataFragmentDoc = gql`
    fragment ReportSharedData on Report {
  ...ReportBasicData
  sharedWith {
    id
    professionalId
  }
  patient {
    ...PatientData
  }
  professional {
    ...ProfessionalBasicData
  }
}
    ${ReportBasicDataFragmentDoc}
${PatientDataFragmentDoc}
${ProfessionalBasicDataFragmentDoc}`;
export const RequestAuthorDataFragmentDoc = gql`
    fragment RequestAuthorData on RequestAuthor {
  ... on Patient {
    id
    name
    surname
    taxId
  }
  ... on Medic {
    id
    role
    name
    surname
    user {
      ...UserData
    }
  }
  ... on Operator {
    id
    name
    surname
    role
    user {
      ...UserData
    }
  }
  ... on OrganizationSa {
    id
    name
    surname
    role
  }
}
    ${UserDataFragmentDoc}`;
export const RequestMessageDataFragmentDoc = gql`
    fragment RequestMessageData on RequestMessage {
  id
  message
  readAt
  creator {
    ...UserData
  }
  author {
    ...RequestAuthorData
  }
  attachments {
    ...AttachmentData
  }
  createdAt
}
    ${UserDataFragmentDoc}
${RequestAuthorDataFragmentDoc}
${AttachmentDataFragmentDoc}`;
export const RequestDataFragmentDoc = gql`
    fragment RequestData on Request {
  id
  title
  type
  result
  private
  createdAt
  messages {
    ...RequestMessageData
  }
  recipient {
    ...RequestAuthorData
  }
  sender {
    ...RequestAuthorData
  }
}
    ${RequestMessageDataFragmentDoc}
${RequestAuthorDataFragmentDoc}`;
export const UserEmailAddressDataFragmentDoc = gql`
    fragment UserEmailAddressData on UserEmailAddress {
  id
  value
  verified
  via
  status
}
    `;
export const CreateAgendaListDocument = gql`
    mutation CreateAgendaList($medicId: String!, $agendaList: CreateAgendaListInput!, $numberOfWeeks: Int = 0) {
  createAgendaList(
    medicId: $medicId
    agendaList: $agendaList
    numberOfWeeks: $numberOfWeeks
  ) {
    ...AgendaListData
  }
}
    ${AgendaListDataFragmentDoc}`;
export type CreateAgendaListMutationResult = ApolloReactCommon.MutationResult<CreateAgendaListMutation>;
export type CreateAgendaListMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAgendaListMutation, CreateAgendaListMutationVariables>;
export const UpdateAgendaListDocument = gql`
    mutation UpdateAgendaList($id: String!, $agendaList: UpdateAgendaListInput!) {
  updateAgendaList(id: $id, agendaList: $agendaList) {
    ...AgendaListData
  }
}
    ${AgendaListDataFragmentDoc}`;
export type UpdateAgendaListMutationResult = ApolloReactCommon.MutationResult<UpdateAgendaListMutation>;
export type UpdateAgendaListMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAgendaListMutation, UpdateAgendaListMutationVariables>;
export const DeleteAgendaListDocument = gql`
    mutation DeleteAgendaList($id: String!) {
  deleteAgendaList(id: $id)
}
    `;
export type DeleteAgendaListMutationResult = ApolloReactCommon.MutationResult<DeleteAgendaListMutation>;
export type DeleteAgendaListMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAgendaListMutation, DeleteAgendaListMutationVariables>;
export const AddWeekDocument = gql`
    mutation AddWeek($agendaListId: String!, $order: Float!) {
  createAgendaWeek(agendaId: $agendaListId, order: $order) {
    ...AgendaWeekData
  }
}
    ${AgendaWeekDataFragmentDoc}`;
export type AddWeekMutationResult = ApolloReactCommon.MutationResult<AddWeekMutation>;
export type AddWeekMutationOptions = ApolloReactCommon.BaseMutationOptions<AddWeekMutation, AddWeekMutationVariables>;
export const UpdateWeekDocument = gql`
    mutation UpdateWeek($id: String!, $order: Float!) {
  updateAgendaWeek(id: $id, order: $order) {
    ...AgendaWeekData
  }
}
    ${AgendaWeekDataFragmentDoc}`;
export type UpdateWeekMutationResult = ApolloReactCommon.MutationResult<UpdateWeekMutation>;
export type UpdateWeekMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWeekMutation, UpdateWeekMutationVariables>;
export const DeleteWeekDocument = gql`
    mutation DeleteWeek($id: String!) {
  deleteAgendaWeek(id: $id)
}
    `;
export type DeleteWeekMutationResult = ApolloReactCommon.MutationResult<DeleteWeekMutation>;
export type DeleteWeekMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteWeekMutation, DeleteWeekMutationVariables>;
export const CreateAgendaEntryDocument = gql`
    mutation CreateAgendaEntry($weekId: String!, $agendaEntry: CreateAgendaEntryInput!) {
  createAgendaEntry(weekId: $weekId, agendaEntry: $agendaEntry) {
    ...AgendaEntryData
  }
}
    ${AgendaEntryDataFragmentDoc}`;
export type CreateAgendaEntryMutationResult = ApolloReactCommon.MutationResult<CreateAgendaEntryMutation>;
export type CreateAgendaEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAgendaEntryMutation, CreateAgendaEntryMutationVariables>;
export const UpdateAgendaEntryDocument = gql`
    mutation UpdateAgendaEntry($id: String!, $agendaEntry: UpdateAgendaEntryInput!) {
  updateAgendaEntry(id: $id, agendaEntry: $agendaEntry) {
    ...AgendaEntryData
  }
}
    ${AgendaEntryDataFragmentDoc}`;
export type UpdateAgendaEntryMutationResult = ApolloReactCommon.MutationResult<UpdateAgendaEntryMutation>;
export type UpdateAgendaEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAgendaEntryMutation, UpdateAgendaEntryMutationVariables>;
export const DeleteAgendaEntryDocument = gql`
    mutation DeleteAgendaEntry($id: String!) {
  deleteAgendaEntry(id: $id)
}
    `;
export type DeleteAgendaEntryMutationResult = ApolloReactCommon.MutationResult<DeleteAgendaEntryMutation>;
export type DeleteAgendaEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAgendaEntryMutation, DeleteAgendaEntryMutationVariables>;
export const CreateManyAgendaEntriesDocument = gql`
    mutation CreateManyAgendaEntries($weekId: String!, $agendaEntries: [CreateAgendaEntryInput!]!) {
  createManyAgendaEntry(weekId: $weekId, agendaEntries: $agendaEntries) {
    ...AgendaEntryData
  }
}
    ${AgendaEntryDataFragmentDoc}`;
export type CreateManyAgendaEntriesMutationResult = ApolloReactCommon.MutationResult<CreateManyAgendaEntriesMutation>;
export type CreateManyAgendaEntriesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateManyAgendaEntriesMutation, CreateManyAgendaEntriesMutationVariables>;
export const OverrideDayDocument = gql`
    mutation OverrideDay($day: Date!, $medicId: String!) {
  overrideDay(medicId: $medicId, date: $day) {
    ...AgendaEntryInDayData
  }
}
    ${AgendaEntryInDayDataFragmentDoc}`;
export type OverrideDayMutationResult = ApolloReactCommon.MutationResult<OverrideDayMutation>;
export type OverrideDayMutationOptions = ApolloReactCommon.BaseMutationOptions<OverrideDayMutation, OverrideDayMutationVariables>;
export const CreateOverrideEntryDocument = gql`
    mutation CreateOverrideEntry($date: Date!, $agendaEntry: CreateAgendaEntryInput!, $medicId: String!) {
  createOverrideEntry(date: $date, agendaEntry: $agendaEntry, medicId: $medicId) {
    ...AgendaEntryInDayData
  }
}
    ${AgendaEntryInDayDataFragmentDoc}`;
export type CreateOverrideEntryMutationResult = ApolloReactCommon.MutationResult<CreateOverrideEntryMutation>;
export type CreateOverrideEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOverrideEntryMutation, CreateOverrideEntryMutationVariables>;
export const ResetDayDocument = gql`
    mutation ResetDay($day: Date!, $medicId: String!) {
  resetDay(date: $day, medicId: $medicId) {
    ...AgendaEntryInDayData
  }
}
    ${AgendaEntryInDayDataFragmentDoc}`;
export type ResetDayMutationResult = ApolloReactCommon.MutationResult<ResetDayMutation>;
export type ResetDayMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetDayMutation, ResetDayMutationVariables>;
export const CreateBreakDocument = gql`
    mutation CreateBreak($medicId: String!, $break: CreateBreakInput!) {
  createBreak(medicId: $medicId, break: $break) {
    ...BreakData
  }
}
    ${BreakDataFragmentDoc}`;
export type CreateBreakMutationResult = ApolloReactCommon.MutationResult<CreateBreakMutation>;
export type CreateBreakMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBreakMutation, CreateBreakMutationVariables>;
export const CreateAppointmentDocument = gql`
    mutation CreateAppointment($medicId: String!, $appointment: CreateAppointmentInput!) {
  createAppointment(medicId: $medicId, appointment: $appointment) {
    ...AppointmentData
  }
}
    ${AppointmentDataFragmentDoc}`;
export type CreateAppointmentMutationResult = ApolloReactCommon.MutationResult<CreateAppointmentMutation>;
export type CreateAppointmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>;
export const UpdateBreakDocument = gql`
    mutation UpdateBreak($id: String!, $break: UpdateBreakInput!) {
  updateBreak(id: $id, break: $break) {
    ...BreakData
  }
}
    ${BreakDataFragmentDoc}`;
export type UpdateBreakMutationResult = ApolloReactCommon.MutationResult<UpdateBreakMutation>;
export type UpdateBreakMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBreakMutation, UpdateBreakMutationVariables>;
export const UpdateAppointmentDocument = gql`
    mutation UpdateAppointment($id: String!, $appointment: UpdateAppointmentInput!) {
  updateAppointment(id: $id, appointment: $appointment) {
    ...AppointmentData
  }
}
    ${AppointmentDataFragmentDoc}`;
export type UpdateAppointmentMutationResult = ApolloReactCommon.MutationResult<UpdateAppointmentMutation>;
export type UpdateAppointmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>;
export const DeleteBreakDocument = gql`
    mutation DeleteBreak($id: String!) {
  deleteBreak(id: $id)
}
    `;
export type DeleteBreakMutationResult = ApolloReactCommon.MutationResult<DeleteBreakMutation>;
export type DeleteBreakMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBreakMutation, DeleteBreakMutationVariables>;
export const DeleteAppointmentDocument = gql`
    mutation DeleteAppointment($id: String!) {
  deleteAppointment(id: $id)
}
    `;
export type DeleteAppointmentMutationResult = ApolloReactCommon.MutationResult<DeleteAppointmentMutation>;
export type DeleteAppointmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAppointmentMutation, DeleteAppointmentMutationVariables>;
export const CopyAgendaListDocument = gql`
    mutation CopyAgendaList($id: String!, $agendaList: CopyAgendaListInput!) {
  copyAgendaList(agendaList: $agendaList, id: $id) {
    ...AgendaListData
  }
}
    ${AgendaListDataFragmentDoc}`;
export type CopyAgendaListMutationResult = ApolloReactCommon.MutationResult<CopyAgendaListMutation>;
export type CopyAgendaListMutationOptions = ApolloReactCommon.BaseMutationOptions<CopyAgendaListMutation, CopyAgendaListMutationVariables>;
export const AssignMedicToEntryDocument = gql`
    mutation AssignMedicToEntry($medicId: String!, $id: String!) {
  assignMedicToEntry(medicId: $medicId, id: $id) {
    ...AgendaEntryData
  }
}
    ${AgendaEntryDataFragmentDoc}`;
export type AssignMedicToEntryMutationResult = ApolloReactCommon.MutationResult<AssignMedicToEntryMutation>;
export type AssignMedicToEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignMedicToEntryMutation, AssignMedicToEntryMutationVariables>;
export const DissociateFromEntryDocument = gql`
    mutation DissociateFromEntry($id: String!) {
  dissociateFromEntry(id: $id)
}
    `;
export type DissociateFromEntryMutationResult = ApolloReactCommon.MutationResult<DissociateFromEntryMutation>;
export type DissociateFromEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<DissociateFromEntryMutation, DissociateFromEntryMutationVariables>;
export const ChatAccessTokenDocument = gql`
    mutation ChatAccessToken {
  getChatAccessToken
}
    `;
export type ChatAccessTokenMutationResult = ApolloReactCommon.MutationResult<ChatAccessTokenMutation>;
export type ChatAccessTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<ChatAccessTokenMutation, ChatAccessTokenMutationVariables>;
export const StartChatDocument = gql`
    mutation StartChat($userId: ID!) {
  createChat(userId: $userId)
}
    `;
export type StartChatMutationResult = ApolloReactCommon.MutationResult<StartChatMutation>;
export type StartChatMutationOptions = ApolloReactCommon.BaseMutationOptions<StartChatMutation, StartChatMutationVariables>;
export const CreateClinicDocument = gql`
    mutation CreateClinic($medicId: String!, $clinic: CreateClinicInput!) {
  createClinic(clinic: $clinic, medicId: $medicId) {
    ...ClinicData
  }
}
    ${ClinicDataFragmentDoc}`;
export type CreateClinicMutationResult = ApolloReactCommon.MutationResult<CreateClinicMutation>;
export type CreateClinicMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateClinicMutation, CreateClinicMutationVariables>;
export const UpdateClinicDocument = gql`
    mutation UpdateClinic($id: String!, $clinic: UpdateClinicInput!) {
  updateClinic(id: $id, clinic: $clinic) {
    ...ClinicData
  }
}
    ${ClinicDataFragmentDoc}`;
export type UpdateClinicMutationResult = ApolloReactCommon.MutationResult<UpdateClinicMutation>;
export type UpdateClinicMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClinicMutation, UpdateClinicMutationVariables>;
export const DeleteClinicDocument = gql`
    mutation DeleteClinic($id: String!) {
  deleteClinic(id: $id)
}
    `;
export type DeleteClinicMutationResult = ApolloReactCommon.MutationResult<DeleteClinicMutation>;
export type DeleteClinicMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteClinicMutation, DeleteClinicMutationVariables>;
export const CreateClinicRoomDocument = gql`
    mutation CreateClinicRoom($clinicId: String!, $room: CreateClinicRoomInput!) {
  createClinicRoom(clinicId: $clinicId, room: $room) {
    ...ClinicRoomData
  }
}
    ${ClinicRoomDataFragmentDoc}`;
export type CreateClinicRoomMutationResult = ApolloReactCommon.MutationResult<CreateClinicRoomMutation>;
export type CreateClinicRoomMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateClinicRoomMutation, CreateClinicRoomMutationVariables>;
export const UpdateClinicRoomDocument = gql`
    mutation UpdateClinicRoom($id: String!, $room: UpdateClinicRoomInput!) {
  updateClinicRoom(id: $id, room: $room) {
    ...ClinicRoomData
  }
}
    ${ClinicRoomDataFragmentDoc}`;
export type UpdateClinicRoomMutationResult = ApolloReactCommon.MutationResult<UpdateClinicRoomMutation>;
export type UpdateClinicRoomMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClinicRoomMutation, UpdateClinicRoomMutationVariables>;
export const DeleteClinicRoomDocument = gql`
    mutation DeleteClinicRoom($id: String!) {
  deleteClinicRoom(id: $id)
}
    `;
export type DeleteClinicRoomMutationResult = ApolloReactCommon.MutationResult<DeleteClinicRoomMutation>;
export type DeleteClinicRoomMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteClinicRoomMutation, DeleteClinicRoomMutationVariables>;
export const InvitePatientDocument = gql`
    mutation InvitePatient($medicId: String!, $email: String!, $patientProfessionalId: String!) {
  invitePatient(
    medicId: $medicId
    email: $email
    patientProfessionalId: $patientProfessionalId
  ) {
    id
  }
}
    `;
export type InvitePatientMutationResult = ApolloReactCommon.MutationResult<InvitePatientMutation>;
export type InvitePatientMutationOptions = ApolloReactCommon.BaseMutationOptions<InvitePatientMutation, InvitePatientMutationVariables>;
export const InviteAssistantDocument = gql`
    mutation InviteAssistant($medicId: String!, $email: String!) {
  inviteAssistant(medicId: $medicId, user: {email: $email}) {
    id
  }
}
    `;
export type InviteAssistantMutationResult = ApolloReactCommon.MutationResult<InviteAssistantMutation>;
export type InviteAssistantMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteAssistantMutation, InviteAssistantMutationVariables>;
export const InviteToOrganizationDocument = gql`
    mutation InviteToOrganization($orgId: String!, $role: String!, $email: String!) {
  inviteToOrganization(organizationId: $orgId, role: $role, user: {email: $email}) {
    id
  }
}
    `;
export type InviteToOrganizationMutationResult = ApolloReactCommon.MutationResult<InviteToOrganizationMutation>;
export type InviteToOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteToOrganizationMutation, InviteToOrganizationMutationVariables>;
export const AcceptAssistantInviteDocument = gql`
    mutation AcceptAssistantInvite($id: String!, $tos: Boolean!) {
  acceptAssistantInvite(id: $id, assistantTermsAndConditions: $tos) {
    ... on Assistant {
      ...AssistantBasicData
    }
  }
}
    ${AssistantBasicDataFragmentDoc}`;
export type AcceptAssistantInviteMutationResult = ApolloReactCommon.MutationResult<AcceptAssistantInviteMutation>;
export type AcceptAssistantInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptAssistantInviteMutation, AcceptAssistantInviteMutationVariables>;
export const AcceptOrganizationInviteDocument = gql`
    mutation AcceptOrganizationInvite($id: String!, $medicId: String!) {
  acceptOrganizationInvite(id: $id, medicId: $medicId) {
    id
  }
}
    `;
export type AcceptOrganizationInviteMutationResult = ApolloReactCommon.MutationResult<AcceptOrganizationInviteMutation>;
export type AcceptOrganizationInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptOrganizationInviteMutation, AcceptOrganizationInviteMutationVariables>;
export const DeleteInviteDocument = gql`
    mutation DeleteInvite($id: String!) {
  deleteInvite(id: $id)
}
    `;
export type DeleteInviteMutationResult = ApolloReactCommon.MutationResult<DeleteInviteMutation>;
export type DeleteInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteInviteMutation, DeleteInviteMutationVariables>;
export const UpsertInvoiceOptionsDocument = gql`
    mutation UpsertInvoiceOptions($data: InvoiceOptionsInput!) {
  createOrUpdateInvoiceOptions(input: $data) {
    ...InvoiceOptionsData
  }
}
    ${InvoiceOptionsDataFragmentDoc}`;
export type UpsertInvoiceOptionsMutationResult = ApolloReactCommon.MutationResult<UpsertInvoiceOptionsMutation>;
export type UpsertInvoiceOptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertInvoiceOptionsMutation, UpsertInvoiceOptionsMutationVariables>;
export const UpsertInvoiceDocument = gql`
    mutation UpsertInvoice($data: InvoiceInput!) {
  createOrUpdateInvoice(input: $data) {
    ...InvoiceData
  }
}
    ${InvoiceDataFragmentDoc}`;
export type UpsertInvoiceMutationResult = ApolloReactCommon.MutationResult<UpsertInvoiceMutation>;
export type UpsertInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertInvoiceMutation, UpsertInvoiceMutationVariables>;
export const UpsertInvoiceRowDocument = gql`
    mutation UpsertInvoiceRow($data: InvoiceRowInput) {
  createOrUpdateInvoiceRow(input: $data) {
    ...InvoiceRowData
  }
}
    ${InvoiceRowDataFragmentDoc}`;
export type UpsertInvoiceRowMutationResult = ApolloReactCommon.MutationResult<UpsertInvoiceRowMutation>;
export type UpsertInvoiceRowMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertInvoiceRowMutation, UpsertInvoiceRowMutationVariables>;
export const DeleteInvoiceRowDocument = gql`
    mutation DeleteInvoiceRow($id: Int!) {
  deleteInvoiceRow(id: $id) {
    id
  }
}
    `;
export type DeleteInvoiceRowMutationResult = ApolloReactCommon.MutationResult<DeleteInvoiceRowMutation>;
export type DeleteInvoiceRowMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteInvoiceRowMutation, DeleteInvoiceRowMutationVariables>;
export const DeleteInvoiceDocument = gql`
    mutation DeleteInvoice($id: Int!) {
  deleteInvoice(id: $id) {
    id
  }
}
    `;
export type DeleteInvoiceMutationResult = ApolloReactCommon.MutationResult<DeleteInvoiceMutation>;
export type DeleteInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>;
export const UpdateMedicProfileDocument = gql`
    mutation UpdateMedicProfile($medic: UpdateMedicInput!) {
  updateMedic(medic: $medic) {
    ...MedicBasicData
  }
}
    ${MedicBasicDataFragmentDoc}`;
export type UpdateMedicProfileMutationResult = ApolloReactCommon.MutationResult<UpdateMedicProfileMutation>;
export type UpdateMedicProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMedicProfileMutation, UpdateMedicProfileMutationVariables>;
export const AddSpecializationToMedicDocument = gql`
    mutation AddSpecializationToMedic($specializationId: String!) {
  addSpecializationToMedic(id: $specializationId) {
    ...SpecializationData
  }
}
    ${SpecializationDataFragmentDoc}`;
export type AddSpecializationToMedicMutationResult = ApolloReactCommon.MutationResult<AddSpecializationToMedicMutation>;
export type AddSpecializationToMedicMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSpecializationToMedicMutation, AddSpecializationToMedicMutationVariables>;
export const RemoveSpecializationFromMedicDocument = gql`
    mutation RemoveSpecializationFromMedic($specializationId: String!) {
  removeSpecializationFromMedic(id: $specializationId)
}
    `;
export type RemoveSpecializationFromMedicMutationResult = ApolloReactCommon.MutationResult<RemoveSpecializationFromMedicMutation>;
export type RemoveSpecializationFromMedicMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveSpecializationFromMedicMutation, RemoveSpecializationFromMedicMutationVariables>;
export const CreateProfessionalProvisionDocument = gql`
    mutation CreateProfessionalProvision($medicId: String!, $provision: CreateProfessionalProvisionInput!) {
  createProfessionalProvision(id: $medicId, provision: $provision) {
    ...ProfessionalProvisionData
  }
}
    ${ProfessionalProvisionDataFragmentDoc}`;
export type CreateProfessionalProvisionMutationResult = ApolloReactCommon.MutationResult<CreateProfessionalProvisionMutation>;
export type CreateProfessionalProvisionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProfessionalProvisionMutation, CreateProfessionalProvisionMutationVariables>;
export const UpdateProfessionalProvisionDocument = gql`
    mutation UpdateProfessionalProvision($id: String!, $provision: UpdateProfessionalProvisionInput!) {
  updateProfessionalProvision(id: $id, provision: $provision) {
    ...ProfessionalProvisionData
  }
}
    ${ProfessionalProvisionDataFragmentDoc}`;
export type UpdateProfessionalProvisionMutationResult = ApolloReactCommon.MutationResult<UpdateProfessionalProvisionMutation>;
export type UpdateProfessionalProvisionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProfessionalProvisionMutation, UpdateProfessionalProvisionMutationVariables>;
export const DeleteProfessionalProvisionDocument = gql`
    mutation DeleteProfessionalProvision($id: String!) {
  deleteProfessionalProvision(id: $id)
}
    `;
export type DeleteProfessionalProvisionMutationResult = ApolloReactCommon.MutationResult<DeleteProfessionalProvisionMutation>;
export type DeleteProfessionalProvisionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProfessionalProvisionMutation, DeleteProfessionalProvisionMutationVariables>;
export const CreateMedicDocument = gql`
    mutation CreateMedic($medic: CreateMedicInput!) {
  createMedic(medic: $medic) {
    ...MedicData
  }
}
    ${MedicDataFragmentDoc}`;
export type CreateMedicMutationResult = ApolloReactCommon.MutationResult<CreateMedicMutation>;
export type CreateMedicMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMedicMutation, CreateMedicMutationVariables>;
export const SendMailDocument = gql`
    mutation SendMail($medicId: String!, $to: [String!]!, $title: String!, $body: String!, $attachments: [Upload!]) {
  sendMail(
    medicId: $medicId
    to: $to
    title: $title
    body: $body
    attachments: $attachments
  )
}
    `;
export type SendMailMutationResult = ApolloReactCommon.MutationResult<SendMailMutation>;
export type SendMailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendMailMutation, SendMailMutationVariables>;
export const SendMassiveEmailDocument = gql`
    mutation SendMassiveEmail($medicId: String!, $target: MassiveEmailTarget!, $title: String!, $body: String!, $attachments: [Upload!]) {
  sendMassiveEmail(
    medicId: $medicId
    target: $target
    title: $title
    body: $body
    attachments: $attachments
  )
}
    `;
export type SendMassiveEmailMutationResult = ApolloReactCommon.MutationResult<SendMassiveEmailMutation>;
export type SendMassiveEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendMassiveEmailMutation, SendMassiveEmailMutationVariables>;
export const UploadPrivacyDocument = gql`
    mutation UploadPrivacy($medicId: String!, $file: CreateAttachmentInput, $link: String) {
  uploadPrivacyPolicy(medicId: $medicId, file: $file, link: $link) {
    ...AttachmentData
  }
}
    ${AttachmentDataFragmentDoc}`;
export type UploadPrivacyMutationResult = ApolloReactCommon.MutationResult<UploadPrivacyMutation>;
export type UploadPrivacyMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadPrivacyMutation, UploadPrivacyMutationVariables>;
export const RequestConnectionDocument = gql`
    mutation RequestConnection($requestId: String!, $id: String!) {
  requestConnection(requestId: $requestId, id: $id) {
    ...NetworkData
  }
}
    ${NetworkDataFragmentDoc}`;
export type RequestConnectionMutationResult = ApolloReactCommon.MutationResult<RequestConnectionMutation>;
export type RequestConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestConnectionMutation, RequestConnectionMutationVariables>;
export const UpdateConnectionDocument = gql`
    mutation UpdateConnection($status: NetworkStatus!, $id: String!) {
  updateConnection(status: $status, id: $id) {
    ...NetworkData
  }
}
    ${NetworkDataFragmentDoc}`;
export type UpdateConnectionMutationResult = ApolloReactCommon.MutationResult<UpdateConnectionMutation>;
export type UpdateConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateConnectionMutation, UpdateConnectionMutationVariables>;
export const DeleteConnectionDocument = gql`
    mutation DeleteConnection($id: String!) {
  deleteConnection(id: $id)
}
    `;
export type DeleteConnectionMutationResult = ApolloReactCommon.MutationResult<DeleteConnectionMutation>;
export type DeleteConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteConnectionMutation, DeleteConnectionMutationVariables>;
export const ReadNotificationDocument = gql`
    mutation ReadNotification($id: String!) {
  markNotificationAsRead(id: $id) {
    ...NotificationData
  }
}
    ${NotificationDataFragmentDoc}`;
export type ReadNotificationMutationResult = ApolloReactCommon.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>;
export const ReadManyNotificationsDocument = gql`
    mutation ReadManyNotifications($ids: [String!]!) {
  markManyNotificationsAsRead(ids: $ids) {
    ...NotificationData
  }
}
    ${NotificationDataFragmentDoc}`;
export type ReadManyNotificationsMutationResult = ApolloReactCommon.MutationResult<ReadManyNotificationsMutation>;
export type ReadManyNotificationsMutationOptions = ApolloReactCommon.BaseMutationOptions<ReadManyNotificationsMutation, ReadManyNotificationsMutationVariables>;
export const CreateOperatorDocument = gql`
    mutation CreateOperator($operator: CreateOperatorInput!) {
  createOperator(operator: $operator) {
    ...OperatorData
  }
}
    ${OperatorDataFragmentDoc}`;
export type CreateOperatorMutationResult = ApolloReactCommon.MutationResult<CreateOperatorMutation>;
export type CreateOperatorMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOperatorMutation, CreateOperatorMutationVariables>;
export const UpdateOperatorProfileDocument = gql`
    mutation UpdateOperatorProfile($operator: UpdateOperatorInput!) {
  updateOperator(operator: $operator) {
    ...OperatorBasicData
  }
}
    ${OperatorBasicDataFragmentDoc}`;
export type UpdateOperatorProfileMutationResult = ApolloReactCommon.MutationResult<UpdateOperatorProfileMutation>;
export type UpdateOperatorProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOperatorProfileMutation, UpdateOperatorProfileMutationVariables>;
export const CreatePatientProfessionalDocument = gql`
    mutation CreatePatientProfessional($patient: CreatePatientInput!, $patientProfessional: CreatePatientProfessionalInput!, $medicId: String!) {
  createPatientProfessional(
    patientProfessional: $patientProfessional
    patient: $patient
    medicId: $medicId
  ) {
    ...PatientProfessionalData
  }
}
    ${PatientProfessionalDataFragmentDoc}`;
export type CreatePatientProfessionalMutationResult = ApolloReactCommon.MutationResult<CreatePatientProfessionalMutation>;
export type CreatePatientProfessionalMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePatientProfessionalMutation, CreatePatientProfessionalMutationVariables>;
export const UpdatePatientProfessionalDocument = gql`
    mutation UpdatePatientProfessional($patient: UpdatePatientProfessionalInput!, $id: String!) {
  updatePatientProfessional(patient: $patient, id: $id) {
    ...PatientProfessionalData
  }
}
    ${PatientProfessionalDataFragmentDoc}`;
export type UpdatePatientProfessionalMutationResult = ApolloReactCommon.MutationResult<UpdatePatientProfessionalMutation>;
export type UpdatePatientProfessionalMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePatientProfessionalMutation, UpdatePatientProfessionalMutationVariables>;
export const ArchivePatientProfessionalDocument = gql`
    mutation ArchivePatientProfessional($id: String!) {
  archivePatientProfessional(id: $id) {
    ...PatientProfessionalData
  }
}
    ${PatientProfessionalDataFragmentDoc}`;
export type ArchivePatientProfessionalMutationResult = ApolloReactCommon.MutationResult<ArchivePatientProfessionalMutation>;
export type ArchivePatientProfessionalMutationOptions = ApolloReactCommon.BaseMutationOptions<ArchivePatientProfessionalMutation, ArchivePatientProfessionalMutationVariables>;
export const SharePatientWithProfessionalDocument = gql`
    mutation SharePatientWithProfessional($patientProfessionalId: String!, $medicId: String!, $orgId: String!) {
  sharePatientWithProfessional(
    patientProfessionalId: $patientProfessionalId
    medicId: $medicId
    orgId: $orgId
  )
}
    `;
export type SharePatientWithProfessionalMutationResult = ApolloReactCommon.MutationResult<SharePatientWithProfessionalMutation>;
export type SharePatientWithProfessionalMutationOptions = ApolloReactCommon.BaseMutationOptions<SharePatientWithProfessionalMutation, SharePatientWithProfessionalMutationVariables>;
export const RevokeSharePatientWithProfessionalDocument = gql`
    mutation RevokeSharePatientWithProfessional($patientProfessionalId: String!, $medicId: String!, $orgId: String!) {
  revokeSharePatientWithProfessional(
    patientProfessionalId: $patientProfessionalId
    medicId: $medicId
    orgId: $orgId
  )
}
    `;
export type RevokeSharePatientWithProfessionalMutationResult = ApolloReactCommon.MutationResult<RevokeSharePatientWithProfessionalMutation>;
export type RevokeSharePatientWithProfessionalMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokeSharePatientWithProfessionalMutation, RevokeSharePatientWithProfessionalMutationVariables>;
export const UpdatePatientProfessionalStatusDocument = gql`
    mutation UpdatePatientProfessionalStatus($id: String!, $medicId: String!, $status: UpdatablePatientProfessionalStatus!) {
  updatePatientProfessionalStatus(id: $id, medicId: $medicId, status: $status) {
    id
    status
  }
}
    `;
export type UpdatePatientProfessionalStatusMutationResult = ApolloReactCommon.MutationResult<UpdatePatientProfessionalStatusMutation>;
export type UpdatePatientProfessionalStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePatientProfessionalStatusMutation, UpdatePatientProfessionalStatusMutationVariables>;
export const CreateReportDocument = gql`
    mutation CreateReport($patientProfessionalId: String!, $report: CreateReportInput!) {
  createReportAsProfessional(
    patientProfessionalId: $patientProfessionalId
    report: $report
  ) {
    ...ReportSharedData
  }
}
    ${ReportSharedDataFragmentDoc}`;
export type CreateReportMutationResult = ApolloReactCommon.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReportMutation, CreateReportMutationVariables>;
export const DeleteReportDocument = gql`
    mutation DeleteReport($id: String!) {
  deleteReportAsProfessional(id: $id)
}
    `;
export type DeleteReportMutationResult = ApolloReactCommon.MutationResult<DeleteReportMutation>;
export type DeleteReportMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteReportMutation, DeleteReportMutationVariables>;
export const UpdateReportDocument = gql`
    mutation UpdateReport($id: String!, $report: UpdateReportInput!) {
  updateReportAsProfessional(id: $id, report: $report) {
    ...ReportSharedData
  }
}
    ${ReportSharedDataFragmentDoc}`;
export type UpdateReportMutationResult = ApolloReactCommon.MutationResult<UpdateReportMutation>;
export type UpdateReportMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateReportMutation, UpdateReportMutationVariables>;
export const ReadRequestMessageDocument = gql`
    mutation ReadRequestMessage($id: String!, $medicId: String!, $readAt: DateTime!) {
  markRequestMessageAsReadAsProfessional(
    id: $id
    medicId: $medicId
    readAt: $readAt
  ) {
    ...RequestMessageData
  }
}
    ${RequestMessageDataFragmentDoc}`;
export type ReadRequestMessageMutationResult = ApolloReactCommon.MutationResult<ReadRequestMessageMutation>;
export type ReadRequestMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<ReadRequestMessageMutation, ReadRequestMessageMutationVariables>;
export const AddRequestMessageDocument = gql`
    mutation AddRequestMessage($requestId: String!, $medicId: String!, $message: CreateRequestMessageInput!) {
  answerRequestAsProfessional(
    id: $requestId
    medicId: $medicId
    message: $message
  ) {
    ...RequestMessageData
  }
}
    ${RequestMessageDataFragmentDoc}`;
export type AddRequestMessageMutationResult = ApolloReactCommon.MutationResult<AddRequestMessageMutation>;
export type AddRequestMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<AddRequestMessageMutation, AddRequestMessageMutationVariables>;
export const ResolveRequestDocument = gql`
    mutation ResolveRequest($requestId: String!, $result: RequestResult!) {
  resolveRequest(id: $requestId, result: $result) {
    result
  }
}
    `;
export type ResolveRequestMutationResult = ApolloReactCommon.MutationResult<ResolveRequestMutation>;
export type ResolveRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<ResolveRequestMutation, ResolveRequestMutationVariables>;
export const CreateRequestDocument = gql`
    mutation CreateRequest($medicId: String!, $request: CreateRequestInput!) {
  createRequestAsProfessional(medicId: $medicId, request: $request) {
    ...RequestData
  }
}
    ${RequestDataFragmentDoc}`;
export type CreateRequestMutationResult = ApolloReactCommon.MutationResult<CreateRequestMutation>;
export type CreateRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRequestMutation, CreateRequestMutationVariables>;
export const DeleteRequestDocument = gql`
    mutation DeleteRequest($id: String!) {
  deleteRequest(id: $id)
}
    `;
export type DeleteRequestMutationResult = ApolloReactCommon.MutationResult<DeleteRequestMutation>;
export type DeleteRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRequestMutation, DeleteRequestMutationVariables>;
export const SearchCityDocument = gql`
    query SearchCity($name: String!) {
  cities(filter: {name: {like: $name}}, limit: 100) {
    items {
      ...CityData
    }
  }
}
    ${CityDataFragmentDoc}`;
export type SearchCityQueryResult = ApolloReactCommon.QueryResult<SearchCityQuery, SearchCityQueryVariables>;
export const SearchDistrictDocument = gql`
    query SearchDistrict($name: String!) {
  cities(filter: {name: {like: $name}, isDistrict: {eq: true}}, limit: 100) {
    items {
      ...CityData
    }
  }
}
    ${CityDataFragmentDoc}`;
export type SearchDistrictQueryResult = ApolloReactCommon.QueryResult<SearchDistrictQuery, SearchDistrictQueryVariables>;
export const GetCityDocument = gql`
    query GetCity($id: ID!) {
  city(id: $id) {
    ...CityData
  }
}
    ${CityDataFragmentDoc}`;
export type GetCityQueryResult = ApolloReactCommon.QueryResult<GetCityQuery, GetCityQueryVariables>;
export const AgendaEntriesByDayDocument = gql`
    query AgendaEntriesByDay($medicId: String!, $day: Date!) {
  agendaEntryByDay(day: $day, medicId: $medicId) {
    ...AgendaEntryInDayData
  }
}
    ${AgendaEntryInDayDataFragmentDoc}`;
export type AgendaEntriesByDayQueryResult = ApolloReactCommon.QueryResult<AgendaEntriesByDayQuery, AgendaEntriesByDayQueryVariables>;
export const AgendaEntriesInDaysDocument = gql`
    query AgendaEntriesInDays($medicId: String!, $from: Date!, $to: Date!) {
  agendaEntriesInDays(medicId: $medicId, from: $from, to: $to) {
    ...AgendaEntryInDayData
  }
}
    ${AgendaEntryInDayDataFragmentDoc}`;
export type AgendaEntriesInDaysQueryResult = ApolloReactCommon.QueryResult<AgendaEntriesInDaysQuery, AgendaEntriesInDaysQueryVariables>;
export const AgendaListsDocument = gql`
    query AgendaLists($medicId: String!) {
  agendaLists(medicId: $medicId) {
    ...AgendaListBasicData
  }
}
    ${AgendaListBasicDataFragmentDoc}`;
export type AgendaListsQueryResult = ApolloReactCommon.QueryResult<AgendaListsQuery, AgendaListsQueryVariables>;
export const AgendaListDocument = gql`
    query AgendaList($id: ID!) {
  agendaList(id: $id) {
    ...AgendaListData
  }
}
    ${AgendaListDataFragmentDoc}`;
export type AgendaListQueryResult = ApolloReactCommon.QueryResult<AgendaListQuery, AgendaListQueryVariables>;
export const AgendaEventsByDayDocument = gql`
    query AgendaEventsByDay($medicId: String!, $day: Date!, $clinicIds: [String!]) {
  agendaEventsByDay(medicId: $medicId, day: $day, clinicIds: $clinicIds) {
    ...AgendaEventData
  }
}
    ${AgendaEventDataFragmentDoc}`;
export type AgendaEventsByDayQueryResult = ApolloReactCommon.QueryResult<AgendaEventsByDayQuery, AgendaEventsByDayQueryVariables>;
export const AgendaEventsInDaysDocument = gql`
    query AgendaEventsInDays($medicId: String!, $from: Date!, $to: Date!, $clinicIds: [String!]) {
  agendaEventsInDays(
    medicId: $medicId
    from: $from
    to: $to
    clinicIds: $clinicIds
  ) {
    ...AgendaEventBasicData
  }
}
    ${AgendaEventBasicDataFragmentDoc}`;
export type AgendaEventsInDaysQueryResult = ApolloReactCommon.QueryResult<AgendaEventsInDaysQuery, AgendaEventsInDaysQueryVariables>;
export const AgendaEventDocument = gql`
    query AgendaEvent($id: ID!) {
  agendaEvent(id: $id) {
    ...AgendaEventData
  }
}
    ${AgendaEventDataFragmentDoc}`;
export type AgendaEventQueryResult = ApolloReactCommon.QueryResult<AgendaEventQuery, AgendaEventQueryVariables>;
export const AppointmentDocument = gql`
    query Appointment($id: ID!) {
  appointment(id: $id) {
    ...AppointmentData
  }
}
    ${AppointmentDataFragmentDoc}`;
export type AppointmentQueryResult = ApolloReactCommon.QueryResult<AppointmentQuery, AppointmentQueryVariables>;
export const AgendaEntriesAndEventsByDayDocument = gql`
    query AgendaEntriesAndEventsByDay($medicId: String!, $day: Date!) {
  agendaEntryByDay(medicId: $medicId, day: $day) {
    ...AgendaEntryInDayData
  }
  agendaEventsByDay(medicId: $medicId, day: $day) {
    ...AgendaEventData
  }
}
    ${AgendaEntryInDayDataFragmentDoc}
${AgendaEventDataFragmentDoc}`;
export type AgendaEntriesAndEventsByDayQueryResult = ApolloReactCommon.QueryResult<AgendaEntriesAndEventsByDayQuery, AgendaEntriesAndEventsByDayQueryVariables>;
export const AgendaEntriesAndEventsInDaysDocument = gql`
    query AgendaEntriesAndEventsInDays($medicId: String!, $from: Date!, $to: Date!) {
  agendaEntriesInDays(medicId: $medicId, from: $from, to: $to) {
    ...AgendaEntryInDayData
  }
  agendaEventsInDays(medicId: $medicId, from: $from, to: $to) {
    ...AgendaEventBasicData
  }
}
    ${AgendaEntryInDayDataFragmentDoc}
${AgendaEventBasicDataFragmentDoc}`;
export type AgendaEntriesAndEventsInDaysQueryResult = ApolloReactCommon.QueryResult<AgendaEntriesAndEventsInDaysQuery, AgendaEntriesAndEventsInDaysQueryVariables>;
export const TimeslotsDocument = gql`
    query Timeslots($provisionId: String!, $from: Date!, $to: Date!, $clinicIds: [String!]) {
  timeslots(
    provisionId: $provisionId
    from: $from
    to: $to
    clinicIds: $clinicIds
  ) {
    day
    slots
  }
}
    `;
export type TimeslotsQueryResult = ApolloReactCommon.QueryResult<TimeslotsQuery, TimeslotsQueryVariables>;
export const ClinicsByIdsDocument = gql`
    query ClinicsByIds($medicId: ID!, $ids: [ID!]!) {
  clinics(filter: {id: {in: $ids}, professional: {id: {eq: $medicId}}}) {
    id
    name
    phone
  }
}
    `;
export type ClinicsByIdsQueryResult = ApolloReactCommon.QueryResult<ClinicsByIdsQuery, ClinicsByIdsQueryVariables>;
export const PendingInvitesDocument = gql`
    query PendingInvites {
  pendingInvites {
    ...InviteData
  }
}
    ${InviteDataFragmentDoc}`;
export type PendingInvitesQueryResult = ApolloReactCommon.QueryResult<PendingInvitesQuery, PendingInvitesQueryVariables>;
export const SentInvitesDocument = gql`
    query SentInvites($medicId: String!) {
  sentInvites(medicId: $medicId) {
    ...InviteData
  }
}
    ${InviteDataFragmentDoc}`;
export type SentInvitesQueryResult = ApolloReactCommon.QueryResult<SentInvitesQuery, SentInvitesQueryVariables>;
export const InviteDocument = gql`
    query Invite($id: ID!) {
  invite(id: $id) {
    ...InviteData
  }
}
    ${InviteDataFragmentDoc}`;
export type InviteQueryResult = ApolloReactCommon.QueryResult<InviteQuery, InviteQueryVariables>;
export const InvoicesDocument = gql`
    query Invoices($medicId: Int!, $year: Int!, $page: Int, $limit: Int) {
  getInvoices(
    professionalId: $medicId
    year: $year
    first: $limit
    page: $page
    sortBy: [{field: NUMBER, order: DESC}]
  ) {
    data {
      ...InvoiceData
    }
    paginatorInfo {
      total
      hasMorePages
    }
  }
}
    ${InvoiceDataFragmentDoc}`;
export type InvoicesQueryResult = ApolloReactCommon.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const InvoiceDocument = gql`
    query Invoice($id: Int!) {
  getInvoice(invoiceId: $id) {
    ...InvoiceData
  }
}
    ${InvoiceDataFragmentDoc}`;
export type InvoiceQueryResult = ApolloReactCommon.QueryResult<InvoiceQuery, InvoiceQueryVariables>;
export const NextInvoiceNumberDocument = gql`
    query NextInvoiceNumber($medicId: ID!, $year: Int!) {
  getInvoiceNextNumber(professionalId: $medicId, year: $year)
}
    `;
export type NextInvoiceNumberQueryResult = ApolloReactCommon.QueryResult<NextInvoiceNumberQuery, NextInvoiceNumberQueryVariables>;
export const InvoiceOptionsDocument = gql`
    query InvoiceOptions($medicId: Int!) {
  getInvoiceOptions(professionalId: $medicId) {
    ...InvoiceOptionsData
  }
}
    ${InvoiceOptionsDataFragmentDoc}`;
export type InvoiceOptionsQueryResult = ApolloReactCommon.QueryResult<InvoiceOptionsQuery, InvoiceOptionsQueryVariables>;
export const InvoiceVatCodesDocument = gql`
    query InvoiceVatCodes {
  getInvoiceVatCodes {
    ...InvoiceVatCodeData
  }
}
    ${InvoiceVatCodeDataFragmentDoc}`;
export type InvoiceVatCodesQueryResult = ApolloReactCommon.QueryResult<InvoiceVatCodesQuery, InvoiceVatCodesQueryVariables>;
export const InvoicePaymentTypesDocument = gql`
    query InvoicePaymentTypes {
  getInvoicePaymentTypes {
    ...InvoicePaymentTypeData
  }
}
    ${InvoicePaymentTypeDataFragmentDoc}`;
export type InvoicePaymentTypesQueryResult = ApolloReactCommon.QueryResult<InvoicePaymentTypesQuery, InvoicePaymentTypesQueryVariables>;
export const PatientInvoicesDocument = gql`
    query PatientInvoices($patientId: Int!, $professionalId: Int!, $year: Int, $page: Int, $limit: Int) {
  getInvoicesByPatient(
    patient_id: $patientId
    professional_id: $professionalId
    year: $year
    first: $limit
    page: $page
    sortBy: [{field: NUMBER, order: DESC}]
  ) {
    data {
      ...InvoiceData
    }
    paginatorInfo {
      total
      hasMorePages
    }
  }
}
    ${InvoiceDataFragmentDoc}`;
export type PatientInvoicesQueryResult = ApolloReactCommon.QueryResult<PatientInvoicesQuery, PatientInvoicesQueryVariables>;
export const SearchRecipientDocument = gql`
    query SearchRecipient($name: String!) {
  professionals(
    limit: 100
    filter: {_or: [{name: {like: $name}}, {surname: {like: $name}}]}
  ) {
    items {
      ...ProfessionalBasicData
      user {
        id
        traits {
          taxId
        }
      }
    }
  }
  patients(
    limit: 100
    filter: {_or: [{name: {like: $name}}, {surname: {like: $name}}]}
  ) {
    items {
      ...PatientBasicData
    }
  }
}
    ${ProfessionalBasicDataFragmentDoc}
${PatientBasicDataFragmentDoc}`;
export type SearchRecipientQueryResult = ApolloReactCommon.QueryResult<SearchRecipientQuery, SearchRecipientQueryVariables>;
export const LicenseTypesDocument = gql`
    query LicenseTypes {
  licenseTypes {
    ...LicenseTypeData
  }
}
    ${LicenseTypeDataFragmentDoc}`;
export type LicenseTypesQueryResult = ApolloReactCommon.QueryResult<LicenseTypesQuery, LicenseTypesQueryVariables>;
export const NetworkDocument = gql`
    query Network($id: ID!) {
  network(id: $id) {
    ...NetworkData
  }
}
    ${NetworkDataFragmentDoc}`;
export type NetworkQueryResult = ApolloReactCommon.QueryResult<NetworkQuery, NetworkQueryVariables>;
export const NotificationsDocument = gql`
    query Notifications($filters: NotificationFilterInput, $skip: Int = 0, $limit: Int = 25) {
  notifications(
    skip: $skip
    limit: $limit
    filter: $filters
    order: {createdAt: DESC}
  ) {
    items {
      ...NotificationData
    }
    paginationInfo {
      ...PaginationInfoData
    }
  }
}
    ${NotificationDataFragmentDoc}
${PaginationInfoDataFragmentDoc}`;
export type NotificationsQueryResult = ApolloReactCommon.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const NotificationDocument = gql`
    query Notification($id: ID!) {
  notification(id: $id) {
    ...NotificationData
  }
}
    ${NotificationDataFragmentDoc}`;
export type NotificationQueryResult = ApolloReactCommon.QueryResult<NotificationQuery, NotificationQueryVariables>;
export const ManagedOrganizationsDocument = gql`
    query ManagedOrganizations {
  managedOrganizations {
    ...OrganizationProfessionalData
  }
}
    ${OrganizationProfessionalDataFragmentDoc}`;
export type ManagedOrganizationsQueryResult = ApolloReactCommon.QueryResult<ManagedOrganizationsQuery, ManagedOrganizationsQueryVariables>;
export const PatientsDocument = gql`
    query Patients($filter: PatientFilterInput, $order: PatientOrderInput, $skip: Int, $limit: Int) {
  patients(filter: $filter, order: $order, skip: $skip, limit: $limit) {
    ...PatientPaginatedResponseData
  }
}
    ${PatientPaginatedResponseDataFragmentDoc}`;
export type PatientsQueryResult = ApolloReactCommon.QueryResult<PatientsQuery, PatientsQueryVariables>;
export const PatientProfessionalsDocument = gql`
    query PatientProfessionals($filter: PatientProfessionalFilterInput, $order: PatientProfessionalOrderInput, $skip: Int, $limit: Int, $medicId: String!) {
  patientProfessionals(
    filter: $filter
    order: $order
    skip: $skip
    limit: $limit
    medicId: $medicId
  ) {
    ...PatientProfessionalPaginatedResponseData
  }
}
    ${PatientProfessionalPaginatedResponseDataFragmentDoc}`;
export type PatientProfessionalsQueryResult = ApolloReactCommon.QueryResult<PatientProfessionalsQuery, PatientProfessionalsQueryVariables>;
export const PatientProfessionalDocument = gql`
    query PatientProfessional($id: ID!) {
  patientProfessional(id: $id) {
    ...PatientProfessionalData
    upcomingAppointments {
      ...AppointmentData
    }
    reports {
      ...ReportSharedData
    }
  }
}
    ${PatientProfessionalDataFragmentDoc}
${AppointmentDataFragmentDoc}
${ReportSharedDataFragmentDoc}`;
export type PatientProfessionalQueryResult = ApolloReactCommon.QueryResult<PatientProfessionalQuery, PatientProfessionalQueryVariables>;
export const SearchPatientProfessionalDocument = gql`
    query SearchPatientProfessional($like: String!, $filter: PatientProfessionalFilterInput, $order: PatientProfessionalOrderInput, $skip: Int, $limit: Int, $medicId: String!) {
  searchPatientProfessionals(
    medicId: $medicId
    patientSearch: $like
    filter: $filter
    order: $order
    skip: $skip
    limit: $limit
  ) {
    ...PatientProfessionalPaginatedResponseData
  }
}
    ${PatientProfessionalPaginatedResponseDataFragmentDoc}`;
export type SearchPatientProfessionalQueryResult = ApolloReactCommon.QueryResult<SearchPatientProfessionalQuery, SearchPatientProfessionalQueryVariables>;
export const SearchPatientByTaxIdDocument = gql`
    query SearchPatientByTaxId($taxId: TaxId!) {
  searchPatientByTaxId(taxId: $taxId) {
    ... on Patient {
      ...PatientBasicData
    }
    ... on PublicPatient {
      taxId
    }
  }
}
    ${PatientBasicDataFragmentDoc}`;
export type SearchPatientByTaxIdQueryResult = ApolloReactCommon.QueryResult<SearchPatientByTaxIdQuery, SearchPatientByTaxIdQueryVariables>;
export const ProfessionalDocument = gql`
    query Professional($id: ID!) {
  professional(id: $id) {
    ...ProfessionalData
  }
}
    ${ProfessionalDataFragmentDoc}`;
export type ProfessionalQueryResult = ApolloReactCommon.QueryResult<ProfessionalQuery, ProfessionalQueryVariables>;
export const ProfessionalsDocument = gql`
    query Professionals($name: String!, $roleFilter: ProfessionalRoleFieldFilterInput) {
  professionals(
    limit: 100
    filter: {role: $roleFilter, _or: [{name: {like: $name}}, {surname: {like: $name}}]}
  ) {
    items {
      ...ProfessionalBasicData
    }
    paginationInfo {
      ...PaginationInfoData
    }
  }
}
    ${ProfessionalBasicDataFragmentDoc}
${PaginationInfoDataFragmentDoc}`;
export type ProfessionalsQueryResult = ApolloReactCommon.QueryResult<ProfessionalsQuery, ProfessionalsQueryVariables>;
export const ProfessionalsByEmailDocument = gql`
    query ProfessionalsByEmail($email: String!) {
  professionalsByEmail(email: $email) {
    ...ProfessionalBasicData
  }
}
    ${ProfessionalBasicDataFragmentDoc}`;
export type ProfessionalsByEmailQueryResult = ApolloReactCommon.QueryResult<ProfessionalsByEmailQuery, ProfessionalsByEmailQueryVariables>;
export const SearchProfessionalsDocument = gql`
    query SearchProfessionals($name: String!, $roleFilter: ProfessionalRoleFieldFilterInput) {
  professionals(
    limit: 100
    filter: {role: $roleFilter, _or: [{name: {like: $name}}, {surname: {like: $name}}]}
  ) {
    items {
      ...SearchProfessionalData
    }
    paginationInfo {
      ...PaginationInfoData
    }
  }
}
    ${SearchProfessionalDataFragmentDoc}
${PaginationInfoDataFragmentDoc}`;
export type SearchProfessionalsQueryResult = ApolloReactCommon.QueryResult<SearchProfessionalsQuery, SearchProfessionalsQueryVariables>;
export const PatientProfilesDocument = gql`
    query PatientProfiles {
  patientProfiles {
    ...PatientUserData
  }
}
    ${PatientUserDataFragmentDoc}`;
export type PatientProfilesQueryResult = ApolloReactCommon.QueryResult<PatientProfilesQuery, PatientProfilesQueryVariables>;
export const ProfessionalProfilesDocument = gql`
    query ProfessionalProfiles {
  medicProfile {
    ...MedicData
  }
  assistantProfile {
    ...AssistantBasicData
    networks {
      ...NetworkData
      inverse {
        ... on Medic {
          ...MedicBasicData
        }
        ... on Operator {
          ...OperatorBasicData
        }
      }
    }
  }
}
    ${MedicDataFragmentDoc}
${AssistantBasicDataFragmentDoc}
${NetworkDataFragmentDoc}
${MedicBasicDataFragmentDoc}
${OperatorBasicDataFragmentDoc}`;
export type ProfessionalProfilesQueryResult = ApolloReactCommon.QueryResult<ProfessionalProfilesQuery, ProfessionalProfilesQueryVariables>;
export const MedicProfileDocument = gql`
    query MedicProfile {
  medicProfile {
    id
    role
    hasActiveLicense
    userId
    type
    freeLancer
    name
    surname
  }
}
    `;
export type MedicProfileQueryResult = ApolloReactCommon.QueryResult<MedicProfileQuery, MedicProfileQueryVariables>;
export const AssistantProfileDocument = gql`
    query AssistantProfile {
  assistantProfile {
    ...AssistantBasicData
    networks {
      ...NetworkData
      inverse {
        ... on Medic {
          ...MedicBasicData
          specializations {
            id
          }
        }
        ... on Operator {
          ...OperatorBasicData
          specializations {
            id
          }
        }
        ... on OrganizationSa {
          ...OrganizationSaBasicData
        }
      }
    }
  }
}
    ${AssistantBasicDataFragmentDoc}
${NetworkDataFragmentDoc}
${MedicBasicDataFragmentDoc}
${OperatorBasicDataFragmentDoc}
${OrganizationSaBasicDataFragmentDoc}`;
export type AssistantProfileQueryResult = ApolloReactCommon.QueryResult<AssistantProfileQuery, AssistantProfileQueryVariables>;
export const OperatorProfileDocument = gql`
    query OperatorProfile {
  operatorProfile {
    id
    role
    hasActiveLicense
    userId
    profession
    name
    surname
  }
}
    `;
export type OperatorProfileQueryResult = ApolloReactCommon.QueryResult<OperatorProfileQuery, OperatorProfileQueryVariables>;
export const SearchProvisionsDocument = gql`
    query SearchProvisions($name: String!) {
  provisions(limit: 100, filter: {name: {like: $name}}) {
    items {
      ...ProvisionData
    }
  }
}
    ${ProvisionDataFragmentDoc}`;
export type SearchProvisionsQueryResult = ApolloReactCommon.QueryResult<SearchProvisionsQuery, SearchProvisionsQueryVariables>;
export const ProfessionalReportsDocument = gql`
    query ProfessionalReports($medicId: String!, $limit: Int! = 10, $skip: Int! = 0) {
  professionalReports(
    medicId: $medicId
    limit: $limit
    skip: $skip
    order: {createdAt: DESC}
  ) {
    items {
      ...ReportSharedData
    }
    paginationInfo {
      ...PaginationInfoData
    }
  }
}
    ${ReportSharedDataFragmentDoc}
${PaginationInfoDataFragmentDoc}`;
export type ProfessionalReportsQueryResult = ApolloReactCommon.QueryResult<ProfessionalReportsQuery, ProfessionalReportsQueryVariables>;
export const ProfessionalReportDocument = gql`
    query ProfessionalReport($medicId: String!, $id: String!) {
  professionalReport(medicId: $medicId, id: $id) {
    ...ReportSharedData
  }
}
    ${ReportSharedDataFragmentDoc}`;
export type ProfessionalReportQueryResult = ApolloReactCommon.QueryResult<ProfessionalReportQuery, ProfessionalReportQueryVariables>;
export const SentRequestsDocument = gql`
    query SentRequests($medicId: String!, $skip: Int = 0, $limit: Int = 10, $filter: RequestFilterInput) {
  sentRequestsAsProfessional(
    medicId: $medicId
    skip: $skip
    limit: $limit
    filter: $filter
    order: {createdAt: DESC}
  ) {
    items {
      ...RequestData
    }
    paginationInfo {
      ...PaginationInfoData
    }
  }
}
    ${RequestDataFragmentDoc}
${PaginationInfoDataFragmentDoc}`;
export type SentRequestsQueryResult = ApolloReactCommon.QueryResult<SentRequestsQuery, SentRequestsQueryVariables>;
export const ReceivedRequestsDocument = gql`
    query ReceivedRequests($medicId: String!, $skip: Int = 0, $limit: Int = 10, $filter: RequestFilterInput) {
  receivedRequestsAsProfessional(
    medicId: $medicId
    skip: $skip
    limit: $limit
    filter: $filter
    order: {createdAt: DESC}
  ) {
    items {
      ...RequestData
    }
    paginationInfo {
      ...PaginationInfoData
    }
  }
}
    ${RequestDataFragmentDoc}
${PaginationInfoDataFragmentDoc}`;
export type ReceivedRequestsQueryResult = ApolloReactCommon.QueryResult<ReceivedRequestsQuery, ReceivedRequestsQueryVariables>;
export const RequestDocument = gql`
    query Request($id: ID!) {
  request(id: $id) {
    ...RequestData
  }
}
    ${RequestDataFragmentDoc}`;
export type RequestQueryResult = ApolloReactCommon.QueryResult<RequestQuery, RequestQueryVariables>;
export const SearchSpecializationsDocument = gql`
    query SearchSpecializations($name: String!) {
  specializations(filter: {name: {like: $name}}) {
    ...SpecializationData
  }
}
    ${SpecializationDataFragmentDoc}`;
export type SearchSpecializationsQueryResult = ApolloReactCommon.QueryResult<SearchSpecializationsQuery, SearchSpecializationsQueryVariables>;
export const OnNewNotificationDocument = gql`
    subscription OnNewNotification {
  onNewNotification {
    ...NotificationData
  }
}
    ${NotificationDataFragmentDoc}`;
export type OnNewNotificationSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnNewNotificationSubscription>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "Professional",
        "possibleTypes": [
          {
            "name": "Medic"
          },
          {
            "name": "Assistant"
          },
          {
            "name": "Operator"
          },
          {
            "name": "OrganizationSa"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "BaseEntity",
        "possibleTypes": [
          {
            "name": "Specialization"
          },
          {
            "name": "Provision"
          },
          {
            "name": "Attachment"
          },
          {
            "name": "Report"
          },
          {
            "name": "Organization"
          },
          {
            "name": "OrganizationProfessional"
          },
          {
            "name": "City"
          },
          {
            "name": "PatientProfessional"
          },
          {
            "name": "Clinic"
          },
          {
            "name": "ClinicRoom"
          },
          {
            "name": "ProfessionalProvision"
          },
          {
            "name": "AgendaEntry"
          },
          {
            "name": "AgendaWeek"
          },
          {
            "name": "AgendaList"
          },
          {
            "name": "Network"
          },
          {
            "name": "PaymentMethod"
          },
          {
            "name": "License"
          },
          {
            "name": "Medic"
          },
          {
            "name": "Assistant"
          },
          {
            "name": "Operator"
          },
          {
            "name": "OrganizationSa"
          },
          {
            "name": "PatientUser"
          },
          {
            "name": "Patient"
          },
          {
            "name": "Appointment"
          },
          {
            "name": "Break"
          },
          {
            "name": "Notification"
          },
          {
            "name": "RequestMessage"
          },
          {
            "name": "Request"
          },
          {
            "name": "Invite"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "AgendaEvent",
        "possibleTypes": [
          {
            "name": "Appointment"
          },
          {
            "name": "Break"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "RequestAuthor",
        "possibleTypes": [
          {
            "name": "Medic"
          },
          {
            "name": "Operator"
          },
          {
            "name": "Patient"
          },
          {
            "name": "OrganizationSa"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SearchPatientUnion",
        "possibleTypes": [
          {
            "name": "PublicPatient"
          },
          {
            "name": "Patient"
          }
        ]
      }
    ]
  }
};
      export default result;
    