
export function ensureMultipleOfFive(value: number) {
  return Math.ceil(value / 5) * 5;
}

export function arrayOfLength(len: number) {
  return Array.from(new Array(len)).map((_, i) => i);
}

export function validateEmail(email: string) {
  // eslint-disable-next-line max-len
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePhoneNumber(phone?: string) {
  if (!phone) {
    return true;
  }
  const [prefix] = phone.split(' ');
  return !!prefix;
}

export function formatPhoneNumber(phone?: string) {
  if (!phone || !validatePhoneNumber(phone)) {
    return undefined;
  }
  const [, ...parts] = phone.split(' ');
  if (parts.length === 0) {
    return undefined;
  }
  return phone;
}

export function arrayEquals(arr1: any[], arr2: any[]) {
  // if the other array is a falsy value, return
  if (!arr1 || !arr2)
    return false;

  // compare lengths - can save a lot of time
  if (arr1.length !== arr2.length)
    return false;

  for (let i = 0, l = arr1.length; i < l; i++) {
    // Check if we have nested arrays
    if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
      // recurse into the nested arrays
      if (!arrayEquals(arr1[1], arr2[1])) {
        return false;
      }
    } else if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

export function kebabCase(str: string) {
  const matches = str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
  if (!matches) return '';
  return matches
    .map(x => x.toLowerCase())
    .join('-');
}

export function isValidHttpUrl(str: string) {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}
