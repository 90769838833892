import { memo, Suspense, useEffect, useMemo } from 'react';
import { Switch, useHistory } from 'react-router';
import { getRoute, renderCommonRoutes, renderErrorRoutes, RoutesKeys } from '../routes';
import { Layout } from '../components/layout';
import { ChatPopupProvider } from '../contexts/chat.context';
import { useProfile } from '../contexts/profile.context';
import { useLocalStorage } from 'react-use';
import { shouldCompleteProfile } from '../utils/profiles';
import { CenteredLoading } from '../components/centered-loading';

const MainContainer = memo(() => {
  const routes = useMemo(() => renderCommonRoutes([]).concat(renderErrorRoutes()), []);
  const { loading, actingAs } = useProfile();
  const [firstConfigCheck, setFirstConfigCheck] = useLocalStorage<boolean>('@medic:firstConfig');
  const history = useHistory();

  useEffect(() => {
    if (actingAs && shouldCompleteProfile(actingAs) && !firstConfigCheck) {
      setFirstConfigCheck(true);
      history.replace(getRoute(RoutesKeys.profile));
    }
  }, [actingAs, setFirstConfigCheck, firstConfigCheck, history]);

  const content = loading ? <CenteredLoading/> : (
    <Suspense fallback={<CenteredLoading/>}>
      <Switch>
        {routes}
      </Switch>
    </Suspense>
  );

  return (
    <ChatPopupProvider>
      <Layout>
        {content}
      </Layout>
    </ChatPopupProvider>
  );
});

export default MainContainer;
