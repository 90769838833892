import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, Grid, Icon, MenuItem, Select, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { SupportedProfileType, useProfile } from '../../contexts/profile.context';
import { getProfessionalPrefix, getProfileColor, getProfileIcon } from '../../utils/profiles';
import { useIsMobile } from '../../hooks/useIsMobile';

const useStyles = makeStyles(theme =>
  createStyles({
    select: {
      height: 32,
      '&:before': {
        borderBottom: '0px solid white !important',
      },
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'capitalize',
      color: theme.palette.text.secondary,
    },
    listItemIcon: {
      minWidth: 0,
      paddingRight: 4,
    },
    profileIcon: {
      height: 20,
      width: 20,
      paddingRight: 2,
      fill: theme.palette.text.secondary,
    },
    icon: {
      fill: theme.palette.text.secondary,
    },
    renderedName: {
      maxWidth: '30vw',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

type ImpersonateSelectorProps = {
  options: SupportedProfileType[];
}

export const ImpersonateSelector: FC<ImpersonateSelectorProps> = ({ options }) => {
  const { impersonatedProfile, setImpersonatedProfile } = useProfile();
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const getProfileIndex = useCallback(() => {
    return options.findIndex(p => p.id === impersonatedProfile?.id)
  }, [impersonatedProfile, options]);

  const [profileIndex, setProfileIndex] = useState(getProfileIndex());

  useEffect(() => {
    const newIndex = getProfileIndex();
    if (newIndex !== profileIndex) {
      setProfileIndex(newIndex);
    }
  }, [impersonatedProfile, profileIndex, setProfileIndex, getProfileIndex]);

  const handleChange = useCallback((event: ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>) => {
    const idx = event.target.value as number;
    setProfileIndex(idx);

    const newProfile = options[idx];
    setImpersonatedProfile(newProfile || null);
  }, [options, setImpersonatedProfile]);

  return (
    <FormControl>
      <Select
        variant="outlined"
        className={classes.select}
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
        value={profileIndex}
        onChange={handleChange}
        renderValue={value => {
          const profile = options[value as number];
          if (!profile) {
            return null;
          }
          const ProfileIcon = getProfileIcon(profile);

          return <Grid container alignItems="center">
            <Grid item>
              <Icon className={classes.listItemIcon}>
                <ProfileIcon className={classes.profileIcon} style={{ fill: getProfileColor(profile) }} />
              </Icon>
            </Grid>
            <Grid item>
              <Typography className={classes.renderedName}>
                {`${getProfessionalPrefix(profile, t)}${
                  isMobile ? `${profile.name[0]}.` : profile.name
                } ${profile.surname} `}
              </Typography>
            </Grid>
          </Grid>
        }}
      >
        {options.map((profile, i) => {
          const ProfileIcon = getProfileIcon(profile);

          return <MenuItem key={profile.id} value={i}>
            <Grid container alignItems="center">
              <Grid item>
                <Icon className={classes.listItemIcon}>
                  <ProfileIcon className={classes.profileIcon} style={{ fill: getProfileColor(profile) }} />
                </Icon>
              </Grid>
              <Grid item>
                <Typography>
                  {`${getProfessionalPrefix(profile, t)}${profile.name} ${profile.surname} `}
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
        })}
      </Select>
    </FormControl>
  )
}
