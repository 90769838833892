import { PureComponent } from 'react';
import { Error as ErrorComponent } from '@hu-care/react-layout';
import { ErrorHandler } from '@hu-care/react-utils';
import { Box, Button } from '@material-ui/core';

export class ErrorBoundary extends PureComponent<any, { error: Error | null }> {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error: new ErrorHandler(error as any) };
  }

  onReload = () => {
    window.location.reload();
  }

  render() {
    if (this.state.error) {
      return (
        <Box display="flex" flexDirection="column" p={8} alignItems="center" justifyContent="center">
          <ErrorComponent error={this.state.error!}/>
          <Box pt={2}>
            <Button
              variant="outlined"
              id={'reload-page-btn'}
              color="secondary"
              onClick={this.onReload}>Reload</Button>
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}
