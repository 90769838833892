import { FC, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Box, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ChatBubble, Clear, Mail } from '@material-ui/icons';

import { useProfile } from '../../contexts/profile.context';
import { ImpersonateSelector } from '../impersonate-selector';
import { getProfessionalFullName, getProfileColor } from '../../utils/profiles';
import { getRoute, RoutesKeys } from '../../routes';
import { useChatPopup } from '../../contexts/chat.context';
import { useDialog } from '@hu-care/react-ui-store';
import { SendMail } from '../send-mail';
import { useTranslation } from 'react-i18next';
import { MailIcon } from '../../utils/icons';
import { useIsMobile } from '../../hooks/useIsMobile';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      zIndex: 100,
      paddingLeft: theme.spacing(1),
      background: theme.palette.background.paper,
    },
    info: {
      color: theme.palette.secondary.main,
    },
    username: {
      fontWeight: theme.typography.fontWeightBold,
    },
    actionIcon: {
      padding: theme.spacing(1),
    },
  }),
);

export const ImpersonateBar: FC = () => {
  const classes = useStyles();
  const { setImpersonatedProfile, availableImpersonate, actingAs, isImpersonating, selectedProfile } = useProfile();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const history = useHistory();
  const { open: openChat } = useChatPopup();
  const dialog = useDialog();

  const openMail = useCallback(() => {
    const onClose = () => dialog.close();
    if (!isImpersonating || !actingAs?.userId || !selectedProfile) {
      return;
    }
    dialog.open({
      title: (
        <Box display="flex" alignItems="center">
          <Box mr={2} display="flex"><MailIcon /></Box>
          <Typography>{getProfessionalFullName(actingAs, t)}</Typography>
        </Box>
      ),
      body: <SendMail
        onSend={onClose}
        onCancel={onClose}
        professional={selectedProfile}
        recipientIds={[actingAs.userId]}
      />,
      onClose,
      maxWidth: 'md',
      fullWidth: true,
      fullScreen: isMobile,
    });
  }, [dialog, actingAs, selectedProfile, t, isMobile, isImpersonating]);

  const stopImpersonate = useCallback(() => {
    setImpersonatedProfile(null);
    history.push(getRoute(RoutesKeys.dashboard));
  }, [setImpersonatedProfile, history]);

  return (isImpersonating && actingAs) ? (
    <>
      <Grid container justify="space-between" alignItems="center" className={classes.root}>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <Box pr={1}>
                <ImpersonateSelector options={availableImpersonate} />
              </Box>
            </Grid>
            <Grid item>
              <IconButton className={classes.actionIcon} onClick={openMail}>
                <Mail />
              </IconButton>
            </Grid>
            <Grid item>
              {actingAs.user && (
                <IconButton className={classes.actionIcon} onClick={() => {
                  if (actingAs?.user) {
                    openChat(actingAs.user);
                  }
                }}>
                  <ChatBubble />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <IconButton onClick={stopImpersonate}>
            <Clear />
          </IconButton>
        </Grid>
      </Grid>
      <Divider style={{ height: 4, opacity: 0.6, backgroundColor: getProfileColor(actingAs) }} />
    </>
  ) : null;
}
