import { useCallback, useEffect, FC } from 'react';
import { ProfessionalBasicDataFragment } from '../../generated/graphql';
import { MAX_FILE_SIZE } from '../../utils/upload';
import { SendMailFormComponent } from './send-mail-form';
import { SendMailForm, useSendMail, useSendMailForm } from '../../hooks/useSendMail';

export interface SendMailProps {
  onSend: () => void;
  onCancel: () => void;
  professional: ProfessionalBasicDataFragment;
  recipientIds: string[];
}

const MAX_FILES = 5;

export const SendMail: FC<SendMailProps> = ({ onCancel, onSend, professional, recipientIds }) => {
  const { sendMail, loading } = useSendMail(professional);
  const {
    control,
    register,
    setValue,
    errors,
    fields,
    onDrop,
    handleSubmit,
    onFileRemove,
  } = useSendMailForm(MAX_FILES, MAX_FILE_SIZE);


  useEffect(() => {
    register('userIds', {
      required: true,
    });
  }, [register]);

  useEffect(() => {
    setValue('userIds', recipientIds);
  }, [setValue, recipientIds]);

  const onSubmit = useCallback(async (data: SendMailForm) => {
    data.attachments = fields.filter(({ file }) => !!file).map(({ file }) => file!);
    sendMail(data)
    .then(onSend)
    .catch(() => {
      //
    });
  }, [fields, sendMail, onSend]);

  return (
    <SendMailFormComponent
      maxFiles={MAX_FILES}
      maxFileSize={MAX_FILE_SIZE}
      onDrop={onDrop}
      onSubmit={handleSubmit(onSubmit)}
      control={control}
      errors={errors}
      onFileRemove={onFileRemove}
      files={fields}
      loading={loading}
      onCancel={onCancel}
    />
  )
}
